import { useCallback } from "react";
import { AgentExplorerSecEmptystateStyled } from "./agentexplorersecemptystate.styles";
import { AgentExplorerSecEmptystateProps } from "./agentexplorersecemptystate.types";
import { OnCreateNewToolAgentClick } from "../../../../../..";
import {
  grayGearWithStarIcon,
  solidGoToIcon,
} from "../../../../../../../../svgs";
import LinkButton from "../../../../../../../../components/linkbutton";

const grayGearWithStarSvg = grayGearWithStarIcon("27.2", "26.4");
const solidGoToIcontSvg = solidGoToIcon("", "9px");

const AgentExplorerSecEmptystate = (
  props: AgentExplorerSecEmptystateProps
): JSX.Element => {
  const { onCreateNewToolAgentClick } = props;

  const onCreateNewToolClick = useCallback(
    ([type]: Parameters<OnCreateNewToolAgentClick>) => (): void =>
      onCreateNewToolAgentClick(type),
    [onCreateNewToolAgentClick]
  );

  return (
    <AgentExplorerSecEmptystateStyled>
      {grayGearWithStarSvg}

      <div className="text">
        You haven't created any tools yet. To customize your agent's
        capabilities
      </div>

      <LinkButton
        sufixIcon={solidGoToIcontSvg}
        onClick={onCreateNewToolClick(["tool"])}
        target="_blank"
      >
        Create a new Tool
      </LinkButton>
    </AgentExplorerSecEmptystateStyled>
  );
};

export default AgentExplorerSecEmptystate;
