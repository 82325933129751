import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Dropdown } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";

import {
  HeaderDropdownMenuStyled,
  HeaderStyled,
  MenuStyled,
} from "./header.styles";
import { getLogoutUrl } from "../../utils/logouturl";
import {
  useGetAppState,
  useOpenModal,
  useOpenDrawer,
  useCancelDrawer,
} from "../../customhooks";

import SearchWithDropdown from "../searchwithdropdown";
import { getPostLoginData } from "../../utils/getpostlogindata";
import {
  darkFilledFilterIcon,
  fillChevronIconDown,
  questionMarkSvg,
} from "../../svgs";
import { APP_ROUTES, ELEMENT_IDS } from "../../constants";
import { getGlobalFiltersData } from "../../utils/getglobalfiltersdata";
import { getModuleLevelAccessInfo } from "../../utils/getModuleLevelAccessInfo";
import GatewaysListPopover from "../gatewayslistpopover";
import { useGatewayStatusContext } from "../../contexts";
import StyledLink from "../styledlink/styledlink";

const {
  userjrn_per_drop_down: USERJRN_PER_DROP_DOWN,
  userjrn_per_my_setting: USERJRN_PER_MY_SETTING,
  userjrn_help_hlp_top_nav: USERJRN_HELP_HLP_TOP_NAV,
} = ELEMENT_IDS;

function Header(): JSX.Element {
  const [dropdownVisibility, setDropdownVisibility] = useState<boolean>(false);

  const location = useLocation();
  const history = useHistory();
  const onOpenModal = useOpenModal();
  const onOpenDrawer = useOpenDrawer();
  const closeDrawer = useCancelDrawer();

  const postLoginInfo = getPostLoginData();

  const {
    routerPrompt: { isAnyUnsavedChanges, isPrompted },
    drawer,
    isOnboardingMode,
  } = useGetAppState();

  const {
    state: { listOfGateways = [], isFetchingGatewaysList },
  } = useGatewayStatusContext();

  const { visible: drawerVisibility, drawerId } = drawer || {};

  const onClose = useCallback(() => {
    onOpenModal({});
    onOpenDrawer({});
  }, [location]);

  useEffect(() => {
    onClose();
  }, [location?.pathname]);

  const logout = useCallback(() => {
    localStorage.clear();
    window.location.href = getLogoutUrl();
  }, []);

  const onLogOut = useCallback(() => {
    if (isAnyUnsavedChanges) {
      onOpenModal({
        modalId: "logout_confirmation_box",
        modalTitle: "Confirmation",
        visible: true,
        modalProps: {
          onSuccess: logout,
          okText: "Leave",
          content: (
            <>
              You have unsaved changes. This changes will be lost if you leave
              this page.<div className="bold">Do you want to proceed?</div>
            </>
          ),
        },
      });
    } else {
      logout();
    }
  }, [isAnyUnsavedChanges, isPrompted]);

  const onMySettingsClick = useCallback(() => {
    setDropdownVisibility(false);
  }, []);

  const settingsPageUrl = `${APP_ROUTES?.private_routes?.my_settings}/general`;

  /* parent rendering 3 times */
  const searchWithDropdown = useMemo(() => {
    return <SearchWithDropdown />;
  }, []);

  const doesGatewayListExist = useMemo(() => {
    return (
      isFetchingGatewaysList ||
      listOfGateways?.filter((item) => item?.isOnpremiseGateway)?.length > 0
    );
  }, [listOfGateways, isFetchingGatewaysList]);

  const onClickHelpButton = useCallback(() => {
    if (drawerVisibility && drawerId === "dvsum_help") {
      closeDrawer();
    } else {
      onOpenDrawer({ drawerId: "dvsum_help", visible: true });
    }
  }, [drawerVisibility, drawerId]);

  const { user_info: userInfo } = getPostLoginData();

  const { hasCatalogAccess, hasGlossaryAccess } = getModuleLevelAccessInfo();

  const { selected_filters: selectedFilters } = getGlobalFiltersData();
  const { source, domain } = selectedFilters || {};

  const {
    is_enabled: isSourceEnabled = false,
    filters_list: sourcesFiltersList = [],
  } = source || {};

  const {
    is_enabled: isDomainEnabled = false,
    filters_list: domainsFiltersList = [],
  } = domain || {};

  const filterCount =
    (isSourceEnabled ? sourcesFiltersList?.length : 0) +
    (isDomainEnabled ? domainsFiltersList?.length : 0);

  return (
    <HeaderStyled isOnboardingMode={isOnboardingMode}>
      <div className="left-sec">
        <Link to="/home" className="logo-link">
          <img src="/assets/Logo.png" alt="app-logo" />{" "}
          <div>{userInfo?.company_name}</div>
        </Link>
      </div>
      {(hasCatalogAccess || hasGlossaryAccess) && searchWithDropdown}
      <div className="right-sec">
        {doesGatewayListExist && (
          <GatewaysListPopover placement="bottomRight" />
        )}

        <div
          className="help-center-icon"
          onClick={onClickHelpButton}
          role="button"
          id={USERJRN_HELP_HLP_TOP_NAV}
        >
          {questionMarkSvg} Help
        </div>
        <Dropdown
          overlay={
            <HeaderDropdownMenuStyled>
              <MenuStyled>
                <StyledLink
                  to={settingsPageUrl}
                  propsOnClick={onMySettingsClick}
                  cutomClassName="my-settings-menu-item"
                  id={USERJRN_PER_MY_SETTING}
                  type="text"
                >
                  <span>My Settings</span>
                  <div className="filter-icon-sec">
                    {darkFilledFilterIcon("15", "15")}
                    {!!filterCount && (
                      <div className="filter-count">{filterCount}</div>
                    )}
                  </div>
                </StyledLink>
              </MenuStyled>
              <MenuStyled>
                <div onClick={onLogOut} role="button">
                  <PoweroffOutlined /> Logout
                </div>
              </MenuStyled>
            </HeaderDropdownMenuStyled>
          }
          trigger={["click"]}
          onVisibleChange={(visible): void => {
            setDropdownVisibility(visible);
          }}
          visible={dropdownVisibility}
          overlayClassName="header-dropdown-menu"
        >
          <div className="more-options" id={USERJRN_PER_DROP_DOWN}>
            <div className="dropdown--direction-icon">
              {dropdownVisibility
                ? fillChevronIconDown()
                : fillChevronIconDown()}
            </div>
            <div className="welcome-text"> Welcome,</div>
            <div className="first-name-sec">
              {postLoginInfo?.user_info?.user_first_name}
            </div>
          </div>
        </Dropdown>
      </div>
    </HeaderStyled>
  );
}

export default Header;
