import { checkSourceType, createUUID } from "../../utils";
import { getQueryCreatedByInfo } from "./analisisdetailpage.utils";

import {
  AnalaysisPageState,
  AnalysisDetailPageParamsState,
  AnalysisPageQueryBox,
  QueryBlockGridPagination,
} from "./analysisdetailpage.types";

export const DEFAULT_QUERY_BLOCK_GRID_PAGINATION: QueryBlockGridPagination = {
  current_page: 1,
  page_size: 100,
};

const queryCreatedByInfo = getQueryCreatedByInfo();

export const DEFAULT_QUERY: AnalysisPageQueryBox = {
  qry_sec_id: null,
  type: "CCQ",
  id: createUUID(),
  selected_topic_id: 0,
  qry_retry_count: 0,
  is_focused: true,
  is_expanded: true,
  is_loading: false,
  is_loading_verbose_logs: false,
  loader_type: "newly_added_question",
  src_id: "",
  src_name: "",
  table_id: "",
  table_name: "",
  is_selected: false,
  qry_prf_results_s3_url: "",
  qry_tmp_results_s3_url: "",
  qry_full_results_s3_url: "",
  verbose_s3_url: "",
  ...queryCreatedByInfo,
  qry_created_by_id: 0,
  qry_exec_by: "",
  qry_exec_by_id: 0,
  qry_exec_on: "",
  qry_updated_by: "",
  qry_updated_by_id: 0,
  qry_updated_on: "",
  use_du_tool: false,
  use_general_knowledge_tool: false,
  use_sql_tool: false,
  header: {
    title: "Untitled Query",
    is_profiled: false,
    is_chart_settings_visible: false,
    is_maximize_query_block: false,
    is_editing_query_mode: false,
    edited_query_value: "Untitled Query",
    is_sql_query_visible: false,
    is_show_contexts_list: false,
    is_use_context: false,
    query_context: [],
    result_type: "chart",
    is_verbose_visible: false,
    is_explanation_visible: false,
    is_question_saved: false,
  },
  query: {
    sql_query: "",
    filters: {},
    is_filter_expanded: false,
    is_filter_edit_mode_expanded: false,
  },
  filters: {
    applied_filters: {},
    transiant_filters: {},
    sortModel: [],
  },
  results: {
    data: [],
    columns: [],
    columns_profilig: [],
    last_refreshed: "",
    is_server_side_filteration: false,
    record_count: 0,
    grid_record_count: 0,
    pagination: DEFAULT_QUERY_BLOCK_GRID_PAGINATION,
    tbl_record_count: 0,
    is_sample_data: false,
    sec_title: "",
    chart_config: {
      is_default_settings: true,
    },
    formated_detailed_answer: "",
    formatted_answer: "",
  },
  settings: {
    is_data_formatted: false,
    visible: false,
    tab: "general",
    prefrences: {},
  },
  qry_status: "",
  user_feedback: {
    response: "",
    reason: "",
    comment: "",
    train_me: "",
    train_sql: "",
  },
  is_newly_added_question: true,
  is_response_type_non_technical: false,
  is_technical_details_expanded: false,
  rephrased_question: "",
  qry_created_by_first_name: "",
  qry_created_by_last_name: "",
  agent_id: 0,
};

export const DEFAULT_ANALYSIS_TITLE = "Untitled Agent";

export const getDefaultAnalysisPageState = (
  paramsState: AnalysisDetailPageParamsState
): AnalaysisPageState => {
  const {
    sourceId = "",
    sourceName = "",
    sourceTypeId,
    isCloned = false,
    nodeSubType,
  } = paramsState || {};

  const { isRestAPISource } = checkSourceType(sourceTypeId);

  const defaultIntentLogic = `data_analysis_chain:
- Use when question is to analyze or re-analyze an entity of a dataset.  
- Whenever there are questions to check for a ID or any entity, use this flag.
- Even if a question contains only an entity like ID or an entity name use this flag.

qa_chain:
- Use when question does not contain anything related to a particular entity, but rather a generic domain specific question.
- For generic questions related to a domain or follow-up questions that reference previous conversations (chat history).
- In any other situation, if you do not know which flag to use, use this flag by default.`;

  const defaultSimplifiedAudienceTailoring = `The response should be concise, providing clear information for a call center representative to understand the issue and guide the customer. Include simplified explanations and key action points without overwhelming technical jargon.
-  Summarize the key points, including the issue, explanation, and actions. Simplify the language for easy understanding by a representative who may relay this to customers.`;

  const defaultCustomerFacingAudienceTailoring = `The response should be user-friendly, focusing on the customer's concern. Explain the situation in simple terms, avoiding technical jargon, and provide clear guidance on the next steps.
- Write a customer-facing explanation, clearly addressing the issue and explaining what is being done to resolve it. Avoid technical terms,sensitive terms and reassure the customer.
- Reassure them that the issues will be resolved.`;

  const defaultAIResponseformat = `{  
    "issue_category": "Fetch issue_category from  <detailed_answer>,
    "answer": Provide "explanation" and "recommended_actions",
    "detailed_answer": "Exact content of <detailed_answer>"    
}`;
  return {
    header: {
      title: isCloned
        ? `${sourceName} (Clone)`
        : `${sourceName} ${nodeSubType === "TOL" ? "Tool" : "Agent"}`,
      desc: "",
      view: {
        is_collapse: false,
        is_expanded: false,
      },
      actions: {
        is_expanded: false,
      },
    },
    queries: [],
    pinnedTables: [], //what will pe pinned tables list?
    agents: [],
    pinnedTools: [],
    analysis_info: {
      is_node_cloned: false,
      parent_analysis_id: 0,
      analysis_id: 0,
      created_by: { name: "", email: "" },
      share_type: "NON",
      shared_with: [],
      tables_list: [],
      datasource_used: {
        name: "",
        type: "ADL",
      },
      selected_contexts: [],
      selected_topic_id: 0,
      is_connected_to_agent: false,
    },
    table_id: "",
    src_id: sourceId,
    is_scope_enabled: false,
    is_verbose: false,
    train_bot_filter: "all",
    asked_by_filter: ["all"],
    tableFilter: [],
    columnFilter: [],
    configuration: {
      prompt_inputs: {
        ai_assistant_type: "",
        data_description: "",
        logic: "",
        guidelines: "",
        response_format: "",
        audience_tailoring: {
          simplified: defaultSimplifiedAudienceTailoring,
          customer_facing: defaultCustomerFacingAudienceTailoring,
        },
        api_response_format: defaultAIResponseformat,
        intent_logic: defaultIntentLogic,
        tool_selection_logic: "",
      },
      workflow_json_s3_pre_signed_get_url: "",
    },
    aiChatModel: isRestAPISource ? "AWS" : "AWS",
    aiChatModelName: "",
    gatewayId: "",
  };
};

export const MAX_LIMIT_OF_CLIENT_SIDE_FILT = 10000;

export const DEFAULT_IS_FROM_PAGE = "home";

export const GENERIC_QUERY_BLOCK_ERROR_MESSAGE =
  "Sorry, ran into difficulty finding answer to this question.";

export const GENERIC_CCQ_QUERY_BLOCK_ERROR_MESSAGE =
  "Sorry, ran into difficulty finding answer to this question. Try asking the question a different way.";

export const NO_RESULTS_QUERY_BLOCK_ERROR_MESSAGE =
  "I didn't find any data. Try asking the question a different way.";

export const NO_RESULTS_ON_FILTERING_QUERY_BLOCK_MESSAGE =
  "No records were found that match your selected filter. Please adjust your filters and try again.";

export const CCQ_SQL_COMPILATION_ERROR =
  "Sorry, I could not generate the right SQL. Please give me some feedback so I can improve.";

export const CCQ_SQL_COMPILATION_ERROR_FOR_CADDI =
  "Sorry, I could not generate the right SQL. Try asking the question in a different way.";

export const CCQ_CHAT_GPT_FAILURE_ERROR =
  "Sorry, Error in Conversational AI API. Try asking the question in a different way";

export const PROMPT_SUMMARY_ERROR = `Sorry, not able to summarize the topic`;

export const GUIDE_ME_QUESTIONS_ERROR = `Sorry, not able to fetch guided set of Questions`;

export const CADDI_DATA_NOT_FOUND_MESSAGE = `Please re-run this question, It's possible that your query sample data might have been removed due to data retention policies configured.`;

export const LOADING_TEXT = "Data is being fetched";

export const ANALYSIS_BOT_TABS = "analysis-bot-tabs";

export const START_EXECUTION_ERROR = "Something went wrong. Please try again";

export const REST_SOURCE_ERROR_MESSAGE =
  "Something went wrong. I didn't find any data.";

export const GATEWAY_DOWN_ERROR = "Sorry, could not connect to Gateway.";
