import { useCallback, useMemo } from "react";

import TableActionsSec from "../../../../components/tableactionssec";
import {
  chevronDown,
  tickIcon,
  userGroupsIcon,
  userIcon,
} from "../../../../svgs";

import { SharedWithItemProps } from "./sharedwithitem.types";

import { SharedWithItemStyled } from "./sharedwithitem.styles";
import { Item } from "../../../../components/tableactionssec/tableactionssec.types";
import { isLoggedInUserAndOtherUserEmailIsSame } from "../../../../utils";

const SharedWithItem = (props: SharedWithItemProps): JSX.Element => {
  const {
    index,
    type,
    name,
    email,
    access_type,
    handleChangeAccessType,
    accessOptions,
  } = props;

  const isViewer = access_type === "viewer";
  const isEditor = access_type === "editor";

  const showIcon = useCallback(
    (keyName: string) => {
      return (
        (keyName === "viewer" && isViewer) || (keyName === "editor" && isEditor)
      );
    },
    [isViewer, isEditor]
  );

  const memoizedAccessOptions: Item[] = useMemo(() => {
    return (
      accessOptions?.map((access) => {
        const keyName = access?.key || "viewer";
        return {
          name: access?.label,
          onClick: (): void => {
            handleChangeAccessType(keyName, index);
          },
          icon: showIcon(keyName) ? (
            tickIcon()
          ) : (
            <span
              style={{
                width: "12px",
                height: "12px",
                marginRight: "5px",
              }}
            />
          ),
        };
      }) || []
    );
  }, []);

  const viewerAccess = useMemo(() => {
    return accessOptions?.find((item) => item?.key === "viewer");
  }, [accessOptions]);

  const editorAccess = useMemo(() => {
    return accessOptions?.find((item) => item?.key === "editor");
  }, [accessOptions]);

  const isLoggedInUser = useMemo(
    () => isLoggedInUserAndOtherUserEmailIsSame(email),
    [email]
  );

  const labelToShow = isViewer
    ? viewerAccess?.label
    : isEditor
    ? editorAccess?.label
    : "Remove Access";
  return (
    <SharedWithItemStyled>
      <div className="icon">{type === "USR" ? userIcon : userGroupsIcon()}</div>

      <div className="details">
        <div className="name">{name}</div>
        <div className="email">{email}</div>
      </div>

      <div className="role">
        {access_type !== "owner" ? (
          isLoggedInUser ? (
            <span className="owner">{labelToShow}</span>
          ) : (
            <TableActionsSec hideDots data={memoizedAccessOptions}>
              <div className="role-container">
                {labelToShow}
                {chevronDown()}
              </div>
            </TableActionsSec>
          )
        ) : (
          <span className="owner">Owner</span>
        )}
      </div>
    </SharedWithItemStyled>
  );
};

export default SharedWithItem;
