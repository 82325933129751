// <--- Library Imports Start --->
import { useCallback, useMemo, useState } from "react";
// <--- Library Imports End --->

// <--- Components Start --->
import LinkButton from "../../../../../../../../../../components/linkbutton/linkbutton";
import EditQueryQuestionForm from "../../../../../../../../analysisdetailpage.components/editqueryquestionform";

import ConfirmationMessage from "../../../../../../../../../../components/confirmationmessage";
import UserNameInitials from "../../../../../../../../../../components/usernameinitials/usernameinitials";
// <--- Components End --->

// <--- Styles Start --->
import { QueryBlockHeaderStyled } from "./queryblockheader.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { editPencilIcon } from "../../../../../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import {
  EditQueryQuestionFormWrapperProps,
  QueryBlockHeaderProps,
} from "./queryblockheader.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  checkIsViewOnlyChatQuestionPage,
  getPostLoginData,
} from "../../../../../../../../../../utils";

import QueryActionSection from "./queryactionsection/queryactionsection";
import { checkIsChatPage } from "../../../../../../../../../../utils/ischatpage";

import { getNameInitials } from "../../../../../../../../../../utils/getNameInitials";
import { checkQueryType } from "../../../../../../../../analisisdetailpage.utils";

import { useOpenModal } from "../../../../../../../../../../customhooks";
import { LOADING_TEXT } from "../../../../../../../../analysisdetalpage.constants";
// <--- Utils End --->

const EditQueryQuestionFormWrapper = (
  props: EditQueryQuestionFormWrapperProps
): JSX.Element => {
  const { queryItem, handlers, onQueryQuestionEditClick } = props;
  const { header, id = "" } = queryItem || {};
  const { onUpdateQueryInfo, onEditingQuery } = handlers || {};

  const { title = "", edited_query_value: editedQueryValue = "" } =
    header || {};

  const onCancel = useCallback(() => {
    onEditingQuery?.(id, title);
    onQueryQuestionEditClick();
  }, []);

  const onSave = useCallback(
    (formData: { text: string }) => {
      const { text: formTitle = "" } = formData || {};
      const fromattedTitle = formTitle?.replaceAll("\n", " ")?.trim() || "";

      onUpdateQueryInfo(id, fromattedTitle);
      onQueryQuestionEditClick();
    },
    [onUpdateQueryInfo, onQueryQuestionEditClick]
  );

  return (
    <EditQueryQuestionForm
      defaultTitle={title}
      editedQueryValue={editedQueryValue}
      handleCancel={onCancel}
      onSubmit={onSave}
      onEditingQuery={onEditingQuery}
      queryNodeId={id}
    />
  );
};

const QueryBlockHeader = (props: QueryBlockHeaderProps): JSX.Element => {
  const onOpenModal = useOpenModal();

  const {
    queryItem,
    handlers,
    hasUserAccessToEdit = false,
    isSavedContextsSecVisible = false,
  } = props;

  const {
    header,
    id = "",
    is_focused: isFocused,
    is_loading: isLoading,
    type,
    sub_type: subType,
    qry_created_by_first_name: qryCreatedByFirstName = "",
    qry_created_by_last_name: qryCreatedByLastName = "",
    qry_created_by: qryCreatedBy = "",
    user_feedback: userFeedback,
    settings,
  } = queryItem || {};

  const { visible: isQuerySettingsVisible = false } = settings || {};

  const {
    title = "",
    is_editing_query_mode: isEditingQueryMode = false,
    is_maximize_query_block: isMaximized = false,
  } = header || {};

  const { onDelete, onEditingQueryStart } = handlers || {};

  const { response } = userFeedback || {};

  const suffixText =
    response === "dislike" || response === "like"
      ? ` The feedback you have given will be deleted as well.`
      : "";

  const isChatPage = checkIsChatPage();
  const isViewOnlyChatQuestionPage = checkIsViewOnlyChatQuestionPage();

  const {
    isSummary,
    isAboutPrompt,
    isGuideMeSec,
    isConceptQuery,
    isTermQuery,
  } = checkQueryType(type, subType);

  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [isShowTitleSecActionBtns, setIsShowTitleSecActionBtns] = useState(
    false
  );

  const { user_info: userInfo } = getPostLoginData();

  const { user_first_name: firstName = "", user_last_name: lastName = "" } =
    userInfo || {};

  const memoizedNameInitials = useMemo(
    () =>
      getNameInitials(
        qryCreatedByFirstName || firstName,
        qryCreatedByLastName || lastName
      ),
    [firstName, lastName, qryCreatedByFirstName, qryCreatedByLastName]
  );

  const onDeleteActionCalled = useCallback((): void => {
    onDelete?.(id);
    setIsDeleteMode(false);
  }, [onDelete]);

  const onConceptEditClick = useCallback((): void => {
    // const modalProps: TrainbotShareConceptFormProps = {
    //   description: title,
    //   onUpdateConcept,
    //   queryId: id,
    // };
    // onOpenModal({
    //   modalId: "train_bot_share_concept_modal",
    //   visible: true,
    //   modalProps,
    // });
  }, [title, id]);

  const editSec = (
    <LinkButton
      marginRight="13px"
      tooltipProps={{
        title: isLoading
          ? LOADING_TEXT
          : `Edit ${isConceptQuery ? "Concept" : "Question"} `,
      }}
      className={!isChatPage ? "edit-question-btn" : ""}
      onClick={(): void => {
        isConceptQuery ? onConceptEditClick() : onEditingQueryStart(id);
        setIsShowTitleSecActionBtns(false);
      }}
      disabled={isLoading}
      data-testid="analysis-detail-page-query-block-header-right-sec-edit-btn"
    >
      <div className="query-edit-icon"> {editPencilIcon("15", "15")}</div>
    </LinkButton>
  );

  return (
    <QueryBlockHeaderStyled
      isQuerySettingsVisible={isQuerySettingsVisible}
      isChatPage={isChatPage}
      isMaximized={isMaximized}
      isShowTitleSecActionBtns={isShowTitleSecActionBtns}
      isSavedContextsSecVisible={isSavedContextsSecVisible}
      className={
        isChatPage ? "chat-query-block-header" : "analysis-query-block-header"
      }
      data-testid="analysis-detail-page-query-block-header-main-wrapper"
      isViewOnlyChatQuestionPage={isViewOnlyChatQuestionPage}
    >
      <div
        className="query-block-header"
        data-testid="analysis-detail-page-query-block-header"
        onMouseEnter={(): void => setIsShowTitleSecActionBtns(true)}
        onMouseLeave={(): void => setIsShowTitleSecActionBtns(false)}
      >
        {isEditingQueryMode ? (
          <EditQueryQuestionFormWrapper
            {...props}
            onQueryQuestionEditClick={(): void => onEditingQueryStart(id)}
          />
        ) : isDeleteMode ? (
          <ConfirmationMessage
            message={
              isConceptQuery
                ? "Are you sure you want to delete this concept? This cannot be undone."
                : isSummary
                ? "Are you sure you want to delete this summary? This cannot be undone."
                : isAboutPrompt
                ? "Are you sure you want to delete this info about the agent? This cannot be undone."
                : isGuideMeSec
                ? "Are you sure you want to delete these generated questions? This cannot be undone."
                : isTermQuery
                ? "Are you sure you want to delete this question? This cannot be undone."
                : `Are you sure you want to delete this question?${suffixText} This cannot be undone.`
            }
            onOk={onDeleteActionCalled}
            onCancel={(): void => setIsDeleteMode(false)}
          />
        ) : (
          <>
            <div
              className="query-block-header-left-sec"
              data-testid="analysis-detail-page-query-block-header-left-sec"
            >
              <UserNameInitials
                name={memoizedNameInitials}
                userName={qryCreatedBy}
              />
              <div
                className="title"
                data-testid="analysis-detail-page-query-block-header-title-text"
              >
                {title}
              </div>
              {(isFocused || (isChatPage && !isViewOnlyChatQuestionPage)) && (
                <div className="query-question-action-section">
                  {isChatPage ? (
                    <>
                      {!isAboutPrompt && !isSummary && !isGuideMeSec && editSec}
                    </>
                  ) : (
                    !isSummary &&
                    !isAboutPrompt &&
                    !isGuideMeSec &&
                    isFocused &&
                    hasUserAccessToEdit &&
                    editSec
                  )}
                </div>
              )}
            </div>

            <div
              className="query-block-header-right-sec"
              data-testid="analysis-detail-page-query-block-header-right-sec"
            >
              <QueryActionSection
                handlers={handlers}
                queryItem={queryItem}
                hasUserAccessToEdit={hasUserAccessToEdit}
              />
            </div>
          </>
        )}
      </div>
    </QueryBlockHeaderStyled>
  );
};

export default QueryBlockHeader;
