import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "../../..";

import {
  useCancelModal,
  useGetAppState,
  useSetData,
} from "../../../../customhooks";

import { FormItemStyled, FormStyled } from "../../../form";
import {
  S3ConfigurationForm,
  S3ConfigurationModalProps,
} from "./s3configurationmodal.types";

import { S3ConfigurationModalStyled } from "./s3configurationmodal.styles";
import { InputField, TextAreaField } from "../../../formfields";
import { useRequestWithMethod } from "../../../../api";
import { API_CONFIG } from "../../../../constants/apiconfig";

import {
  createFieldLog,
  getS3ConfigurationModalConfig,
  getS3ResourceType,
  handleErrorMessage,
} from "./s3configurationmodal.utils";

import StateHandler from "../../../statehandler";
import { s3configurationformschema } from "../../../../utils/schemas";
import { CustomException } from "../../../../customexception";

const { get_s3_configuration: getS3Configuration } = API_CONFIG;

const S3ConfigurationModal = (): JSX.Element => {
  const onCancel = useCancelModal();
  const onSetData = useSetData();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    type = "s3_source_bucket",
    region = "",
    access_key = "",
    secret_key = "",
    defaultValues,
    onSuccess,
    updateFieldLogs,
  } = modalProps as S3ConfigurationModalProps;

  const [dialogError, setDialogError] = useState<CustomException>();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<S3ConfigurationForm>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onChange",
    resolver: yupResolver(s3configurationformschema(type)),
  });

  const formFields = useMemo(() => {
    const config = getS3ConfigurationModalConfig(type);

    return config?.map((item, index) => {
      return (
        <FormItemStyled
          key={`s3-config-form-field-${index}`}
          paddingLeft="0px"
          label={item?.label}
          required={item?.isRequired}
        >
          {item?.fieldType === "textarea" ? (
            <TextAreaField
              control={control}
              name={item?.fieldName}
              width="333px"
              maxLength={1000}
              placeholder={item?.placeholder}
              disabled={item?.disabled}
            />
          ) : (
            <InputField
              name={item?.fieldName}
              control={control}
              placeholder={item?.placeholder}
              disabled={item?.disabled}
              width="333px"
              height="34px"
            />
          )}
        </FormItemStyled>
      );
    });
  }, [type]);

  const { isLoading, onExecuteRequest } = useRequestWithMethod(
    "save_s3_configuration",
    undefined,
    true,
    undefined,
    undefined,
    {
      "Access-key": access_key,
      "Secret-key": secret_key,
    }
  );

  const onCreate = useCallback(
    (formValues) => {
      updateFieldLogs([createFieldLog(type, formValues, "request sent")]);

      onExecuteRequest(
        {
          action: "create-resource",
          region,
          resourceType: getS3ResourceType(type)?.save,
          resourceInfo: {
            ...formValues,
          },
        },
        undefined,
        (res): void => {
          onSuccess(
            type,
            ["s3_source_bucket", "s3_staging_bucket"]?.includes(type)
              ? formValues?.bucket_name
              : formValues?.name
          );

          onCancel();
        },
        undefined,
        (err): void => {
          const updatedError = new CustomException({
            message: handleErrorMessage(type, err?.formattedMsg),
          });

          setDialogError(updatedError);
          updateFieldLogs([
            createFieldLog(type, formValues, "failed"),
            err?.formattedMsg,
          ]);
        }
      );
    },
    [
      type,
      region,
      onSuccess,
      handleErrorMessage,
      setDialogError,
      onCancel,
      updateFieldLogs,
      createFieldLog,
    ]
  );

  return (
    <S3ConfigurationModalStyled>
      <StateHandler error={dialogError} isModal isFetching={isLoading}>
        <FormStyled paddingLeft="126px" paddingRight="126px" isItemColumnLayout>
          <div className="form-body">{formFields}</div>

          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="78px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              disabled={!isValid}
              onClick={handleSubmit(onCreate)}
            >
              Create
            </Button>
          </FormItemStyled>
        </FormStyled>
      </StateHandler>
    </S3ConfigurationModalStyled>
  );
};

export default S3ConfigurationModal;
