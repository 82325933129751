import { APP_ROUTES } from "../constants";

export const checkIsChatPage = (): boolean => {
  const pathname = window?.location?.pathname || "";
  try {
    return (
      (pathname?.includes(APP_ROUTES.private_routes.chat) ||
        pathname?.includes(APP_ROUTES.private_routes.read_only_chat) ||
        pathname?.includes(
          APP_ROUTES.private_routes.view_only_chat_question
        )) &&
      !pathname?.includes(APP_ROUTES.private_routes.ai_agent) &&
      !pathname?.includes(APP_ROUTES.private_routes.tool) &&
      !pathname?.includes(APP_ROUTES.private_routes.my_settings)
    );
  } catch (e) {
    return false;
  }
};
