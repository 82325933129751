import moment from "moment";
import { Key } from "react";

// <--- Types Start --->
import { ShareLevelId } from "../../components/sharelevelicon/sharelevelicon.types";
import {
  AnalysisDetailsDocResponse,
  AnalysisQueriesResponseType,
  AnalysisQueryDetailResponseType,
  AnalysisNodeSubType,
  QuerySubType,
  QueryType,
} from "../../parsers/analysisparser";

import {
  AnalaysisPageState,
  AnalysisPageQueryBox,
  AnalysisPageQueryBoxChartConfig,
  QueryBlockGridFilter,
  QueryBoxFiltersType,
  QueryExplanationType,
  UserQueriesPrefOfAnswerOutput,
} from "./analysisdetailpage.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  checkDataTypeOfColumn,
  checkSourceType,
  formatPercentage,
  getObjectKeys,
  getPostLoginData,
  isDateInRange,
  isNumber,
  isNumberInRange,
  jsonParse,
  jsonStringify,
  localTimeZoneToUtc,
  toCapitalize,
} from "../../utils";

import { KeyValuePairType, SourceTypes } from "../../app.types";
import { ColumnChartDataType, ColumnDataType } from "../../parsers";
import { getChatPrefrencesData } from "../../utils/getchatprefrencesdata";
import { LOCAL_STORAGE_CONSTANTS } from "../../constants";

// <--- Utils End --->

export const filterQueryFiltersWhereFilterModelsExists = (
  filters: QueryBoxFiltersType
): QueryBlockGridFilter[] => {
  return getObjectKeys(filters)
    ?.filter((fil) => filters?.[fil]?.filter_model)
    ?.map((filItem) => filters?.[filItem]);
};

export const transformFormatOfFilterModelToBEModel = (
  filters: QueryBoxFiltersType
): { [key: string]: QueryBlockGridFilter["filter_model"] } => {
  return filterQueryFiltersWhereFilterModelsExists(filters)?.reduce(
    (prev, next) => ({
      ...prev,
      [next?.colm_name]: next?.filter_model,
    }),
    {}
  );
};

export const mergeTransientFiltersAndAppliedFilters = (
  apliedFilters: QueryBoxFiltersType,
  transientFilters: QueryBoxFiltersType
): QueryBoxFiltersType => {
  const mergedFilters: QueryBoxFiltersType = {
    ...apliedFilters,
    ...transientFilters,
  };

  return mergedFilters;
};

export const shareLevelMapper = (shareLevel: ShareLevelId): string => {
  const shareLevels: { [key in ShareLevelId]: string } = {
    ALL: "Shared with Everyone",
    LIM: "Shared with group",
    NON: "Private",
    CHT: "Chat",
  };

  return shareLevels[shareLevel];
};

export const extractTableNameFromQuery = (query: string): string => {
  const regEscape = (v: string): string =>
    v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

  const tableName = query
    ?.split(new RegExp(regEscape("from"), "ig"))?.[1]
    ?.trim()
    ?.split(" ")?.[0];

  return tableName || "";
};

const PROPERTIES_TO_IGNORE = [
  "qry_created_by",
  "qry_created_on",
  "qry_exec_by_id",
  "qry_record_cnt",
  "qry_updated_by",
  "qry_updated_on",
  "qry_created_by_id",
  "qry_updated_by_id",
  "is_verbose_visible",
  "qry_props",
  "qry_created_by_last_name",
  "qry_created_by_first_name",
  "is_helpful",
];

const PROPERTY_VALUES_TO_IGNORE = [
  '""',
  "false",
  "undefined",
  "{}",
  "[]",
  '"{}"',
  '"[]"',
];

export const convertLocalQueriesDataToQueriesDoc = (
  analysisSrcTypeId: SourceTypes,
  responseData: AnalysisQueriesResponseType,
  queriesToSave: AnalysisPageQueryBox[],
  isChatPage: boolean,
  isSavingClonedQueries?: boolean,
  allQueries?: AnalysisPageQueryBox[]
): AnalysisQueryDetailResponseType[] => {
  const userChatPreference = getChatPrefrencesData();

  const { date_format: userPrefDateFormat } = userChatPreference || {};

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);

  const queryConversion = (
    query: AnalysisPageQueryBox,
    index: number,
    isSubQry?: boolean
  ): AnalysisQueryDetailResponseType => {
    const resQryDetails: AnalysisQueryDetailResponseType = jsonParse(
      responseData?.find((qry) => qry?.QRY_UUID === query?.id)?.QRY_DETAILS,
      true
    );

    const responseQueryTable = resQryDetails?.qry_props?.qry_tbls || {
      tbl_alias_nm: "",
    };

    const chartConfig: AnalysisPageQueryBoxChartConfig = query?.results
      ?.chart_config || { is_default_settings: true };

    const resQry: AnalysisQueryDetailResponseType = {
      ...(isRestAPISource && {
        qry_cols: query?.results?.columns,
      }),
      qry_seq_id: isSavingClonedQueries ? null : query?.qry_sec_id || null,
      parent_query_id: query?.parent_query_id || "",
      qry_result_sec_title: query?.results?.sec_title || "",
      qry_id: query?.id || "",
      ...(isChatPage && {
        ref_node_id: query?.selected_topic_id || 0,
        agent_id: query?.agent_id || 0,
      }),
      qry_desc: "",
      qry_name: query?.header?.title || "",
      qry_text: query?.query?.sql_query || "",
      qry_type: query?.type || "NCQ",
      qry_sub_type: query?.sub_type,
      qry_order: index + 1,
      qry_props: {
        qry_tbls: {
          ...responseQueryTable,
          tbl_id: Number(query?.table_id || "0"),
          tbl_name: query?.table_name || "",
        },
        qry_filters: jsonStringify(
          transformFormatOfFilterModelToBEModel(
            mergeTransientFiltersAndAppliedFilters(
              query?.filters?.applied_filters || {},
              query?.filters?.transiant_filters || {}
            )
          )
        ),
        qry_dyn_filters: null,
        qry_select_cols: [],
        qry_order_by_cols: [],
      },
      qry_exec_by: query?.qry_exec_by || "",
      qry_exec_on: query?.qry_exec_on?.split(".")?.[0] || null,
      qry_created_by: query?.qry_created_by || "",
      qry_created_on: query?.qry_created_on?.split(".")?.[0] || null,
      qry_created_by_first_name: query?.qry_created_by_first_name || "",
      qry_created_by_last_name: query?.qry_created_by_last_name || "",
      qry_exec_by_id: 0,
      qry_record_cnt: query?.results?.record_count || 0,
      qry_updated_by: query?.qry_updated_by || "",
      qry_updated_on: query?.qry_updated_on?.split(".")?.[0] || null,
      qry_created_by_id: 0,
      qry_updated_by_id: 0,
      qry_prf_results_s3_url: query?.qry_prf_results_s3_url || "",
      qry_tmp_results_s3_url: query?.qry_tmp_results_s3_url || "",
      qry_full_results_s3_url: query?.qry_full_results_s3_url || "",
      verbose_s3_url: query?.verbose_s3_url || "",
      total_records: query?.results?.record_count || 0,
      filtersForUI: jsonStringify(
        mergeTransientFiltersAndAppliedFilters(
          query?.filters?.applied_filters || {},
          query?.filters?.transiant_filters || {}
        )
      ),
      tbl_record_count: query?.results?.tbl_record_count ?? 0,
      is_sample_data: !!query?.results?.is_sample_data,
      is_caddi_sample_data: query?.results?.is_caddi_sample_data,
      is_expanded: !!query?.is_expanded,
      is_technical_details_expanded: !!query?.is_technical_details_expanded,
      is_response_type_non_technical: !!query?.is_response_type_non_technical,
      use_context: query?.header?.is_use_context || false,
      da_context: jsonStringify(query?.header?.query_context || []),
      summary: query?.results?.summary,
      summary_response_keys: query?.results?.summary_response_keys || [],
      guide_me_data: query?.results?.guide_me_data || undefined,
      prompt_summary: query?.results?.prompt_summary,
      result_type: query?.header?.result_type || "chart",
      error: query?.error,
      qry_status: query?.qry_status,
      is_helpful:
        query?.user_feedback?.response === "like"
          ? true
          : query?.user_feedback?.response === "dislike"
          ? false
          : null,
      qry_reason_cd: query?.user_feedback?.reason || "",
      qry_comment: query?.user_feedback?.comment || "",
      qry_train_me: query?.user_feedback?.train_me || "",
      qry_train_sql: query?.user_feedback?.train_sql || "",
      tables_used_in_answer: jsonStringify(
        query?.results?.tables_used_in_answer || []
      ),
      query_explanation:
        typeof query?.results?.query_explanation === "string"
          ? query?.results?.query_explanation
          : jsonStringify(query?.results?.query_explanation),
      questiont_answer: query?.results?.questiont_answer || "",
      detailed_answer: query?.results?.detailed_answer || "",
      chart_config: {
        chart_x_axis: chartConfig?.chart_x_axis || "",
        chart_x_Axis_data_type: chartConfig?.chart_x_Axis_data_type,
        chart_y_axises: chartConfig?.chart_y_axises || [],
        sort_direction: chartConfig?.sort_direction,
        sort_info_of_y_axis: chartConfig?.sort_info_of_y_axis,
        has_no_y_axes: chartConfig?.has_no_y_axes || false,
        is_default_settings: chartConfig?.is_default_settings || false,
      },

      sort_model: query?.filters?.sortModel || [],

      // is_data_formatted: query?.results?.is_data_formatted || true,
      pivot_state: jsonStringify(query?.results?.pivot_state || []),
      use_du_tool: !!query?.use_du_tool,
      use_general_knowledge_tool: !!query?.use_general_knowledge_tool,
      use_sql_tool: !!query?.use_sql_tool,
      is_explanation_visible: !!query?.header?.is_explanation_visible,
      verbose_response: isRestAPISource
        ? query?.results?.verbose_response || []
        : [], // only for rest api
      is_sql_query_visible: !!query?.header?.is_sql_query_visible,
      is_verbose_visible: !!query?.header?.is_verbose_visible,
      is_question_saved: !!query?.header?.is_question_saved,
      settings: {
        ...query?.settings,
        // date_format_preference: userPrefDateFormat,
        tab: "general",
        visible: false,
      },
      rephrased_question: query?.rephrased_question || "",
      ...(query?.tool_used?.id && { tool_used: query?.tool_used }),
      audience_type_used: query?.audience_type_used || "",
    };

    if (isSubQry) {
      try {
        const cleanResQry: AnalysisQueryDetailResponseType = Object.fromEntries(
          Object.entries(resQry).filter(([key, value]) =>
            PROPERTIES_TO_IGNORE.includes(key)
              ? false
              : !PROPERTY_VALUES_TO_IGNORE.includes(jsonStringify(value))
          )
        ) as AnalysisQueryDetailResponseType;

        return cleanResQry;
      } catch {
        return resQry;
      }
    }
    return resQry;
  };

  const parentQrysWithChildQrys: AnalysisPageQueryBox[] = queriesToSave?.map(
    (qryToSave) => {
      const isChildQuery = qryToSave?.parent_query_id;
      const parentQuery = isChildQuery
        ? allQueries?.find((qry) => qry?.id === qryToSave?.parent_query_id)
        : undefined;

      if (isChildQuery && parentQuery) {
        return {
          ...parentQuery,
          sub_queries: allQueries?.filter(
            (qry) => qry?.parent_query_id === parentQuery?.id
          ),
        };
      }

      return {
        ...qryToSave,
        sub_queries: allQueries?.filter(
          (qry) => qry?.parent_query_id === qryToSave?.id
        ),
      };
    }
  );

  return parentQrysWithChildQrys?.map((parentQryWithChildQry, index) => ({
    ...queryConversion(parentQryWithChildQry, index),
    sub_queries: parentQryWithChildQry?.sub_queries?.map((subQry, subIndex) =>
      queryConversion(subQry, subIndex, true)
    ),
  }));
};

export const convertLocalDataToAnalysisDoc = (
  analysisSrcId: string,
  analysisSrcTypeId: SourceTypes,
  analysisSrcName: string,
  analysisNodeSubType: AnalysisNodeSubType,
  responseData: AnalysisDetailsDocResponse,
  state: AnalaysisPageState,
  isChatPage: boolean,
  shouldUpdatePrompt?: boolean
): AnalysisDetailsDocResponse => {
  const latestRefreshedQuery = state?.queries?.reduce((acc, cur) => {
    if (
      new Date(cur?.qry_exec_on || 0)?.getTime() >
      new Date(acc.qry_exec_on || 0)?.getTime()
    ) {
      return cur;
    }

    return acc;
  }, state?.queries[0]);

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);
  const isTool = analysisNodeSubType === "TOL";

  return {
    ...responseData,
    is_da_config_updated: !!shouldUpdatePrompt,
    tables: {
      value:
        state?.pinnedTables?.map((pinTable) => ({
          anl_tbl_id: Number(pinTable?.id || 0) || 0,
          anl_tbl_name: pinTable?.name || "",
          anl_tbl_title: pinTable?.title || "",
          anl_tbl_type: "TBL",
        })) || [],
      is_changed: true,
    },
    ...(isRestAPISource && !isTool
      ? {
          pinned_tools: {
            value:
              state?.pinnedTools?.map((tool) => ({
                pin_tool_id: tool?.id || 0,
                pin_tool_name: tool?.name || "",
              })) || [],
            is_changed: true,
          },
        }
      : {
          pinned_tables: {
            value:
              state?.pinnedTables?.map((table) => ({
                pin_tbl_id: table?.id || 0,
                pin_tbl_name: table?.name || "",
                pin_tbl_title: table?.title || "",
                pin_tbl_type: "TBL",
              })) || [],
            is_changed: true,
          },
        }),
    da_name: {
      value: state?.header?.title || "",
      is_changed: true,
    },
    src_id: Number(analysisSrcId) || 0,
    node_desc: "",
    node_name: state?.header?.title || "",
    node_type: "ANL",
    parent_id: Number(analysisSrcId) || 0,
    da_access: isChatPage
      ? {
          is_changed: true,
          value: {
            da_share_level: "Chat",
            da_share_level_id: "CHT",
          },
        }
      : responseData?.da_access,
    da_queries_cnt:
      state?.queries?.filter((qry) => !qry?.parent_query_id)?.length || 0,
    description: {
      value: state?.header?.desc || "",
      is_changed: true,
    },
    parent_name: analysisSrcName || "",
    def_tbl_id: {
      value: state?.table_id || null,
      is_changed: true,
    },
    src_type_id: analysisSrcTypeId || "",
    node_sub_type: isChatPage ? "CHT" : analysisNodeSubType || "ANL",
    da_exec_dtls: {
      value: {
        last_executed_by: latestRefreshedQuery?.qry_exec_by || null,
        last_executed_on:
          latestRefreshedQuery?.qry_exec_on?.split(".")?.[0] || null,
        last_execution_id: null,
        last_executed_by_id: latestRefreshedQuery?.qry_exec_by_id || null,
      },
      is_changed: true,
    },
    da_props: {
      is_changed: true,
      value: {
        ref_src_id: Number(analysisSrcId) || 0,
        da_context: jsonStringify(
          state?.analysis_info?.selected_contexts || []
        ),
        llm_provider: state?.aiChatModel || "",
        llm_provider_name: state?.aiChatModelName || "",
        ...(isChatPage && {
          ref_node_id: state?.analysis_info?.selected_topic_id || 0,
        }),

        // ...(isRestAPISource && {
        prompt_inputs: {
          ai_assistant_type:
            state?.configuration?.prompt_inputs?.ai_assistant_type || "",
          guidelines: state?.configuration?.prompt_inputs?.guidelines || "",
          data_description:
            state?.configuration?.prompt_inputs?.data_description || "",
          logic: state?.configuration?.prompt_inputs?.logic || "",
          response_format:
            state?.configuration?.prompt_inputs?.response_format || "",
          audience_tailoring:
            state?.configuration?.prompt_inputs?.audience_tailoring || "",
          api_response_format:
            state?.configuration?.prompt_inputs?.api_response_format || "",
          intent_logic: state?.configuration?.prompt_inputs?.intent_logic || "",
          tool_selection_logic:
            state?.configuration?.prompt_inputs?.tool_selection_logic || "",
        },
        workflow_json_s3_pre_signed_get_url:
          state?.configuration?.workflow_json_s3_pre_signed_get_url || "",
        // }),
      },
    },
    is_scope_enabled: {
      value: state?.is_scope_enabled,
      is_changed: true,
    },
    parent_analysis_id: state?.analysis_info?.parent_analysis_id || 0,
    is_node_cloned: state?.analysis_info?.is_node_cloned || false,
    selected_agent_id: {
      value: state?.selected_agent_id || 0,
      is_changed: true,
    },
    agents: {
      is_changed: true,
      value: state?.agents?.map((agent) => ({
        agent_id: agent?.id || 0,
        agent_name: agent?.title || "",
      })),
    },
  };
};

export const createProfilingRange = (
  min: Key,
  max: Key,
  prefix = "",
  suffix = ""
): string => {
  return `(${prefix}${min}${suffix} - ${prefix}${max}${suffix})`;
};

export const getQueryCreatedByInfo = (): {
  qry_created_by: string;
  qry_created_on: string;
  qry_created_by_first_name: string;
  qry_created_by_last_name: string;
} => {
  const { user_info: userInfo } = getPostLoginData();
  const {
    user_name: userName = "",
    user_first_name: userFirstName = "",
    user_last_name: userLastName = "",
  } = userInfo || {};
  return {
    qry_created_by: userName,
    qry_created_on: localTimeZoneToUtc(),
    qry_created_by_first_name: userFirstName,
    qry_created_by_last_name: userLastName,
  };
};

export const getQueryUpdatedByInfo = (): {
  qry_updated_by: string;
  qry_updated_on: string;
} => {
  const { user_info: userInfo } = getPostLoginData();
  const { user_name: userName = "" } = userInfo || {};
  return {
    qry_updated_by: userName,
    qry_updated_on: localTimeZoneToUtc(),
  };
};

export const getQueryExcecutedByInfo = (): {
  qry_exec_by: string;
  qry_exec_on: string;
} => {
  const { user_info: userInfo } = getPostLoginData();
  const { user_name: userName = "" } = userInfo || {};
  return {
    qry_exec_by: userName,
    qry_exec_on: localTimeZoneToUtc(),
  };
};

export const transformTableNameToUserFriendlyQueryTitle = (
  name: string
): string => {
  try {
    const tableName = name?.includes(".")
      ? name?.split(".")?.[1]
      : name?.split(".")?.[0];

    const removeUnderScore = tableName?.replace(/_/g, " ");
    const removeDashes = removeUnderScore.replace(/-/g, " ");

    return toCapitalize(removeDashes?.toLocaleLowerCase());
  } catch {
    return "";
  }
};

export const removeChatTopicFromClonedAnaylsis = (name: string): string => {
  try {
    const nameInLowerCase = name?.toLocaleLowerCase();
    const keywordIndex = nameInLowerCase?.indexOf("chatbot");
    return keywordIndex >= 0 ? name?.substring(0, keywordIndex) : name || "";
  } catch {
    return "";
  }
};

export const checkQueryType = (
  type: QueryType,
  subType?: QuerySubType
): {
  isSummary: boolean;
  isAboutPrompt: boolean;
  isGuideMeSec: boolean;
  isNoCodeQuery: boolean;
  isConceptQuery: boolean;
  isTermQuery: boolean;
} => {
  return {
    isSummary: type === "CCQ" && subType === "SMR",
    isAboutPrompt: type === "CCQ" && subType === "APT",
    isGuideMeSec: type === "CCQ" && subType === "GME",
    isNoCodeQuery: type === "NCQ",
    isConceptQuery: type === "CCQ" && subType === "CNP",
    isTermQuery: type === "CCQ" && subType === "TRM",
  };
};

export const checkhasQuestionChanged = (
  stateQueries: AnalysisPageQueryBox[],
  parsedQueries: AnalysisPageQueryBox[],
  id: string
): boolean => {
  const findedQuery = stateQueries?.find((query) => query?.id === id);

  const findedParsedDataQuery = parsedQueries?.find(
    (query) => query?.id === id
  );

  const isQuestionChanged =
    (findedQuery?.header?.title !== findedParsedDataQuery?.header?.title &&
      findedQuery?.id === findedParsedDataQuery?.id) ||
    !!findedQuery?.error;
  return isQuestionChanged;
};

export const splitProfilingRangeVal = (
  val: string
): { min: string; max: string; isBothExists: boolean } => {
  try {
    const splitedVal =
      val?.replaceAll("(", "")?.replaceAll(")", "")?.split(" - ") ?? [];
    const [first = "", seconed = ""] = splitedVal;
    return {
      min: first?.trim(),
      max: seconed?.trim(),
      isBothExists: !!(first?.trim() && seconed?.trim()),
    };
  } catch {
    return {
      min: "",
      max: "",
      isBothExists: false,
    };
  }
};

export const findMinMaxValues = (
  data: ColumnChartDataType,
  isDateType?: boolean,
  shouldFormatPercentage?: boolean,
  isPercFormatApplied?: boolean
): [number, number] => {
  let minValue = Number.POSITIVE_INFINITY;
  let maxValue = Number.NEGATIVE_INFINITY;
  data.forEach((item) => {
    const { name } = item;
    const values = name
      .replace(/[()]/g, "")
      .split(" - ")
      .map((value) => value.trim());

    const convertedValues = values.map((value) => {
      if (isDateType) {
        return moment.utc(+value).valueOf();
      }
      return parseFloat(value);
    });

    minValue = Math.min(minValue, ...convertedValues);
    maxValue = Math.max(maxValue, ...convertedValues);
  });
  return [
    shouldFormatPercentage
      ? formatPercentage(minValue, isPercFormatApplied)
      : minValue,
    shouldFormatPercentage
      ? formatPercentage(maxValue, isPercFormatApplied)
      : maxValue,
  ];
};

export const calculateStep = (
  min: number,
  max: number,
  count: number
): number => {
  const range = max - min;
  const desiredStepCount = count;
  const unroundedStep = range / desiredStepCount;
  return unroundedStep;
};

export const formatProfilingDate = (val: string): string => {
  try {
    const chatPrefrencesData = getChatPrefrencesData();
    const finalFormat = chatPrefrencesData?.date_format;

    const errMsg = "Invalid date";

    if (isNumber(val) && `${val}`?.length > 8) {
      const finalVal = moment?.utc(Number(val))?.format(finalFormat);
      return finalVal === errMsg ? "" : finalVal;
    }

    const finalVal = moment?.utc(val)?.format(finalFormat);

    return finalVal === errMsg ? "" : finalVal;
  } catch {
    return "";
  }
};

const computeDateColProfiling = (
  name: string,
  data: KeyValuePairType<any>[] = []
): ColumnChartDataType => {
  const itemData = data?.map((item) => item?.[name]);

  if (itemData?.length === 0) return [];

  const uniqueDates = [...new Set(itemData)];

  const dataWithDateType = uniqueDates?.map((item) => moment.utc(item));

  const sortedDates = dataWithDateType?.sort((a, b) =>
    a?.isBefore(b) ? -1 : a?.isAfter(b) ? 1 : 0
  );

  const minDate = sortedDates?.[0];
  const maxDate = sortedDates?.slice(-1)?.[0];

  const differenceInDays = maxDate?.diff(minDate, "days");

  // Create the date range
  const dateRange: string[] = [];

  if (differenceInDays < 20) {
    return sortedDates?.map((date) => {
      const startDate = date?.valueOf();
      const endDate = startDate;

      return {
        name: `(${startDate})`,
        Count: itemData?.reduce((prev, next) => {
          if (isDateInRange(startDate, endDate, `${next}`, true)) {
            return prev + 1;
          }

          return prev;
        }, 0),
        fill: "#428bca",
      };
    });
  }

  if (differenceInDays < 180) {
    const weekStart = minDate?.startOf("week");
    const weekEnd = maxDate?.endOf("week");

    dateRange.push(weekStart?.valueOf()?.toString());
    while (weekStart?.valueOf() < weekEnd?.valueOf()) {
      weekStart?.add(7, "days");
      dateRange.push(weekStart?.valueOf()?.toString());
    }
  } else if (differenceInDays < 730) {
    const monthStart = minDate?.startOf("month");
    dateRange.push(monthStart?.valueOf()?.toString());
    while (monthStart?.valueOf() < maxDate?.valueOf()) {
      monthStart?.add(1, "months");
      dateRange.push(monthStart?.valueOf()?.toString());
    }
  } else if (differenceInDays < 7300) {
    const quarterStart = minDate?.startOf("quarter");
    const quarterEnd = maxDate?.endOf("quarter");

    dateRange.push(quarterStart?.valueOf()?.toString());
    while (quarterStart?.valueOf() < quarterEnd?.valueOf()) {
      quarterStart?.add(1, "quarters");
      dateRange.push(quarterStart?.valueOf()?.toString());
    }
  } else {
    const yearStart = minDate?.startOf("year");

    dateRange.push(yearStart?.valueOf()?.toString());
    while (yearStart?.valueOf() < maxDate?.valueOf()) {
      yearStart?.add(1, "years");
      dateRange.push(yearStart?.valueOf()?.toString());
    }
  }

  const profiling = dateRange
    ?.map((date, index: number) => {
      const startDate = date;
      const endDate = dateRange?.[index + 1];
      const isLastElement = index + 1 >= dateRange?.length;

      return {
        name: createProfilingRange(startDate, endDate),
        Count: itemData?.reduce((prev, next) => {
          if (isDateInRange(+startDate, +endDate, `${next}`)) {
            return prev + 1;
          }

          return prev;
        }, 0),
        fill: isLastElement ? "" : "#428bca",
      };
    })
    ?.filter((item) => item?.fill);

  return profiling;
};

const computeNumberColProfiling = (
  name: string,
  data: KeyValuePairType<any>[] = [],
  isDecimalCol: boolean
): ColumnChartDataType => {
  const itemData = data?.map((item) => Number(item?.[name]));

  if (itemData?.length === 0) return [];

  const uniqueNumbers = [...new Set(itemData)];

  if (uniqueNumbers?.length <= 20) {
    return uniqueNumbers
      ?.sort((a, b) => a - b)
      ?.map((number) => {
        return {
          name: `(${isDecimalCol ? number : Math.floor(number)})`,
          Count: itemData?.reduce((prev, next) => {
            if (next === number) {
              return prev + 1;
            }

            return prev;
          }, 0),
          fill: "#428bca",
        };
      });
  }

  const minNumber = Math.min(...itemData);
  const maxNumber = Math.max(...itemData);

  const range = maxNumber - minNumber;

  // Calculate the rounding factor
  const roundFactor = 10 ** Math.floor(Math.log10(range) - 1);

  // Calculate the distribution interval
  const interval = Math.ceil(range / 20 / roundFactor) * roundFactor;

  // Calculate the rounded minimum value
  const minVal = Math.floor(minNumber / interval) * interval;

  // Calculate the date interval in milliseconds
  const numSteps = 20;

  // Create the date range
  const numberRange: number[] = [];
  for (let i = 0; i < numSteps; i++) {
    const newNumber = minVal;

    numberRange.push(
      isDecimalCol
        ? newNumber + i * interval
        : Math.floor(newNumber + i * interval)
    );
  }

  const filteredNumberRange = numberRange?.filter((item) => {
    return item <= maxNumber + roundFactor;
  });

  const profiling = filteredNumberRange
    ?.map((number, index: number) => {
      const startNumber = number;
      const endNumber = filteredNumberRange?.[index + 1];
      const isLastElement = index + 1 >= filteredNumberRange?.length;

      return {
        name: createProfilingRange(startNumber, endNumber),
        Count: itemData?.reduce((prev, next) => {
          if (isNumberInRange(next, startNumber, endNumber)) {
            return prev + 1;
          }

          return prev;
        }, 0),
        fill: isLastElement ? "" : "#428bca",
      };
    })
    ?.filter((item) => item?.fill);

  return profiling;
};

const computeStringColProfiling = (
  name: string,
  data: KeyValuePairType<any>[] = []
): ColumnChartDataType => {
  const itemData = data?.map((item) => item?.[name]);

  if (itemData.length === 0) return [];

  const uniqueData = [...new Set(itemData)];
  return uniqueData
    ?.map((attr) => {
      return {
        name: `${attr}`,
        Count: itemData?.reduce((prev, next) => {
          if (next === attr) {
            return prev + 1;
          }

          return prev;
        }, 0),
        fill: "#428bca",
      };
    })
    ?.sort((a, b) => b?.Count - a?.Count)
    ?.slice(0, 100);
};

export const computeColProfiling = (
  name: string,
  dataType: ColumnDataType,
  data: KeyValuePairType<Key>[]
): ColumnChartDataType => {
  const {
    isDateColumn,
    isNumberCol,
    isStringColumn,
    isDecimalColumn,
  } = checkDataTypeOfColumn(dataType);
  if (isDateColumn) {
    return computeDateColProfiling(name, data);
  }

  if (isNumberCol) {
    return computeNumberColProfiling(name, data, isDecimalColumn);
  }

  if (isStringColumn) {
    return computeStringColProfiling(name, data);
  }
  return [];
};

export const computeColumnSortDirection = (
  currentDirection?: "asc" | "desc"
): "asc" | "desc" | undefined => {
  return currentDirection === "asc"
    ? "desc"
    : currentDirection === "desc"
    ? undefined
    : "asc";
};

export const getUserQrysPrefrOfAnsOutput = (): UserQueriesPrefOfAnswerOutput => {
  const userQrysPrefrences: UserQueriesPrefOfAnswerOutput = jsonParse(
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS.user_qrys_prefr_of_ans_output),
    true
  );
  return { ...userQrysPrefrences };
};

export const updateUserQrysPrefrOfAnsOutput = (
  pref: UserQueriesPrefOfAnswerOutput
): void => {
  const updUserQrysPref: UserQueriesPrefOfAnswerOutput = {
    ...getUserQrysPrefrOfAnsOutput(),
    ...pref,
  };

  localStorage.setItem(
    LOCAL_STORAGE_CONSTANTS.user_qrys_prefr_of_ans_output,
    jsonStringify({
      ...updUserQrysPref,
      ids_of_qrys_pref_show_code: getObjectKeys(
        updUserQrysPref?.qrys_pref_show_code
      ),
      ids_of_qrys_pref_show_expl: getObjectKeys(
        updUserQrysPref?.qrys_pref_show_expl
      ),
      ids_of_qrys_pref_show_logs: getObjectKeys(
        updUserQrysPref?.qrys_pref_show_logs
      ),
    })
  );
};

const IdentifierForLogsToSortTimestamp = "timestampToSort:";

export const appendTimestampInLogs = (log: string, timestamp: number): string =>
  `${log} ${IdentifierForLogsToSortTimestamp} ${timestamp}`;

export const removeTimestampFromLogs = (logWithTimestamp: string): string => {
  const parts =
    logWithTimestamp?.split(`${IdentifierForLogsToSortTimestamp}`) || [];
  return (parts?.[0] || "")?.trim();
};

export const getTimestameFromLogs = (logWithTimestamp: string): number => {
  const parts =
    logWithTimestamp?.split(`${IdentifierForLogsToSortTimestamp}`) || [];
  return Number(parts?.[1]) || 0;
};
