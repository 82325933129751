export const getCurrentTime = (): string => {
  try {
    const currentDate = new Date();

    // Get the day, month, and year
    const day = String(currentDate?.getDate())?.padStart(2, "0"); // Ensures two digits
    const month = String(currentDate?.getMonth() + 1)?.padStart(2, "0"); // Months are 0-based, so add 1
    const year = currentDate?.getFullYear();

    // Get the hours, minutes, and seconds
    const hours = String(currentDate?.getHours())?.padStart(2, "0");
    const minutes = String(currentDate?.getMinutes())?.padStart(2, "0");
    const seconds = String(currentDate?.getSeconds())?.padStart(2, "0");

    return `${day}${month}${year}${hours}${minutes}${seconds}`;
  } catch {
    return "";
  }
};
