import { useElementSize } from "usehooks-ts";
import { useCallback, useMemo, useRef, useState } from "react";
import { useOutsideClick } from "../../utils/useoutsideclick";

import LinkButton from "../linkbutton";
import { ShowMoreOrLessTextProps } from "./showmoreorlesstext.types";
import { ShowMoreOrLessTextStyled } from "./showmoreorlesstext.styles";

function ShowMoreOrLessText(props: ShowMoreOrLessTextProps): JSX.Element {
  const { text = "", textLimit = 130, showSingleLineText } = props;
  const [showMore, setShowMore] = useState(false);
  const showMoreOrLessRef = useRef(null);

  const [textRef, textSize] = useElementSize();

  const updatedTextLimit = useMemo(() => {
    return showSingleLineText ? textSize?.width / 7.2 : textLimit;
  }, [showSingleLineText, textSize?.width, textLimit]);

  const isTextGreateThanLimit = text?.length > updatedTextLimit;

  const onChangeShowMore = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  useOutsideClick(
    showMoreOrLessRef,
    (): void => {
      setShowMore(false);
    },
    [showMore]
  );

  return (
    <ShowMoreOrLessTextStyled
      ref={showMoreOrLessRef}
      data-testid="show-more-text-main-wrapper"
      className="show-more-text-main-wrapper"
      title={text}
    >
      <div ref={textRef}>
        {isTextGreateThanLimit ? (
          <>
            {showMore ? (
              <>{text}</>
            ) : (
              <> {text?.substring(0, updatedTextLimit)}...</>
            )}
            <LinkButton
              onClick={onChangeShowMore}
              marginLeft="5px"
              data-testid="show-more-btn"
            >
              {showMore ? "Show Less" : "Show More"}
            </LinkButton>
          </>
        ) : (
          <>{text || "--"}</>
        )}
      </div>
    </ShowMoreOrLessTextStyled>
  );
}

export default ShowMoreOrLessText;
