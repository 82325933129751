import styled from "styled-components";

export const SelectAgentforToolModalStyled = styled.div`
  .form-wrapper {
    padding: 30px 52px;

    .details {
      color: ${({ theme }): string => theme.textColors.GREY_40};
      margin-bottom: 20px;
    }

    .ant-form-item {
      padding-left: 0px;
    }

    .redirect-btn {
      padding: 0 5px;
    }

    .ant-select-selection-item-content {
      .redirect-btn {
        display: none;
      }
    }
  }
`;
