import { Button } from "../button/button.component";
import { databaseTableIcon } from "../../svgs";
import { EmptyTableStyled } from "./emptytableslate.styles";
import { EmptyTableSlateProps } from "./emptytableslate.types";

function EmptyTableSlate(props: EmptyTableSlateProps): JSX.Element {
  const {
    btnText,
    btnIcon,
    icon,
    desc,
    onClick,
    showBtn = true,
    heading = "",
    addBtnId = "",
    btnComponent,
  } = props;
  return (
    <EmptyTableStyled data-testid="empty-table-slate">
      <div className="icon" data-testid="empty-table-slate-icon">
        {icon || databaseTableIcon()}
      </div>

      {heading && <div className="heading">{heading}</div>}

      {desc && (
        <div className="desc" data-testid="empty-table-slate-desc">
          {desc && desc}
        </div>
      )}
      {showBtn && (
        <Button
          id="primary"
          width="139.2px"
          height="32.8px"
          fontSize="14px"
          onClick={onClick}
          data-testid="empty-table-slate-btn"
          elementId={addBtnId}
        >
          <span
            className="action-icon"
            data-testid="empty-table-slate-btn-icon"
          >
            {btnIcon || "+"}
          </span>
          {btnText}
        </Button>
      )}
      {btnComponent && btnComponent}
    </EmptyTableStyled>
  );
}

export default EmptyTableSlate;
