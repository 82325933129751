import { useCallback } from "react";
import { Tooltip } from "antd";
import { useFormContext } from "react-hook-form";

import {
  commentIcon2,
  managedByIcon,
  userGroupsIcon,
  userIcon,
} from "../../svgs";

import { ManagedBySectionRowStyled } from "./managedbysectionrow.styles";

import {
  ManagedBySectionRowEditableViewProps,
  ManagedBySectionRowprops,
} from "./managedbysectionrow.types";

import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import MultiSelectUsersField from "../formfields/multiselectusersfield";

import { TextAreaField } from "../formfields";
import { removeEmail } from "../../utils";

import { TERM_SECTION_TITLES } from "../../constants/labelconstants";
import { useOpenDrawer } from "../../customhooks";

import LinkButton from "../linkbutton/linkbutton";
import { CommentHistoryDrawerTypes } from "../../drawerviews/activityhistorydrawer/activityhistorydrawer.types";

const comments = commentIcon2();

function ManagedByRowEditableView(
  props: ManagedBySectionRowEditableViewProps
): JSX.Element {
  const { control, setValue } = useFormContext();
  const { isDataStewardField = false, nodeType } = props;

  const editableView = (): JSX.Element => {
    switch (props?.type) {
      case "textarea":
        return (
          <TextAreaField
            control={control}
            width="100%"
            height="134px"
            name={props?.title}
            defaultValue={props?.value}
          />
        );
      case "multiselect":
        return (
          <MultiSelectUsersField
            control={control}
            height="45px"
            name={props?.title}
            setValue={setValue}
            defaultValue={props?.selectedValues}
            width="100%"
            placeholder="Add one or more people"
            onlyUsers={isDataStewardField}
            onlyUsersWithAdminAndEditorRole={isDataStewardField}
            nodeType={nodeType}
          />
        );
      default:
        return (
          <div className="value-sec">{props?.customJSX || props?.value}</div>
        );
    }
  };

  return (
    <div className="section-vertical-column-edit-mode">
      <div className="title">
        {props.title}
        {props?.isInherited && (
          <Tooltip
            title={`Inherited from linked ${props?.inHeritedBy}`}
            overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
            placement="right"
          >
            {managedByIcon()}
          </Tooltip>
        )}
        {!props?.isInherited && ":"}
      </div>
      <div className="value">{editableView()}</div>
    </div>
  );
}

function ManagedBySectionRow(props: ManagedBySectionRowprops): JSX.Element {
  const {
    title,
    isEditable,
    keyValueTags,
    isInherited,
    inHeritedBy = "",
    nodeType = "",
    nodeId = "",
    commentActivity,
    isReportPage = false,
  } = props;

  const openDrawer = useOpenDrawer();

  const onOpenRef = useCallback(() => {
    openDrawer({
      drawerId: "comment_history_ref",
      visible: true,
      drawerProps: {
        nodeType,
        nodeId,
        actionType: "CMT",
        isReportPage,
        activityRequest: commentActivity,
      } as CommentHistoryDrawerTypes,
    });
  }, [nodeId, commentActivity, nodeType]);

  return (
    <ManagedBySectionRowStyled
      className="section-vertical"
      isEditable={isEditable}
    >
      {!isEditable ? (
        <>
          <div className="title">
            {title}
            {isInherited && (
              <Tooltip
                title={`Inherited from linked ${inHeritedBy}`}
                overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                placement="bottomLeft"
              >
                {managedByIcon()}
              </Tooltip>
            )}
            {!isInherited && ":"}
          </div>
          {keyValueTags?.length ? (
            <div className="value-sec">
              {[
                ...keyValueTags?.filter((valTag) => valTag?.type === "UGP"),
                ...keyValueTags?.filter((valTag) => valTag?.type === "USR"),
              ]?.map((item) => (
                <div className="value" key={item?.name} title={item?.name}>
                  {item?.type === "USR" ? userIcon : userGroupsIcon()}
                  {removeEmail(item?.name, item?.email) || "-"}
                </div>
              ))}
            </div>
          ) : props?.customJSX ? (
            <div className="value-sec-custom-jsx">
              {props.customJSX || "--"}
              {title === TERM_SECTION_TITLES?.MANAGED_BY?.AUDIT_TRAIL &&
                nodeId &&
                nodeType && (
                  <LinkButton onClick={(): void => onOpenRef()}>
                    <Tooltip
                      title="Show comments and activities history"
                      overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} show-comments-tooltip`}
                      placement="topLeft"
                    >
                      {comments}
                    </Tooltip>
                  </LinkButton>
                )}
            </div>
          ) : (
            <div className="value-sec empty-value">{userIcon} -</div>
          )}
        </>
      ) : (
        <ManagedByRowEditableView {...props} />
      )}
    </ManagedBySectionRowStyled>
  );
}

export default ManagedBySectionRow;
