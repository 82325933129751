import { LOCAL_STORAGE_CONSTANTS } from "../constants";

export const isLoggedInUserAndOtherUserEmailIsSame = (
  email: string
): boolean => {
  try {
    return (
      localStorage
        .getItem(LOCAL_STORAGE_CONSTANTS?.email)
        ?.toLocaleLowerCase() === email?.toLocaleLowerCase()
    );
  } catch {
    return false;
  }
};
