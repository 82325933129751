import { useCallback, useState, useMemo } from "react";

import { useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";

import { FormStyled, FormItemStyled } from "../../components/form";
import { InputField, SelectField } from "../../components/formfields";

import Transfer from "../../components/transfer";

import {
  TranferStateType,
  TransferRecordType,
} from "../../components/transfer/transfer.types";

import { AddGroupFormStyled } from "./addgroupform.styles";

import {
  AddUserGroupFormType,
  AddUsersGroupFormProps,
} from "./addgroupform.types";

import { Button } from "../../components";

import { UserGroupsGridColumnsType } from "../../pages/usergroupspage/usergroupspage.types";
import { useRequestWithMethod } from "../../api";

import { API_CONFIG } from "../../constants/apiconfig";
import StateHandler from "../../components/statehandler/statehandler";

import {
  useGetAllUserRoles,
  useGetAllUsersForGroups,
} from "../../api/userservice";

import { addGroupSchema } from "../../utils/schemas";
import { useCancelModal, useGetAppState } from "../../customhooks";

import { GetAllUsersParserReturnType } from "../../parsers/usergroups/usergroupsparser.types";
import {
  getUserPermissions,
  selectFilterOption,
  getDvSumInformativeText,
  getLoggedInUserEmail,
  sortObjectsArrayByKey,
} from "../../utils";
import FormItemLabel from "../../components/form/formitemlabel";

function AddUsersGroupForm(props: AddUsersGroupFormProps): JSX.Element {
  const {
    add_usr_grp_mdl_grp_nm: addUsrGrpMdlGrpNm,
    add_usr_grp_mdl_rl: addUsrGrpMdlRl,
    add_usr_grp_mdl_chs_usr: addUsrGrpMdlChsUsr,
  } = getDvSumInformativeText();

  const { isEditMode } = props;

  const {
    parsedData: usersData = [],
    isLoading: isLoading2,
    error: error2,
  } = useGetAllUsersForGroups();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    group_name = "",
    role_id: roleId = "",
    role = "",
    id = "",
    user_ids: userIds = [],
    is_Predefined_Group: isPredefinedGroup = false,
  } = modalProps as UserGroupsGridColumnsType;

  const { is_account_admin: isLoggedInUserAccountAdmin } = getUserPermissions();

  const parsedUsers = useMemo(() => {
    const ownerAccountEmail = usersData?.find((user) => user?.isOwnerAccount)
      ?.email;

    const loggedInUserEmail = getLoggedInUserEmail();

    const isLoggedInUserIsOwner = ownerAccountEmail === loggedInUserEmail;

    const filteredUsers = (isLoggedInUserIsOwner
      ? usersData
      : isLoggedInUserAccountAdmin
      ? usersData?.filter((user) => !user?.isOwnerAccount)
      : usersData?.filter(
          (user) => user?.adminAccessType !== "ACT" && !user?.isOwnerAccount
        )
    )?.filter((nestUsr) => nestUsr?.email !== loggedInUserEmail);

    return sortObjectsArrayByKey(
      filteredUsers?.filter(
        (item: GetAllUsersParserReturnType) =>
          item?.statusId === "ACT" || item?.statusId === "IVT"
      ),
      "name",
      true
    );
  }, [usersData, isLoggedInUserAccountAdmin]);

  const [state, setState] = useState<TranferStateType>({
    data: parsedUsers,
    targetKeys: isEditMode ? userIds : [],
  });

  const queryClient = useQueryClient();

  const {
    parsedData: allParsedRoles = [],
    isLoading: isLoading1,
    error: error1,
  } = useGetAllUserRoles();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
    setError,
    watch,
  } = useForm<AddUserGroupFormType>({
    defaultValues: {
      name: group_name || "",
      group_role: isEditMode ? role : undefined,
    },
    resolver: yupResolver(addGroupSchema),
    mode: "onChange",
  });

  const groupName = watch("name");

  const onCancel = useCancelModal();

  const onSuccess = useCallback(() => {
    const api = API_CONFIG?.get_all_user_groups?.url;
    // const data = queryClient.getQueryData(api) as any;
    // const filteredData =
    //   modalProps &&
    //   data?.data?.filter(
    //     (item: any) => item?.USER_GROUP_ID !== modalProps?.id
    //   );
    // queryClient.setQueryData(api, {
    //   ...data,
    //   data: modalProps
    //     ? [...filteredData, { ...response?.data }]
    //     : [...data?.data, { ...response?.data }],
    // });
    queryClient.invalidateQueries(api, { fetching: false });
    onCancel();
  }, [modalProps]);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    isEditMode ? "edit_user_group" : "add_user_group",
    isEditMode ? [id] : undefined,
    true,
    onSuccess
  );

  const onSubmit = useCallback(
    (values) => {
      onExecuteRequest({
        name: values?.name,
        description: "New User Group for Admistrators",
        role_id:
          isEditMode && role === values?.group_role
            ? roleId
            : values?.group_role,
        add_user_ids: state?.targetKeys?.join(",") || "",
        remove_user_ids: !isEditMode
          ? ""
          : userIds
              ?.filter((item) => !state?.targetKeys?.includes(item))
              ?.join(","),
        userIds: state?.targetKeys?.map((item) => `'${item}'`)?.join(",") || "",
      });
    },
    [modalProps, state]
  );

  const transferData = useMemo(() => {
    return parsedUsers.map((item: TransferRecordType) => ({
      ...item,
      description: state.targetKeys?.includes(item?.key)
        ? groupName
        : userIds?.includes(item?.key) && !state.targetKeys?.includes(item?.key)
        ? "Users"
        : item?.description,
    }));
  }, [groupName, parsedUsers, state.targetKeys]);

  const selectOptionsForRoles = useMemo(() => {
    const filteredRoles = isLoggedInUserAccountAdmin
      ? allParsedRoles
      : allParsedRoles?.filter((role) => role?.admin_access_type_id !== "ACT");

    return filteredRoles?.map((role) => ({
      label: role?.name,
      value: `${role?.id}`,
    }));
  }, [allParsedRoles, isLoggedInUserAccountAdmin]);

  return (
    <StateHandler
      isFetching={isLoading || isLoading1 || isLoading2}
      error={error || error1 || error2}
      isModal
    >
      <AddGroupFormStyled>
        <FormStyled onFinish={handleSubmit(onSubmit) as any} isItemColumnLayout>
          <div className="scroll-sec">
            <FormItemLabel
              label={addUsrGrpMdlGrpNm?.field_name || ""}
              description={addUsrGrpMdlGrpNm?.description || ""}
              required
              marginBottom="25px"
              paddingLeft="334px"
            >
              <InputField
                control={control}
                name="name"
                width="512px"
                disabled={isPredefinedGroup}
                isMessagePositionAbsolute
                placeholder="Group Name"
              />
            </FormItemLabel>
            <FormItemLabel
              label={addUsrGrpMdlRl?.field_name || ""}
              description={addUsrGrpMdlRl?.description || ""}
              marginBottom="25px"
              paddingLeft="334px"
              required
            >
              <SelectField
                control={control}
                name="group_role"
                setValue={setValue}
                options={selectOptionsForRoles}
                width="512px"
                placeholder="Select"
                disabled={isPredefinedGroup}
                setError={setError}
                isMessagePositionAbsolute
                showSearch
                filterOption={selectFilterOption}
              />
            </FormItemLabel>
            <FormItemLabel
              label={addUsrGrpMdlChsUsr?.field_name || ""}
              description={addUsrGrpMdlChsUsr?.description || ""}
              paddingLeft="334px"
              marginBottom="62px"
            >
              <Transfer
                state={{ ...state, data: transferData }}
                setState={setState}
                leftTile={<div className="transfer-left-tilte">Available</div>}
                rightTile="Selected"
                showError={false}
              />
            </FormItemLabel>
          </div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </AddGroupFormStyled>
    </StateHandler>
  );
}

export default AddUsersGroupForm;
