import styled from "styled-components";

export const UserNameInitialsStyled = styled.div<{
  hasIcon?: boolean;
  isChatPage?: boolean;
  paddingRight?: string;
  addBorderAndBgColor?: boolean;
}>`
  cursor: default;
  border-radius: 6px;
  border: ${({ theme, hasIcon, addBorderAndBgColor }): string =>
    !addBorderAndBgColor
      ? ""
      : hasIcon
      ? `solid 1px ${theme?.borderColors?.GREY_56}`
      : ""};
  background-color: ${({
    theme,
    hasIcon,
    isChatPage,
    addBorderAndBgColor,
  }): string =>
    !addBorderAndBgColor
      ? ""
      : isChatPage && hasIcon
      ? theme?.bgColors?.BLUE_116
      : hasIcon
      ? theme?.bgColors?.WHITE_COLOR
      : theme?.bgColors?.LIGHT_GREY_22};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  padding-right: ${({ paddingRight }): string => paddingRight || ""};

  span {
    font-family: OpenSansSemiBold;
    color: ${({ theme }): string => theme.textColors.GREY_24};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
