import { infoIcon } from "../../svgs";
import { InfoMessageStyled } from "./infomessage.styles";
import { InfoMessagePropsType } from "./infomessage.types";

const InfoMessage = (props: InfoMessagePropsType): JSX.Element => {
  const {
    message,
    width = "296px",
    marginTop = "",
    marginBottom = "8px",
  } = props;
  return (
    <InfoMessageStyled
      width={width}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {infoIcon}
      {message}
    </InfoMessageStyled>
  );
};

export default InfoMessage;
