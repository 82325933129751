import { useCallback, useMemo, useState } from "react";
import { Spin, Tooltip } from "antd";

import { ICellRendererParams } from "ag-grid-community";

import {
  HighlightedCircleBackround,
  ContentPreviewer,
} from "../../../../components";

import {
  blueBotIcon,
  blueEarthIcon,
  databaseIcon,
  deleteIcon,
  editIcon,
} from "../../../../svgs";

import {
  ActionRendrerStyled,
  GuidelineRendrerStyled,
  TypeRendrerStyled,
} from "./guidelinestab.styles";

import LinkButton from "../../../../components/linkbutton/linkbutton";
import { TrainbotShareConceptFormProps } from "../../../../forms/trainbotshareconceptform";

import { useOpenModal, useSetData } from "../../../../customhooks";
import ConfirmationPopover from "../../../../components/confirmationpopover";

import { openNotification } from "../../../../utils";
import SuccessNotificationMessage from "../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";

import Flex from "../../../../components/flex";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../constants";

import { AnalysisPageQueryBox } from "../..";
import { QueryBlocksProps } from "../../analysisdetailpage.views";

import { BotGuidelineType } from "../../../../parsers";
import { useRequestWithMethod } from "../../../../api";

import { API_CONFIG } from "../../../../constants/apiconfig";
import { useGetCaddiNegFeedbackProps } from "../../useGetCaddiNegFeedbackProps";

export const GuidelineRendrer = (props: ICellRendererParams): JSX.Element => {
  const typedData: BotGuidelineType = props?.data || {};

  const {
    question = "",
    sql_query: sqlQuery = "",
    explanation = "",
    guideline = "",
    type,
  } = typedData;

  return (
    <GuidelineRendrerStyled isConcept={typedData?.type === "concept"}>
      {type === "concept" ? (
        <div title={guideline} className="concept-sec">
          {guideline}
        </div>
      ) : (
        <>
          <div title={question}>{question}</div>

          {sqlQuery ? (
            <ContentPreviewer sqlQuery={sqlQuery} />
          ) : (
            <div title={explanation}>{explanation}</div>
          )}
        </>
      )}
    </GuidelineRendrerStyled>
  );
};

const earthIcon = blueEarthIcon();
const dbIcon = databaseIcon;
const botIcon = blueBotIcon("16", "14");

export const TypeRendrer = (props: ICellRendererParams): JSX.Element => {
  const typedData: BotGuidelineType = props?.data;

  const typeTitle = typedData?.type === "feedback" ? "Feedback" : "Concept";

  const { title, icon, dataTestId } = useMemo(() => {
    switch (typedData?.concept_level) {
      case "ACT":
        return {
          title: "Account Level",
          icon: earthIcon,
          dataTestId: "caddi-training-grid-item-account-level",
        };
      case "SRC":
        return {
          title: "Source Level",
          icon: dbIcon,
          dataTestId: "caddi-training-grid-item-source-level",
        };
      case "ANL":
        return {
          title: "Agent Level",
          icon: botIcon,
          dataTestId: "caddi-training-grid-item-bot-level",
        };
      default:
        return {};
    }
  }, [typedData?.concept_level]);

  return (
    <TypeRendrerStyled title={typeTitle}>
      {typeTitle}

      {typedData?.type === "concept" && (
        <Tooltip title={title} overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}>
          <span data-testid={dataTestId} className="type-icon">
            {icon}
          </span>
        </Tooltip>
      )}
    </TypeRendrerStyled>
  );
};

export const ActionsRendrer = (props: ICellRendererParams): JSX.Element => {
  const onOpenModal = useOpenModal();
  const onSetData = useSetData();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [deleteTooltipVisible, setDeleteTooltipVisible] = useState(false);

  const [deletefeedbackIsLoading, setDeleteFeedbackIsLoading] = useState(false);

  const isAnyRowSelected = !!props?.api?.getSelectedRows()?.length;
  const typedData: BotGuidelineType = props?.data;
  const isConcept = typedData?.type === "concept";

  const { onDislikeClick } = useGetCaddiNegFeedbackProps(
    (typedData?.query_data || {}) as AnalysisPageQueryBox,
    (typedData?.handlers || {}) as QueryBlocksProps["handlers"],
    typedData?.queries,
    typedData?.src_type_id,
    !typedData?.query_data?.results?.chart_config?.chart_type &&
      !isConcept &&
      !!typedData?.query_data?.results?.columns?.length,
    true,
    props?.colDef?.cellRendererParams?.gatewayId
  );

  const onEditClick = useCallback(
    (e) => {
      e?.stopPropagation();

      if (typedData?.type === "concept") {
        const modalProps: TrainbotShareConceptFormProps = {
          description: typedData?.guideline || "",
          conceptScope: typedData?.concept_level || "ANL",
          analysisId: typedData?.analysis_id || "",
          sourceId: typedData?.source_id || "",
          sourceName: typedData?.source_name || "",
          conceptId: typedData?.id || "",
          currentBotId: typedData?.currentBotId,
          currentBotSrcId: typedData?.currentBotSourceId,
        };

        onOpenModal({
          modalId: "train_bot_share_concept_modal",
          visible: true,
          modalProps,
        });
      } else {
        onDislikeClick?.();
      }
    },
    [onOpenModal, typedData, onDislikeClick]
  );

  const onDeleteSuccess = useCallback(() => {
    onSetData(
      API_CONFIG.get_bot_concepts,
      { id: parseInt(typedData?.id || "") },
      [typedData?.currentBotId || "", typedData?.currentBotSourceId || ""],
      {
        action: "DELETE",
        key: "CNP_ID",
      }
    );

    openNotification(
      <SuccessNotificationMessage
        message={
          typedData?.type === "concept"
            ? "Concept deleted successfully"
            : "Feedback removed successfully"
        }
        showSuccess
      />
    );

    setDeleteFeedbackIsLoading(false);
  }, [typedData?.id, onSetData]);

  const {
    onExecuteRequest: onExecuteDeleteConceptRequest,
    isLoading: deleteConceptsIsLoading,
  } = useRequestWithMethod(
    "delete_bot_concepts",
    [typedData?.analysis_id, typedData?.source_id],
    true,
    onDeleteSuccess
  );

  const onDeleteClick = useCallback(() => {
    if (typedData?.type === "concept") {
      onExecuteDeleteConceptRequest({
        src_id: parseInt(typedData?.source_id || ""),
        da_id: parseInt(typedData?.analysis_id || ""),
        concept_json: [
          {
            cnp_id: typedData?.id,
          },
        ],
      });
    } else {
      setDeleteFeedbackIsLoading(true);
      typedData?.handlers?.onRemoveFeedback(
        [typedData?.id],
        onDeleteSuccess,
        true
      );
    }
  }, [onOpenModal, typedData, onDeleteSuccess]);

  const isAnyDeleteLoading = deleteConceptsIsLoading || deletefeedbackIsLoading;

  return (
    <ActionRendrerStyled>
      <Flex>
        <LinkButton
          disabled={isAnyRowSelected || deleteConceptsIsLoading}
          marginRight="4px"
          onClick={onEditClick}
          tooltipProps={{ title: isConcept ? "Edit Concept" : "Edit Feedback" }}
          className="edit-btn"
        >
          {editIcon}
        </LinkButton>
        {isAnyDeleteLoading ? (
          <Spin size="small" className="delete-cnp-loading" />
        ) : (
          <ConfirmationPopover
            heading={isConcept ? "Delete Concept" : "Delete Feedback"}
            desc="This action cannot be reversed. Are you sure?"
            placement="topLeft"
            arrowPointAtCenter
            okText="Delete"
            cancelText="Cancel"
            onOk={onDeleteClick}
            visible={isConfirmationVisible}
            propOnVisibleChange={(visible): void => {
              setIsConfirmationVisible(visible);
              setDeleteTooltipVisible(false);
            }}
          >
            <LinkButton
              disabled={isAnyRowSelected}
              onClick={(e): void => e?.stopPropagation()}
              tooltipProps={{
                title: isConcept ? "Delete Concept" : "Delete Feedback",
                visible: deleteTooltipVisible,
                onVisibleChange: (visible): void =>
                  setDeleteTooltipVisible(visible),
              }}
              className="delete-btn"
            >
              <HighlightedCircleBackround isFocused={isConfirmationVisible}>
                {deleteIcon}
              </HighlightedCircleBackround>
            </LinkButton>
          </ConfirmationPopover>
        )}
      </Flex>
    </ActionRendrerStyled>
  );
};
