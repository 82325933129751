import { useHistory } from "react-router";
import { useCallback, useState } from "react";

import { JOB_SECTION_TITLES } from "../../constants/labelconstants/jobconfig";
import {
  getRuleDetailPageUrl,
  getTableDetailPageUrl,
  utcTOLocalTimeZone,
} from "../../utils";

import { ruleDetailPageTabsValues } from "../../pages/ruledetailpage/ruledetailpage.views/ruledetailpagecontent/ruledetailpagecontent.types";
import LinkButton from "../../components/linkbutton/linkbutton";

import {
  DescriptionRendererStyled,
  InternalRecipientsRendererStyled,
  MultipleRulesRendererStyled,
  RuleNamesWrapperStyled,
} from "./jobrefdrawer.styles";

import { useOpenDrawer } from "../../customhooks";
import { DATE_TIME_COMBINE_FORMAT } from "../../constants";

import { JobItemType } from "./jobrefdrawer.types";
import ShowMore from "../../components/showmore/showmore";
import { userGroupsIcon, userIcon } from "../../svgs";
import {
  tableDetailPageTabs,
  tableDetailPageTabsValues,
} from "../../pages/tablepage/tablepage.constants";
import StyledLink from "../../components/styledlink/styledlink";

const {
  RULES,
  TABLES,
  LAST_EXECUTION_ON,
  NEXT_EXECUTION_ON,
  INTERNAL_RECIPIENTS,
  EXTERNAL_RECIPIENTS,
  DESCRIPTION,
  ATTRIBUTES,
} = JOB_SECTION_TITLES;

const MultipleNodesRenderer = (item: JobItemType): JSX.Element => {
  const history = useHistory();
  const openDrawer = useOpenDrawer();

  const [countLimit, setCountLimit] = useState(10);

  const { isFromDetailPage, nodeType } = item || {};

  const onOpenDrawerRef = useCallback(
    (nodeId) => {
      openDrawer({
        drawerId: nodeType === "TBL" ? "table_ref" : "rule_ref",
        visible: true,
        drawerProps: {
          id: nodeId,
        },
      });
    },
    [nodeType]
  );

  const getNodeDetailPageUrl = useCallback((nodeId) => {
    const url =
      nodeType === "TBL"
        ? getTableDetailPageUrl(tableDetailPageTabsValues?.overview)
        : getRuleDetailPageUrl(nodeId, ruleDetailPageTabsValues.data);

    return url;
  }, []);

  const dataLength = item?.ids?.length || 0;

  const showAllBtnHandler = useCallback((): void => {
    dataLength <= countLimit ? setCountLimit(10) : setCountLimit(dataLength);
  }, [dataLength, countLimit]);

  return (
    <MultipleRulesRendererStyled>
      {item?.ids?.slice(0, countLimit)?.map((field, index) => {
        const lengthOfItemIds = item?.ids?.length || 0;
        const isLast = lengthOfItemIds - 1 === index;

        const { id = "", name = "" } = field || {};

        return (
          <RuleNamesWrapperStyled key={`field-${field}`}>
            <StyledLink
              to={isFromDetailPage ? "" : getNodeDetailPageUrl(id)}
              {...(isFromDetailPage && {
                propsOnClick: (): void => onOpenDrawerRef(id),
              })}
            >
              {name}
              {!isLast ? ", " : ""}
            </StyledLink>
          </RuleNamesWrapperStyled>
        );
      })}

      {dataLength > 10 && (
        <LinkButton type="link" onClick={showAllBtnHandler} marginLeft="5px">
          {dataLength <= countLimit
            ? "Show Less"
            : `(+${dataLength - countLimit} more)`}
        </LinkButton>
      )}
    </MultipleRulesRendererStyled>
  );
};

const ExexutionDateRenderer = (item: JobItemType): JSX.Element => {
  const { value = "", ids = [] } = item || {};

  return (
    <>{value ? utcTOLocalTimeZone(value, DATE_TIME_COMBINE_FORMAT) : "-"}</>
  );
};

const InternalRecipientsRenderer = (item: JobItemType): JSX.Element => {
  const { value = "", ids = [] } = item || {};

  return (
    <InternalRecipientsRendererStyled>
      {ids?.map((node) => {
        return (
          <div className="value" key={node?.name} title={node?.name}>
            {node?.type === "USR" ? userIcon : userGroupsIcon()}
            {node?.name || "-"}
          </div>
        );
      })}
    </InternalRecipientsRendererStyled>
  );
};

const RecipientsRenderer = (item: JobItemType): JSX.Element => {
  const { value = "" } = item || {};
  return <ShowMore data={value?.split(", ") ?? []} limit={8} />;
};

const AttributesRenderer = (item: JobItemType): JSX.Element => {
  const { stringValues } = item || {};

  const parsedAttrValues = stringValues?.map((item) => {
    const splittedTagName = item?.replace(".", " > ");
    return splittedTagName;
  });

  return <ShowMore data={parsedAttrValues ?? []} limit={8} />;
};

const DescriptionRenderer = (item: JobItemType): JSX.Element => {
  const { value = "" } = item || {};

  return (
    <DescriptionRendererStyled title={value}>{value}</DescriptionRendererStyled>
  );
};

export const jobRefDrawer: {
  [key: string]: (_item: JobItemType) => JSX.Element;
} = {
  [RULES]: (item) => <MultipleNodesRenderer {...item} />,
  [TABLES]: (item) => <MultipleNodesRenderer {...item} />,
  [LAST_EXECUTION_ON]: (item) => <ExexutionDateRenderer {...item} />,
  [NEXT_EXECUTION_ON]: (item) => <ExexutionDateRenderer {...item} />,
  [INTERNAL_RECIPIENTS]: (item) => <InternalRecipientsRenderer {...item} />,
  [ATTRIBUTES]: (item) => <AttributesRenderer {...item} />,
  [EXTERNAL_RECIPIENTS]: (item) => <RecipientsRenderer {...item} />,
  [DESCRIPTION]: (item) => <DescriptionRenderer {...item} />,
};
