import { useParams } from "react-router";
import { useMemo } from "react";
// <--- Library Imports End --->

// <--- Styles Start --->
import {
  AnalysisDetailPageHeaderRightSecStyled,
  MenuItemswrapper,
} from "./analysisdetailpageheaderrightsec.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { duplicateIcon, greyDeleteIcon } from "../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { AnalysisDetailPageHeaderRightSecProps } from "./analysisdetailpageheaderrightsec.types";
import ConfirmationMessage from "../../../../../../components/confirmationmessage/confirmationmessage";
import LinkButton from "../../../../../../components/linkbutton/linkbutton";
// <--- Types End --->

const AnalysisDetailPageHeaderRightSec = (
  props: AnalysisDetailPageHeaderRightSecProps
): JSX.Element => {
  const {
    handlers,
    toggleDeleteConfirmation,
    isShowDeleteConfirmation,
    isEditMode = false,
    isAuthor = false,
    hasUserAccessToEdit = false,
    analysisSourceId = "",
    analysisNodeSubType,
  } = props;

  const { id: paramsAnalysisNodeId = "" } = useParams<{ id: string }>();

  const { onDuplicateClick, onDeleteAnalysis } = handlers || {};

  const actionsConfig = useMemo(
    () => [
      ...((!isEditMode &&
        paramsAnalysisNodeId &&
        hasUserAccessToEdit && [
          {
            name: "Duplicate",
            icon: duplicateIcon("12", "12"),
            onClick: onDuplicateClick,
          },
        ]) ||
        []),

      ...((!isEditMode &&
        paramsAnalysisNodeId &&
        isAuthor && [
          {
            name: "Delete",
            icon: greyDeleteIcon("11.76", "12.48"),
            onClick: toggleDeleteConfirmation,
            // disabled: !analysisSourceId,
          },
        ]) ||
        []),
    ],
    [
      onDuplicateClick,
      toggleDeleteConfirmation,
      isAuthor,
      paramsAnalysisNodeId,
      isEditMode,
      hasUserAccessToEdit,
      analysisSourceId,
    ]
  );

  const isTool = analysisNodeSubType === "TOL";

  return (
    <AnalysisDetailPageHeaderRightSecStyled data-testid="analysis-detail-page-header-right-sec-main-wrapper">
      <div
        className="btns-wrapper"
        data-testid="analysis-detail-page-header-right-sec-action-btns-wrapper"
      >
        {isShowDeleteConfirmation ? (
          <div
            className="delete-confirmation-wrapper"
            data-testid="analysis-detail-page-header-delete-confirmation-wrapper"
          >
            <ConfirmationMessage
              message={
                isTool
                  ? "This will delete the tool and all its configuration data, are you sure you want to proceed?"
                  : "This will delete the agent and all its training data, are you sure you want to proceed?"
              }
              onCancel={toggleDeleteConfirmation}
              onOk={onDeleteAnalysis}
            />
          </div>
        ) : (
          <MenuItemswrapper>
            {actionsConfig.map(
              (
                menu: {
                  name: string;
                  icon: JSX.Element;
                  onClick: () => void;
                  customClass?: string;
                  disabled?: boolean;
                },
                index: number
              ): JSX.Element => {
                const {
                  name,
                  icon,
                  onClick,
                  customClass,
                  disabled = false,
                } = menu;
                return (
                  <LinkButton
                    className={customClass || "item"}
                    key={index}
                    onClick={onClick}
                    disabled={disabled}
                  >
                    {icon && icon}
                    <p className="menu-item-name">{name}</p>
                  </LinkButton>
                );
              }
            )}
          </MenuItemswrapper>
        )}
      </div>
    </AnalysisDetailPageHeaderRightSecStyled>
  );
};

export default AnalysisDetailPageHeaderRightSec;
