import Flex from "../flex";
import { SectionBlankSlateTextStyled } from "./sectionblankslatetext.styles";
import { SectionBlankSlateTextType } from "./sectionblankslatetext.types";

function SectionBlankSlateText(props: SectionBlankSlateTextType): JSX.Element {
  const {
    text = "No data to show",
    children,
    isEmpty,
    containMinHeight = true,
    className = "",
    icon,
  } = props;

  return (
    <SectionBlankSlateTextStyled
      {...props}
      containMinHeight={containMinHeight}
      className={className}
    >
      {isEmpty ? (
        <div className="sec-blank-slate" data-testid="section-blank-slate-text">
          {icon ? (
            <Flex direction="column" rowGap={10}>
              {icon}
              {text}
            </Flex>
          ) : (
            text
          )}
        </div>
      ) : (
        children
      )}
    </SectionBlankSlateTextStyled>
  );
}

export default SectionBlankSlateText;
