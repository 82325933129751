// <--- Library Imports Start --->
import styled from "styled-components";
import { hexToRgbA } from "../../../../utils";
import LinkButton from "../../../../components/linkbutton/linkbutton";
// <--- Library Imports End --->

export const AnalysisDetailPageContentSecStyled = styled.div<{
  isChatPage?: boolean;
  isQueryPresent?: boolean;
  isOnboardingMode?: boolean;
}>`
  padding: ${({ isChatPage }): string => (isChatPage ? "0" : "0 20px 14.4px")};

  .content-sec {
    column-gap: 25.6px;

    .explorer-query-sec {
      border: ${({ theme, isChatPage }): string =>
        isChatPage ? "" : ` 1px solid ${theme?.borderColors?.GREY_56}`};

      .ant-col-1 {
        flex: 0 0 3.166667%;
      }
    }

    .query-block-listing-section-blank-slate-text {
      width: 100%;
      height: 100%;
      background: ${({ theme, isChatPage }): string =>
        isChatPage
          ? theme.textColors.WHITE_COLOR
          : theme?.bgColors?.LIGHT_GREY_22};
      border-top: ${({ theme, isChatPage, isQueryPresent }): string =>
        isChatPage && !isQueryPresent
          ? ` 1px solid ${theme?.borderColors?.GREY_56}`
          : ""};

      .sec-blank-slate {
        height: 100%;
      }
    }

    .query-block-listing-col {
      background: ${({ theme, isChatPage }): string =>
        isChatPage
          ? theme.textColors.WHITE_COLOR
          : theme?.bgColors?.LIGHT_GREY_22};
      padding: ${({ isChatPage }): string =>
        isChatPage ? "0" : "19.2px 19.2px 10.4px 19.2px"};

      width: 100%;
      height: 100%;

      border-top: ${({ theme, isChatPage, isQueryPresent }): string =>
        isChatPage && !isQueryPresent
          ? ` 1px solid ${theme?.borderColors?.GREY_56}`
          : ""};
    }

    .bottom-sec {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 25.6px 0 33.6px;
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 70%,
        rgba(255, 255, 255, 0.6) 100%
      );
      z-index: ${({ isOnboardingMode }): number =>
        isOnboardingMode ? 10008 : 1};
      box-shadow: 0 0 12px 0 rgb(0 0 0 / 15%);

      .bottom-sec-wrapper {
        width: 950px;

        .bottom-sec-actions-sec {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 9.6px;

          .left-action-buttons {
            display: flex;
            align-items: center;
            column-gap: 3.2px;
            margin-bottom: 3px;
          }

          .right-action-buttons {
            display: flex;
            align-items: center;

            .guide-me-action-btn {
              span {
                display: flex;
              }
            }

            .tool-sec-heading {
              font-size: 12px;
              color: ${({ theme }): string => theme?.textColors?.GREY_24};
              margin-right: 4px;
            }

            .tools-sec-container {
              border: solid 1px
                ${({ theme }): string => theme?.borderColors?.GREY_56};
              border-radius: 4px;
            }
          }

          .summarize-btn {
            font-size: 12px;
            padding: 4px 8px;
            color: ${({ theme }): string => theme?.textColors?.BLUE_116};
          }
        }

        .form-style {
          width: 100%;

          .ant-input {
            border-radius: 6px;
            box-shadow: 0 4px 12px 0
              ${({ theme }): string =>
                hexToRgbA(theme.bgColors.BLACK_COLOR, 0.15)};
            border: solid 1px ${({ theme }): string => theme?.bgColors?.GREY_56};
          }

          .send-msg-btn {
            right: 20px;

            svg {
              width: 23.2px;
              height: 19.84px;
            }
          }
        }
      }
    }
  }
`;

export const CollapseButtonStyled = styled.div<{
  isActive: boolean;
  showCursor?: boolean;
}>`
  padding: 4px 8px;
  display: flex;
  align-items: center;
  column-gap: 5.04px;
  cursor: ${({ showCursor }): string => (showCursor ? "pointer" : "default")};
  font-size: 12px;
  color: ${({ theme }): string => theme?.textColors?.GREY_24};
  background: ${({ theme, isActive }): string =>
    isActive && theme?.textColors?.GREY_56};

  .highlighted-text {
    font-family: OpenSansSemiBold;
  }
`;

export const SavedContextsButtonStyled = styled.div<{ isActive: boolean }>`
  padding: 4px 8px;
  cursor: pointer;
  font-size: 12px;
  color: ${({ theme }): string => theme?.textColors?.GREY_24};
  background: ${({ theme, isActive }): string =>
    isActive && theme?.bgColors?.LIGHT_BLUE_25};
`;

export const TrainbotChatMenuButtonStyled = styled.div`
  width: 65px;
  height: 59px;
  border-radius: 6px;
  border: solid 1px ${({ theme }): string => theme?.borderColors?.BLUE_116};
  color: ${({ theme }): string => theme?.textColors?.BLUE_116};
  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-family: OpenSansSemiBold;
  margin-right: 12px;
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme }): string => theme?.bgColors?.BLUE_116};
    }
  }
`;

export const TrainbotChatMenuStyled = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 14px;
  border: solid 1px #e8e8e8;
  background-color: #fff;
  cursor: pointer;

  .share-cpt-svg {
    display: inline-flex;
    svg {
      margin-right: 6px;
    }
  }

  :hover {
    background-color: ${(props): string => props.theme.bgColors.BLUE_116};
    color: ${(props): string => props.theme.textColors.WHITE_COLOR};
    svg {
      path {
        fill: ${(props): string => props.theme.textColors.WHITE_COLOR};
      }
    }
  }
`;

export const SelectedToolButtonStyled = styled(LinkButton)<{
  isSelected: boolean;
}>`
  width: 32px;
  height: 32px;
  border-right: solid 1px ${({ theme }): string => theme?.borderColors?.GREY_56};
  background-color: ${({ theme, isSelected }): string =>
    isSelected ? theme?.bgColors?.LIGHT_BLUE_27 : theme?.bgColors?.WHITE_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;

  :hover,
  :focus,
  :active {
    background-color: ${({ theme, isSelected }): string =>
      isSelected
        ? theme?.bgColors?.LIGHT_BLUE_25
        : theme?.bgColors?.WHITE_COLOR};
  }

  svg {
    path {
      fill: ${({ theme, isSelected }): string =>
        isSelected ? theme?.bgColors?.BLUE_116 : theme?.bgColors?.GREY_18};
    }
  }
`;

export const AnalysisMessageFieldDisabledInfo = styled.div``;
