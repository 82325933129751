import * as yup from "yup";
import { S3ConfigurationModalType } from "../../components/modalwrapper/modals/s3configurationmodal/s3configurationmodal.types";
import { REQUIRED_MESSAGE } from "../../constants/formconstants";

export const s3configurationformschema = (
  type: S3ConfigurationModalType
): any => {
  switch (type) {
    case "s3_source_bucket":
    case "s3_staging_bucket":
      return yup.object().shape({
        bucket_name: yup.string().required(REQUIRED_MESSAGE),
      });
    case "glue_database":
      return yup.object().shape({
        name: yup.string().required(REQUIRED_MESSAGE),
        description: yup.string().required(REQUIRED_MESSAGE),
      });
    case "glue_crawler":
      return yup.object().shape({
        name: yup.string().required(REQUIRED_MESSAGE),
        description: yup.string().required(REQUIRED_MESSAGE),
        s3_path: yup.string().required(REQUIRED_MESSAGE),
        exclude_pattern: yup.string(),
        role: yup.string().required(REQUIRED_MESSAGE),
        database_name: yup.string().required(REQUIRED_MESSAGE),
      });
    default:
      return yup.object().shape({});
  }
};
