import styled from "styled-components";

export const AgentExplorerSecEmptystateStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 59.2px 0;

  .text {
    margin-top: 12px;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    text-align: center;
  }

  .text,
  .ant-btn-link {
    font-size: 13px;
  }
`;
