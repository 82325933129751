import styled from "styled-components";
import { ellipsis } from "../../../../../../../../styles/app.styles";
import { hexToRgbA } from "../../../../../../../../utils";

const flexStyle = `
  display: flex;
  align-items: center;
`;

export const AgentExplorerSecStyled = styled.div`
  .ant-collapse {
    .ant-collapse-item-active {
      .ant-collapse-header {
        .ant-collapse-arrow {
          svg {
            path {
              fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
            }
          }
        }
      }
    }

    .ant-collapse-item {
      background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};

      .ant-collapse-header {
        ${flexStyle}

        .collapse-header-content {
          width: 100%;
          ${flexStyle}
          column-gap: 8px;
          font-size: 13.6px;
          font-family: OpenSansSemiBold;

          svg {
            path {
              fill: ${({ theme }): string => theme?.textColors?.GREY_210};
            }
          }

          .collapse-header-content-title {
            ${flexStyle}
            column-gap: 8px;
            font-size: 13.6px;
            font-family: OpenSansSemiBold;
            flex: 1;

            .count {
              margin-top: 3px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: ${({ theme }): string =>
                theme?.bgColors?.GREY_56};
              height: 18px;
              width: 18px;
              border-radius: 50px;
              font-size: 10px;
            }
            svg {
              path {
                fill: ${({ theme }): string => theme?.textColors?.GREY_210};
              }
            }
          }
        }
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          padding-top: 0;
          padding-left: 22.4px;

          .view-icon {
            svg {
              path {
                stroke: ${({ theme }): string => theme?.borderColors?.BLUE_116};
              }
            }
          }

          .plus-icon,
          .minus-icon {
            svg {
              path,
              rect {
                fill: ${({ theme }): string => theme?.borderColors?.BLUE_116};
              }
            }
          }

          .minus-icon {
            margin-top: 4px;
          }

          .explorer-collapse-item-content-container {
            padding: 8px 0px 8px 19.2px;
            border-left: 1px solid
              ${({ theme }): string => theme?.bgColors?.BLUE_116};

            .pinned-objects-blankstate {
              font-size: 12px;
              color: ${({ theme }): string => theme?.bgColors?.GREY_24};
              font-style: italic;
            }

            .table-browser-search {
              border-radius: 3.2px;
              margin-bottom: 10px;

              .ant-input-prefix {
                svg {
                  path {
                    fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
                  }
                }
              }

              .ant-input {
                font-size: 12px;
              }

              .ant-input-suffix {
                .ant-btn-link {
                  svg {
                    fill: ${({ theme }): string => theme?.textColors?.GREY_18};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ExplorerSecListItemStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 9.6px;

  .title-sec {
    ${flexStyle}
    column-gap: 8px;
    max-width: 80%;

    .title {
      ${ellipsis}
      width: 100%;
      font-size: 14.4px;
    }

    /* It will be applicable for tables as their ref open on this click */
    /* cursor: pointer; */
    /* color: ${({ theme }): string => theme?.textColors?.BLUE_116}; */
  }

  .success-span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-right: 10px;

    .text {
      color: green;
    }
  }

  .action-btns {
    ${flexStyle}
    column-gap: 10px;

    .action-btn {
      display: flex;

      svg {
        display: none;
      }
    }
  }

  &:hover {
    background: ${({ theme }): string =>
      hexToRgbA(theme?.borderColors?.BLUE_20, 0.5)};

    .action-btns {
      svg {
        display: block;
      }
    }
  }
`;
