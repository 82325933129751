import { Link } from "react-router-dom";
import { Tooltip } from "antd";

import { useFormContext } from "react-hook-form";

import { CheckboxWrapperStyled } from "../form/form.styles";
import { CheckboxField } from "../formfields";

import {
  CheckboxChildrenWrapper,
  CheckboxWithLabelAndDescStyled,
} from "./checkboxwithlabelanddesc.styles";

import { CheckboxWithLabelAndDescType } from "./checkboxwithlabelanddesc.types";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { infoIcon } from "../../svgs";
import { AlertStyled } from "../alert/alert.styles";
import { getChatPrefrencesData } from "../../utils";

const CheckboxWithLabelAndDesc = (
  props: CheckboxWithLabelAndDescType
): JSX.Element => {
  const {
    desc = "",
    label = "",
    name,
    disabled = false,
    tooltipProps,
    linkTitle,
    linkTo,
    noteTitle = false,
    paddingLeft = "",
    children,
    onChange,
  } = props;

  const { control } = useFormContext();

  const {
    is_descriptive_mode_enabled: isDescriptiveModeEnabled,
  } = getChatPrefrencesData();

  return (
    <CheckboxWithLabelAndDescStyled
      paddingLeft={paddingLeft}
      className="checkbox-with-label-and-desc"
    >
      <Tooltip
        {...tooltipProps}
        placement="bottomLeft"
        overlayClassName="form-checkbox-tooltip"
        getPopupContainer={(trigger): HTMLElement =>
          trigger.parentNode as HTMLElement
        }
        className={DVSUM_TOOLTIP_CLASS_NAME}
        overlay={undefined}
      >
        <CheckboxWrapperStyled paddingLeft="30px" marginBottom="0">
          <CheckboxField
            control={control}
            name={name}
            mode="primary"
            disabled={disabled}
            onChange={onChange}
          >
            <span className="label"> {label}</span>
          </CheckboxField>
        </CheckboxWrapperStyled>
      </Tooltip>
      <CheckboxChildrenWrapper>
        {desc && isDescriptiveModeEnabled && (
          <p className="desc">
            {desc}{" "}
            {linkTo && (
              <span className="link">
                <Link target="_blank" to={linkTo}>
                  {linkTitle}
                </Link>
              </span>
            )}
          </p>
        )}

        <div className="children-components">
          {noteTitle && (
            <div className="alert">
              <AlertStyled
                height="32px"
                message={noteTitle}
                type="info"
                icon={infoIcon}
                showIcon
              />
            </div>
          )}
          {children && children}
        </div>
      </CheckboxChildrenWrapper>
    </CheckboxWithLabelAndDescStyled>
  );
};

export default CheckboxWithLabelAndDesc;
