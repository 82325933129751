//   <--Library Imports Start-->
import { useHistory } from "react-router";
import { useCallback, useMemo } from "react";

import { AxiosResponse } from "axios";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//   <--Library Imports End-->

//   <--Components Start-->
import { Button } from "../../../button/button.component";
import { FormItemStyled, FormStyled } from "../../../form";

import { InputField, TextAreaField } from "../../../formfields";
import LinkButton from "../../../linkbutton";

import SuccessNotificationMessage from "../../../successnotificationmessagerendrer/successnotificationmessagerendrer";
import StateHandler from "../../../statehandler";

import BusinessNameEditInfoMessage from "../../../businessnameeditinfo";
//   <--Components End-->

//   <--Constants Start-->
import { API_CONFIG } from "../../../../constants/apiconfig";
import {
  REQUIRED_MESSAGE,
  TITLE_MAX_CHA_LIMIT,
  TITLE_MAX_RANGE,
} from "../../../../constants/formconstants";
//   <--Constants End-->

//   <--Custom Hooks Start-->
import { useGetAppType, useGetData, useSetData } from "../../../../customhooks";
//   <--Custom Hooks End-->

//   <--Styles Start-->
import { NodeRefQuickEditEditViewStyled } from "./noderefquickediteditview.styles";
//   <--Styles End-->

//   <--Services Start-->
import { useRequestWithMethod } from "../../../../api";
//   <--Services End-->

//   <--Types Start-->
import { TableDocResponse } from "../../../../parsers/tablepage/tablepageparser.types";
import { TermDocResponse } from "../../../../parsers/termsparser/termsparser.types";

import { DataSourceDocResponse } from "../../../../parsers/datasourcesparsers/datasourcesparsers.types";
import { DetailPageHistoryState } from "../../../noderefquickedit/noderefquickedit.types";

import {
  NodeRefEditFormType,
  NodeRefQuickEditEditViewProps,
} from "./noderefquickediteditview.types";

import { ColumnDocResponse } from "../../../../parsers";
//   <--Types End-->

//   <--Utils Start-->
import {
  getDetailpageUrlBasedOnNodeType,
  getFiltersfromLocalStorage,
  getTableDetailPageUrl,
  openNotification,
} from "../../../../utils";

import { tableDetailPageTabsValues } from "../../../../pages/tablepage/tablepage.constants";
import { ELEMENT_IDS } from "../../../../constants";
import StyledLink from "../../../styledlink/styledlink";
//   <--Utils End-->

const schema = yup.object().shape({
  title: yup.string(),
});

const schemaForTerm = yup.object().shape({
  title: yup.string(),
  name: yup
    .string()
    .required(REQUIRED_MESSAGE)
    ?.max(TITLE_MAX_CHA_LIMIT, TITLE_MAX_RANGE),
});

const {
  datdict_detpg_info_ref_buss_name_field: DATDICT_DETPG_INFO_REF_BUSS_NAME_FIELD,
  datdict_detpg_info_ref_save_btn: DATDICT_DETPG_INFO_REF_SAVE_BTN,
} = ELEMENT_IDS;

const NodeRefQuickEditEditView = (
  props: NodeRefQuickEditEditViewProps
): JSX.Element => {
  const {
    nodeDesc = "",
    nodeTitle = "",
    onCancel,
    nodeId = "",
    nodeType = "TBL",
    nodeSubType,
    parentId = "",
    onSuccessUpdateIsEdited,
    isUpdatingInheritedDesc,
    nodeName = "",
    isWfEnable = false,
  } = props;

  const isColEdit = nodeType === "COL";
  const isTermEdit = nodeType === "TRM";

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { isValid },
  } = useForm<NodeRefEditFormType>({
    defaultValues: {
      title: nodeTitle,
      desc: nodeDesc,
      name: nodeName,
    },
    resolver: yupResolver(isTermEdit ? schemaForTerm : schema),
    mode: "onChange",
  });

  const { desc: watchDesc } = watch();

  const hasUserChangedInheritedDesc = useMemo(() => {
    return isUpdatingInheritedDesc
      ? !!(nodeType === "COL" && watchDesc !== nodeDesc)
      : true;
  }, [watchDesc, isUpdatingInheritedDesc, nodeType, nodeDesc]);

  const onSetData = useSetData();
  const onGetData = useGetData();

  const { isExtOrDesktop } = useGetAppType();

  const history = useHistory();

  const onSuccess = useCallback(
    (res) => {
      const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);
      if (isColEdit) {
        const { desc, title } = getValues();
        const updatedTitle = title || nodeName;

        const colRefData = onGetData(API_CONFIG.get_node, [
          nodeId,
          nodeType,
          "PUB",
          "true",
          "",
          parsedNodeFilter,
        ]) as AxiosResponse<ColumnDocResponse>;

        const colRefFormattedData: ColumnDocResponse = {
          ...colRefData?.data,
          display_name: updatedTitle,
          title: {
            value: updatedTitle,
            is_changed: true,
          },
          description: {
            ...colRefData?.data?.description,
            description: {
              is_changed: true,
              value: desc,
              is_inherited: !hasUserChangedInheritedDesc,
            },
          },
        };

        onSetData(API_CONFIG.get_node, colRefFormattedData, [
          nodeId,
          nodeType,
          "PUB",
          "true",
          "",
          parsedNodeFilter,
        ]);

        const tableColumnsDetails = onGetData(API_CONFIG.get_table_columns, [
          parentId,
        ]);

        if (tableColumnsDetails) {
          onSetData(API_CONFIG.get_table_columns, res?.data, [parentId]);
        }
      } else {
        const detailPageData = onGetData(API_CONFIG.get_node, [
          nodeId,
          nodeType,
          "PUB",
          "",
          "",
          parsedNodeFilter,
        ]);

        if (detailPageData) {
          onSetData(API_CONFIG.get_node, res?.data, [
            nodeId,
            nodeType,
            "PUB",
            "",
            "",
            parsedNodeFilter,
          ]);
        }

        onSetData(API_CONFIG.get_node, res?.data, [
          nodeId,
          nodeType,
          "PUB",
          "true",
          "",
          parsedNodeFilter,
        ]);
      }

      openNotification(
        <SuccessNotificationMessage message="Your changes have been saved" />,
        0,
        {
          top: isExtOrDesktop ? 0 : 60,
          className: "desktop-app-center-notification",
          getContainer: (): any => {
            const elements = document.getElementsByClassName(
              "ant-drawer-content"
            );
            return (elements?.length && elements[0]) || document.body;
          },
        }
      );
      onSuccessUpdateIsEdited();
      onCancel();
    },
    [props, hasUserChangedInheritedDesc]
  );

  const { onExecuteRequest, isLoading, error } = useRequestWithMethod(
    "update_node",
    [
      nodeId,
      nodeType,
      isWfEnable ? "DFT" : "PUB",
      "true",
      "",
      getFiltersfromLocalStorage(nodeType),
    ],
    true,
    onSuccess
  );

  const {
    isLoading: updateColIsLoading,
    onExecuteRequest: updateColumn,
    error: updateColumnError,
  } = useRequestWithMethod(
    "edit_column_config",
    [parentId, "true"],
    false,
    onSuccess
  );

  const onSubmit = useCallback(
    (values: NodeRefEditFormType) => {
      const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);
      const updatedtitle = values?.title || nodeName;

      const refViewData = onGetData(API_CONFIG.get_node, [
        nodeId,
        nodeType,
        "PUB",
        "true",
        "",
        parsedNodeFilter,
      ]);

      if (nodeType === "COL") {
        updateColumn([
          {
            COL_ID: nodeId,
            COL_TITLE: updatedtitle,
            COL_USER_DESC: hasUserChangedInheritedDesc ? values?.desc : "",
          },
        ]);
      } else if (nodeType === "TBL") {
        const tblData = refViewData as AxiosResponse<TableDocResponse>;
        const formattedTableData: TableDocResponse = {
          ...tblData?.data,
          title: { value: updatedtitle, is_changed: true },
          description: {
            ...tblData?.data?.description,
            description: {
              ...tblData?.data?.description?.description,
              is_changed: true,
              value: values?.desc,
            },
          },
        };

        onExecuteRequest(formattedTableData);
      } else if (nodeType === "TRM") {
        const trmData = refViewData as AxiosResponse<TermDocResponse>;
        const formattedTermData: TermDocResponse = {
          ...trmData?.data,
          title: { value: updatedtitle, is_changed: true },
          description: {
            ...trmData?.data?.description,
            term_name: {
              ...trmData?.data?.description?.term_name,
              value: values?.name,
              is_changed: true,
            },
            definition: {
              ...trmData?.data?.description?.definition,
              is_changed: true,
              value: values?.desc,
            },
          },
        };

        onExecuteRequest(formattedTermData);
      } else if (nodeType === "DSR") {
        const dsrData = refViewData as AxiosResponse<DataSourceDocResponse>;
        const formattedDsrData: DataSourceDocResponse = {
          ...dsrData?.data,
          title: { value: updatedtitle, is_changed: true },
          description: {
            ...dsrData?.data?.description,
            description: {
              ...dsrData?.data?.description?.description,
              is_changed: true,
              value: values?.desc,
            },
          },
        };

        onExecuteRequest(formattedDsrData);
      }
    },
    [props, hasUserChangedInheritedDesc]
  );

  const onClickEditAll = useCallback(() => {
    const url =
      nodeType === "TBL"
        ? getTableDetailPageUrl(nodeId, tableDetailPageTabsValues.overview)
        : getDetailpageUrlBasedOnNodeType(nodeId, nodeType, nodeSubType);

    history.push({
      pathname: url,
      state: { isEdit: true } as DetailPageHistoryState,
    });
  }, [props]);

  const getEditAllUrl = useCallback(() => {
    const url =
      nodeType === "TBL"
        ? getTableDetailPageUrl(nodeId, tableDetailPageTabsValues.overview)
        : getDetailpageUrlBasedOnNodeType(nodeId, nodeType, nodeSubType);

    return url;
  }, [props]);

  return (
    <StateHandler
      isFetching={isLoading || updateColIsLoading}
      error={error || updateColumnError}
      isModal
    >
      <NodeRefQuickEditEditViewStyled>
        <div className="content-sec">
          <FormStyled
            isItemColumnLayout
            onFinish={handleSubmit(onSubmit) as any}
          >
            {isTermEdit && (
              <FormItemStyled label="Short Name" required>
                <InputField
                  control={control}
                  name="name"
                  width="514px"
                  isMessagePositionAbsolute
                />
              </FormItemStyled>
            )}

            <FormItemStyled label={isTermEdit ? "Long Name" : "Business Name"}>
              <InputField
                control={control}
                name="title"
                width="514px"
                isMessagePositionAbsolute
                id={DATDICT_DETPG_INFO_REF_BUSS_NAME_FIELD}
              />
              <BusinessNameEditInfoMessage />
            </FormItemStyled>

            <FormItemStyled label="Definition">
              <TextAreaField control={control} name="desc" width="514px" />
            </FormItemStyled>
            <FormItemStyled>
              <div className="edit-ref-form-footer">
                <div className="left">
                  {!isColEdit && (
                    <StyledLink
                      to={getEditAllUrl()}
                      stateData={{ isEdit: true }}
                      searchText="isEdit=true"
                    >
                      Edit All
                    </StyledLink>
                  )}
                </div>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                  marginLeft="12px"
                  htmlType="submit"
                  disabled={!isValid}
                  elementId={DATDICT_DETPG_INFO_REF_SAVE_BTN}
                >
                  {isWfEnable ? "Submit For Approval" : "Save Changes"}
                </Button>
              </div>
            </FormItemStyled>
          </FormStyled>
        </div>
      </NodeRefQuickEditEditViewStyled>
    </StateHandler>
  );
};

export default NodeRefQuickEditEditView;
