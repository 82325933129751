import LinkButton from "../../../linkbutton";
import {
  S3ConfigurationForm,
  S3ConfigurationModalConfigType,
  S3ConfigurationModalType,
} from "./s3configurationmodal.types";

export const getS3ResourceType = (
  type: S3ConfigurationModalType
): { get: string; save: string } => {
  switch (type) {
    case "glue_database":
      return { get: "gluedblist", save: "gluedb" };
    case "glue_crawler":
      return { get: "crawlerlist", save: "crawler" };
    default:
      return { get: "s3list", save: "s3bucket" };
  }
};

export const getS3ConfigurationModalConfig = (
  type: S3ConfigurationModalType
): S3ConfigurationModalConfigType => {
  switch (type) {
    case "s3_source_bucket":
    case "s3_staging_bucket":
      return [
        {
          fieldType: "input",
          fieldName: "bucket_name",
          label: "Bucket Name",
          placeholder: "Bucket Name",
          isRequired: true,
        },
      ];
    case "glue_database":
      return [
        {
          fieldType: "input",
          fieldName: "name",
          label: "Database Name",
          placeholder: "Database Name",
          isRequired: true,
        },
        {
          fieldType: "textarea",
          fieldName: "description",
          label: "Description",
          placeholder: "Description",
          isRequired: true,
        },
      ];
    case "glue_crawler":
      return [
        {
          fieldType: "input",
          fieldName: "name",
          label: "Crawler Name",
          placeholder: "Crawler Name",
          isRequired: true,
        },
        {
          fieldType: "textarea",
          fieldName: "description",
          label: "Description",
          placeholder: "Description",
          isRequired: true,
        },
        {
          fieldType: "input",
          fieldName: "s3_path",
          label: "Include Path",
          isRequired: true,
          disabled: true,
        },
        {
          fieldType: "input",
          fieldName: "exclude_pattern",
          label: "Exclude Pattern",
          placeholder: "Exclude Pattern",
        },
        {
          fieldType: "input",
          fieldName: "role",
          label: "IAM Role",
          isRequired: true,
          disabled: true,
        },
        {
          fieldType: "input",
          fieldName: "database_name",
          label: "Output (Glue Database)",
          isRequired: true,
          disabled: true,
        },
      ];
    default:
      return [];
  }
};

export const handleErrorMessage = (
  resourceType: S3ConfigurationModalType,
  errorMessage: string
): string | JSX.Element => {
  switch (resourceType) {
    case "s3_source_bucket":
    case "s3_staging_bucket":
      if (
        errorMessage?.startsWith("Parameter validation failed") ||
        errorMessage?.startsWith("An error occurred (InvalidBucketName)")
      ) {
        return (
          <>
            Bucket name does not conform with{" "}
            <LinkButton
              href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/bucketnamingrules.html"
              target="_blank"
              isTextUnderLine
            >
              AWS Standards
            </LinkButton>
            .
          </>
        );
      }
      if (errorMessage?.startsWith("An error occurred (BucketAlreadyExists)")) {
        return "Bucket name already exists.";
      }
      return "Failed to create bucket.";

    case "glue_database":
      if (
        errorMessage?.startsWith("An error occurred (AlreadyExistsException)")
      ) {
        return "Database already exists.";
      }
      return "Failed to create database.";

    case "glue_crawler":
      if (
        errorMessage?.startsWith("An error occurred (AlreadyExistsException)")
      ) {
        return `Crawler already exists.`;
      }
      return "Failed to create crawler.";

    default:
      return "An unexpected error occurred. Please check your AWS permissions and try again.";
  }
};

export const createFieldLog = (
  type: S3ConfigurationModalType,
  formValue: S3ConfigurationForm,
  logType: string
): string => {
  switch (type) {
    case "glue_crawler":
      return `'${formValue?.name}' crawler creation ${logType}`;
    case "glue_database":
      return `'${formValue?.name}' database creation ${logType}`;
    default:
      return `'${formValue?.bucket_name}' bucket creation ${logType}`;
  }
};
