import { useCallback } from "react";
import { LinkProps, useLocation } from "react-router-dom";
import { StyledLinkComponent } from "./styledlink.styles";
import { SyledLinkProps } from "./styledlink.types";

const StyledLink = ({
  to = "",
  target,
  id,
  children,
  disabled,
  cutomClassName = "",
  elementId = "",
  propsOnClick,
  stateData,
  searchText = "",
  type = "link",
}: LinkProps & SyledLinkProps): JSX.Element => {
  const location = useLocation();

  const onClick = useCallback(
    (event) => {
      if (!to) {
        event?.preventDefault();
      }
      event?.stopPropagation();
      propsOnClick?.();
    },
    [propsOnClick, to]
  );

  return (
    <StyledLinkComponent
      className={cutomClassName}
      to={{
        pathname: `${to}`,
        search: searchText,
        state: { from: location, ...stateData },
      }}
      id={id || elementId}
      target={target}
      aria-disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </StyledLinkComponent>
  );
};

export default StyledLink;
