import { Tooltip } from "antd";

//               <--Components-->
import Conditionalwrapper from "../conditionalwrapper";
//               <--Components-->

//               <--Constants-->
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
//               <--Constants-->

//               <--Styles-->
import { LinkButtonStyled } from "./linkbutton.styles";
//               <--Styles-->

//               <--Types-->
import { LinkButtonProps } from "./linkbutton.types";
//               <--Types-->

function LinkButton(props: LinkButtonProps): JSX.Element {
  const {
    tooltipProps,
    showEllipsis,
    elementId = "",
    id,
    hasCommonStyle = true,
    sufixIcon,
  } = props;

  const dvSumTooltipClassName = hasCommonStyle
    ? `${DVSUM_TOOLTIP_CLASS_NAME}`
    : "";

  return (
    <Conditionalwrapper
      condition={!!tooltipProps && !showEllipsis}
      wrapper={(child): JSX.Element => (
        <Tooltip
          {...tooltipProps}
          overlayClassName={`${dvSumTooltipClassName} ${
            tooltipProps?.overlayClassName ?? ""
          }`}
          overlay={undefined}
        >
          {child}
        </Tooltip>
      )}
    >
      <LinkButtonStyled
        {...props}
        type="link"
        id={elementId as any}
        btnType={id}
      >
        {showEllipsis ? (
          <div className="flex-container">
            <Conditionalwrapper
              condition={!!tooltipProps}
              wrapper={(child): JSX.Element => (
                <Tooltip
                  {...tooltipProps}
                  overlayClassName={`${dvSumTooltipClassName} ${
                    tooltipProps?.overlayClassName ?? ""
                  }`}
                  overlay={undefined}
                >
                  {child}
                </Tooltip>
              )}
            >
              <span>{props?.children}</span>
            </Conditionalwrapper>
          </div>
        ) : (
          props?.children
        )}

        {sufixIcon}
      </LinkButtonStyled>
    </Conditionalwrapper>
  );
}

export default LinkButton;
