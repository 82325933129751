export const ELEMENT_IDS = {
  //Nav Bar
  anldict_lftnav: "anldict-lftnav",
  busgls_lftnav: "busgls-lftnav",
  flddict_lftnav: "flddict-lftnav",
  rlsdict_lftnav: "rlsdict-lftnav",
  dataadm_lftnav: "dataadm-lftnav",
  datsrc_lftnav: "datsrc-lftnav",
  datdict_lftnav: "datdict-lftnav",
  useradm_grp_lst_lftnv: "useradm-grp-lst-lftnv",
  useradm_rol_lst_lftnv: "useradm-rol-lst-lftnv",
  useradm_usr_lst_lftnv: "useradm-usr-lst-lftnv",
  dataadm_impexp_genimp_lftnav: "dataadm-impexp-genimp-lftnav",
  dataorg_tagset_left_nav: "dataorg-tagset-left-nav",
  userjrn_onbrd_reference_dictionary: "userjrn-onbrd-reference-dictionary",
  userjrn_onbrd_reference_dictionary_lft_nav:
    "userjrn-onbrd-reference-dictionary-lft-nav",

  //Analytics
  anldict_detpg_data_fullpag_tab: "anldict-detpg-data-fullpag-tab",
  anldict_detpg_pag_fullpag_tab: "anldict-detpg-pag-fullpag-tab",
  anldict_detpg_ovw_edi_btn: "anldict-detpg-ovw-edi-btn",
  anldict_detpg_ovw_end_btn: "anldict-detpg-ovw-end-btn",
  anldict_detpg_ovw_fol_btn: "anldict-detpg-ovw-fol-btn",
  anldict_detpg_ovw_fullpag_tab: "anldict-detpg-ovw-fullpag-tab",
  anldict_detpg_ovw_ref_nav: "anldict-detpg-ovw-ref-nav",
  anldict_detpg_ovw_ref_ttl: "anldict-detpg-ovw-ref-ttl",
  anldict_lst_cre_btn: "anldict-lst-cre-btn",
  anldict_lst_edi_btn: "anldict-lst-edi-btn",
  anldict_lst_exp_btn: "anldict-lst-exp-btn",
  anldict_lst_getvie_btn: "anldict-lst-getvie-btn",
  anldict_lst_del_btn: "anldict-lst-del-btn",
  anldict_lst_hig_tab: "anldict-lst-hig-tab",
  anldict_lst_masupdtag_btn: "anldict-lst-masupdtag-btn",
  anldict_lst_masupd_btn: "anldict-lst-masupd-btn",
  anldict_lst_shr_btn: "anldict-lst-shr-btn",
  anldict_lst_lst_resval: "anldict-lst-lst-resval",
  anldict_detpg_ovw_done_btn: "anldict-detpg-ovw-done-btn",
  anldict_detpg_ovw_edit_desc_info: "anldict-detpg-ovw-edit-desc",
  anldict_detpg_ove_save_desc_info: "anldict-detpg-ovw-save-desc",
  anldict_detpg_ovw_edit_defi: "anldict-detpg-ovw-edit-def",

  //Sources
  datsrc_src_add_btn: "datsrc-src-add-btn",
  datsrc_src_add_src_id: "datsrc-src-add-**-src",
  datsrc_src_add_sav: "datsrc-src-add-sav",
  datsrc_src_add_srcdesc: "datsrc-src-add-srcdesc",
  datsrc_src_add_srcnam: "datsrc-src-add-srcnam",

  //Enterprise Search
  entsrch_bar: "entsrch-bar",
  entsrch_col_tab: "entsrch-col-tab",
  entsrch_rpt_tab: "entsrch-rpt-tab",
  entsrch_tbl_tab: "entsrch-tbl-tab",
  entsrch_tbl_flt_tab: "entsrch-tbl-src-flt-tab",
  entsrch_tbl_flt_val: "entsrch-tbl-flt-val",
  entsrch_tbl_res_val: "entsrch-tbl-res-val",
  entsrch_trm_tab: "entsrch-trm-tab",
  entsrch_bot_tab: "entsrch-bot-tab",
  entsrch_bot_res_val: "entsrch-bot-res-val",

  //DataDictionary
  datdict_detpg_info_cert_btn: "datdict-detpg-info-cert-btn",
  datdict_detpg_info_edt_btn: "datdict-detpg-info-edt-btn",
  datdict_detpg_info_endrs_btn: "datdict-detpg-info-endrs-btn",
  datdict_detpg_info_flw_btn: "datdict-detpg-info-flw-btn",
  datdict_detpg_info_fulpag_desc: "datdict-detpg-info-fulpag-desc",
  datdict_detpg_info_fulpag_mgby: "datdict-detpg-info-fulpag-mgby",
  datdict_lst_cre_btn: "datdict-lst-cre-btn",
  datdict_lst_del_btn: "datdict-lst-del-btn",
  datdict_lst_edit_btn: "datdict-lst-edit-btn",
  datdict_lst_exp_btn: "datdict-lst-exp-btn",
  datdict_lst_getvw_btn: "datdict-lst-getvw-btn",
  datdict_lst_hlt_tab: "datdict-lst-hlt-tab",
  datdict_lst_masupdtag_btn: "datdict-lst-masupdtag-btn",
  datdict_lst_masupd_btn: "datdict-lst-masupd-btn",
  datdict_lst_shr_btn: "datdict-lst-shr-btn",
  datdict_detpg_info_ref_goto: "datdict-detpg-info-ref-goto",
  datdict_detpg_info_ref_ttl: "datdict-detpg-info-ref-ttl",
  datdict_detpg_info_getsugg_btn: "datdict-detpg-info-getsugg-btn",
  datdict_detpg_info_sugedi_btn: "datdict-detpg-info-sugedi-btn",
  datdict_detpg_dq_ruldet_ttl: "datdict-detpg-dq-ruldet-ttl",
  datdict_detpg_data_dictvw_btn: "datdict-detpg-data-dictvw-btn",
  datdict_detpg_info_edi_cols_btn: "datdict-detpg-info-edi-cols-btn",
  datdict_detpg_data_smpdat_btn: "datdict-detpg-data-smpdat-btn",
  datdict_detpg_data_smpdat_colsum: "datdict-detpg-data-smpdat-colsum",
  datdict_detpg_data_smpdat_dtgrid: "datdict-detpg-data-smpdat-dtgrid",
  datdict_detpg_data_smpdat_prflinfo: "datdict-detpg-data-smpdat-prflinfo",
  datdict_detpg_data_edi_frm: "datdict-detpg-data-edi-frm",
  datdict_detpg_data_fulpag_tab: "datdict-detpg-data-fulpag-tab",
  datdict_detpg_info_fulpag_tab: "datdict-detpg-info-fulpag-tab",
  datdict_detpg_data_quality_fulpag_tab: "datdict-detpg-dq-fulpag-tab",
  datdict_detpg_data_model_fulpag_tab: "datdict-detpg-data-model-fulpag-tab",
  datdict_lst_list_resval: "datdict-lst-list-resval",
  datdict_detpg_data_col_dq_btn: "datdict-detpg-data-col-dq-btn",
  datdict_detpg_info_col_ent_rvw_btn: "datdict-detpg-info-col-ent-rvw-btn",
  datdict_detpg_info_col_ent_opt_btn: "datdict-detpg-info-col-ent-opt-btn",
  datdict_detpg_analysis_fulpag_tab: "datdict-detpg-analysis-fulpag-tab",
  datdict_detpg_info_done_btn: "datdict-detpg-info-done-btn",
  datdict_detpg_info_ref_edit_btn: "datdict-detpg-info-ref-edit-btn",
  datdict_detpg_info_ref_buss_name_field:
    "datdict-detpg-info-ref-buss-name-field",
  datdict_detpg_info_ref_save_btn: "datdict-detpg-info-ref-save-btn",
  datadict_detpg_edit_desc_info: "datdict-detpg-info-edit-desc",
  datadict_detpg_save_desc_info: "datdict-detpg-info-save-desc",
  datadict_detpg_edit_defi: "datdict-detpg-info-edit-defi",

  //Groups
  useradm_grp_cre_btn: "useradm-grp-cre-btn",
  useradm_grp_act_btn: "useradm-grp-act-btn",
  useradm_grp_del_btn: "useradm-grp-del-btn",
  useradm_grp_edi_btn: "useradm-grp-edi-btn",

  //Roles
  useradm_rol_cre_btn: "useradm-rol-cre-btn",
  useradm_rol_act_btn: "useradm-rol-act-btn",
  useradm_rol_del_btn: "useradm-rol-del-btn",
  useradm_rol_edi_btn: "useradm-rol-edi-btn",

  //Users
  useradm_usr_act_btn: "useradm-usr-act-btn",
  useradm_usr_cre_btn: "useradm-usr-cre-btn",
  useradm_usr_deact_btn: "useradm-usr-deact-btn",
  useradm_usr_del_btn: "useradm-usr-del-btn",
  useradm_usr_edi_btn: "useradm-usr-edi-btn",
  userjrn_per_hom_lftnav: "userjrn-per-hom-lftnav",
  userjrn_per_chat_lftnav: "userjrn-per-chat-lftnav",
  useradm_usr_reinv_btn: "useradm-usr-reinv-btn",

  //Administartion
  actadm_gatset_add_btn: "actadm-gatset-add-btn",
  actadm_gatset_act_bn: "actadm-gatset-act-bn",
  actadm_gatset_del_btn: "actadm-gatset-del-btn",
  actadm_gatset_edi_btn: "actadm-gatset-edi-btn",
  actadm_sso_act_btn: "actadm-sso-act-btn",
  actadm_sso_add_btn: "actadm-sso-add-btn",
  actadm_sso_del_btn: "actadm-sso-del-btn",
  actadm_sso_edi_btn: "actadm-sso-edi-btn",
  actadm_gatset_lst_tab: "actadm-gatset-lst-tab",
  actadm_genset_appsec_tab: "actadm-genset-appsec-tab",
  actadm_genset_subscr_tab: "actadm-genset-subscr-tab",
  actadm_genset_usrsec_tab: "actadm-genset-usrsec-tab",
  actadm_sso_tab: "actadm-sso-tab",
  actadm_modeul_settings_tab: "actadm-module-settings-tab",

  //Add listing
  allcat_lst_edi_view_del_btn: "allcat-lst-edi-view-del-btn", //
  allcat_lst_edi_edit_btn: "allcat-lst-edi-edit-btn", //
  allcat_lst_lst_export_btn: "allcat-lst-lst-export-btn", //
  allcat_lst_lst_get_views_btn: "allcat-lst-lst-get-views-btn", //
  allcat_lst_lst_view_ttl: "allcat-lst-lst-view-ttl", //
  allcat_lst_lst_grid: "allcat-lst-lst-grid", //
  allcat_lst_lst_share_btn: "allcat-lst-lst-share-btn", //
  allcat_lst_lst_add_btn: "allcat-lst-lst-add-btn", //
  allcat_lst_lst_create_btn: "allcat-lst-lst-create-btn", //
  allcat_lst_edi_view_name_formfield: "allcat-lst-edi-view-name-formfield", //
  allcat_lst_edi_view_crit_section: "allcat-lst-edi-view-crit-section", //
  allcat_lst_edi_view_lyt_section: "allcat-lst-edi-view-lyt-section", //
  allcat_lst_edi_view_save_btn: "allcat-lst-edi-view-save-btn", //
  allcat_lst_edi_view_clone_btn: "allcat-lst-edi-view-clone-btn", //
  allcat_lst_edi_view_del_confirm: "allcat-lst-edi-view-del-confirm",
  allcat_lst_lst_grid_value: "allcat-lst-lst-grid-value",
  allcat_lst_lst_tags_upd_btn: "allcat-lst-lst-tags-upd-btn",
  allcat_lst_lst_more_actions_btn: "allcat-lst-lst-actions-btn",
  allcat_lst_lst_mass_upd_btn: "allcat-lst-lst-mass-upd-btn",
  allcat_lst_lst_add_tag_btn: "allcat-lst-lst-add-tag-btn",
  allcat_lst_lst_remove_tag_btn: "allcat-lst-lst-remove-tag-btn",

  allcat_lst_add_rule_btn: "allcat-lst-add-rule-btn",

  allcat_lst_lst_update_schedule_btn: "allcat-lst-lst-update-schedule-btn",
  allcat_lst_lst_remove_schedule_btn: "allcat-lst-lst-remove-schedule-btn",
  allcat_lst_lst_clone_rule_btn: "allcat-lst-lst-clone-rule-btn",
  allcat_lst_lst_delete_rule_btn: "allcat-lst-lst-delete-rule-btn",

  allcat_lst_lst_activate_job_btn: "allcat-lst-lst-activate-job-btn",
  allcat_lst_lst_deactivate_job_btn: "allcat-lst-lst-deactivate-job-btn",

  allcat_lst_lst_run_rule_btn: "allcat-lst-lst-run-rule-btn",
  allcat_lst_lst_run_rule_online_btn: "allcat-lst-lst-run-rule-online-btn",
  allcat_lst_lst_run_rule_offline_btn: "allcat-lst-lst-run-rule-offline-btn",

  allcat_lst_lst_hide_btn: "allcat-lst-lst-hide-btn",
  allcat_lst_lst_un_hide_btn: "allcat-lst-lst-un-hide-btn",
  allcat_lst_lst_data_classification_btn:
    "allcat-lst-lst-data-calssification-btn",

  allcat_lst_lst_delete_btn: "allcat-lst-lst-delete-btn",

  //
  allcat_lst_lst_mass_upd_attr_fld: "allcat-lst-lst-mass-upd-attr-*-fld",
  allcat_lst_lst_mass_upd_attr_value: "allcat-lst-lst-mass-upd-attr-*-value-**",
  allcat_lst_lst_mass_upd_apply_btn: "allcat-lst-lst-mass-upd-apply-btn",
  allcat_lst_lst_upd_tag_tag_fld: "allcat-lst-lst-upd-tag-tag-fld",
  allcat_lst_lst_upd_tag_tag_fld_value: "allcat-lst-lst-upd-tag-tag-fld-value",
  allcat_lst_lst_upd_tag_apply_btn: "allcat-lst-lst-upd-tag-apply-btn",
  allcat_lst_lst_grid_select: "allcat-lst-lst-grid-select",
  allcat_lst_list_hlttot: "allcat-lst-list-hlttot",

  //Buisiness glossary
  busgls_detpg_edit_btn: "busgls-detpg-edit-btn", //
  busgls_detpg_like_btn: "busgls-detpg-like-btn", //
  busgls_detpg_follow_btn: "busgls-detpg-follow-btn", //
  busgls_detpg_tab: "busgls-detpg-tab",
  busgls_detpg_sugs_btn: "busgls-detpg-sugs-btn", //
  busgls_detpg_ref_ttl: "busgls-detpg-ref-ttl", //
  busgls_detpg_sugedit_btn: "busgls-detpg-sugedit-btn", //
  busgls_detpg_ref_goto_btn: "busgls-detpg-ref-goto-btn",
  busgls_detpg_done_btn: "busgls-detpg-done-btn",
  busgls_detpg_edit_desc_info: "busgls-detpg-edit-desc-info",
  busgls_detpg_save_desc_info: "busgls-detpg-save-desc-info",
  busgls_detpg_edit_defi: "busgls-detpg-edit-defi",
  busgls_detpg_sbmt_appr_btn: "busgls-detpg-sbmt-appr-btn",
  //Col
  flddict_detpag_ref_ttl: "flddict-detpag-ref-ttl",

  //Enterprise
  entsrch_filt_section: "entsrch-filt-section",
  entsrch_results_section: "entsrch-results-section",

  //User Journey onboarding
  userjrn_onbrd_add1src_btn: "userjrn-onbrd-add1src-btn",
  userjrn_onbrd_prdtour_btn: "userjrn-onbrd-prdtour-btn",
  userjrn_per_hom_jumpto_section: "userjrn-per-hom-jumpto-section",
  userjrn_per_drop_down: "userjrn-per-drop-down",
  userjrn_per_my_setting: "userjrn-per-my-setting",
  userjrn_per_glblflt_ttl: "userjrn-per",
  userjrn_help_hlp_top_nav: "userjrn-help-hlp-top-nav",
  rpt_sumrep_left_nav: "rpt-sumrep-left-nav",
  chat_pref_def_ans_view: "chat-pref-def-ans-view",
  chat_pref_def_chart_sort: "chat-pref-def-chart-sort",
  chat_pref_chrt_sort_asc: "chat-pref-chrt-sort-asc",
  chat_pref_chrt_sort_desc: "chat-pref-chrt-sort-desc",
  chat_pref_ans_outp: "chat-pref-ans-outp",
  chat_pref_clps_vw: "chat-pref-clps-vw",
  chat_pref_lng: "chat-pref-lng",
  chat_pref_clps_vw_qst_onl: "chat-pref-clps-vw-qst-onl",
  chat_pref_clps_vw_def_vw: "chat-pref-clps-vw-dft-vw",
  chat_pref_ans_outp_show_log: "chat-pref-ans-outp-show-log",
  chat_pref_save_btn: "chat-pref-save-btn",
  userjrn_per_top_nav_gateways_sec: "userjrn-per-top-nav-gateways-sec",
  userjrn_per_gateways_lst_sec: "userjrn-per-gateways-lst-sec",

  // Analysis
  ans_detpg_info_ref_goto: "ans-detpg-info-ref-goto",
  ans_detpg_info_ref_ttl: "ans-detpg-info-ref-ttl",

  //chat
  chat_find_data_left_nav: "chat-find-data-left-nav",
  chat_preferences_left_nav: "chat-preferences-left-nav",
  chat_logout_left_nav: "chat-logout-left-nav",
  new_chat_left_nav: "new-chat-left-nav",
  chat_list_item: "chat-list-item",

  //tagsets
  dataorg_tagset_lst_search_field: "dataorg-tagset-lst-search-field",
  dataorg_tagset_lst_value_1: "dataorg-tagset-lst-value",
  dataorg_tagset_dtl_pg_edt_btn: "dataorg-tagset-dtl-pg-edt-btn",
  dataorg_tagset_dtl_pg_tags_edt_btn: "dataorg-tagset-dtl-pg-tags-edt-btn",
  dataorg_tagset_dtl_pg_tags_save_btn: "dataorg-tagset-dtl-pg-tags-save-btn",
  dataorg_tagset_dtl_pg_tag_edt_name_field_1:
    "dataorg-tagset-dtl-pg-tag-edt-name-field",
  dataorg_tagset_dtl_pg_tag_edt_desc_field_1:
    "dataorg-tagset-dtl-pg-tag-edt-desc-field",
  dataorg_tagset_dtl_pg_tag_edt_prmt_tag_field_1:
    "dataorg-tagset-dtl-pg-tag-edt-prmt-tag-field",
  dataorg_tagset_dtl_pg_tag_edt_pvc_field_1:
    "dataorg-tagset-dtl-pg-tag-edt-pvc-field",
  dataorg_tagset_dtl_pg_save_btn: "dataorg-tagset-dtl-pg-save-btn",

  //CADDI
  caddi_lft_nav: "caddi-lft-nav",
  caddi_chat_topics_lst_1: "caddi-chat-topics-lst",
  caddi_abt_qry_blk: "caddi-abt-qry-blk",
  caddi_gid_me_blk: "caddi-gid-me-blk",
  caddi_gid_me_btn: "caddi-gid-me-btn",
  caddi_qry_rslt_1: "caddi-qry-rslt",
  caddi_qst_field: "caddi-qst-field",
  caddi_obj_fld: "caddi-obj-fld",
  caddi_obj_save_btn: "caddi-obj-save-btn",
  caddi_qst_entr_btn: "caddi-qst-entr-btn",

  //Clone Rule Summary Modal
  all_rule_tab: "all-rule-tab",
  error_rule_tab: "error-rule-tab",
  success_rule_tab: "success-rule-tab",

  //Bot
  chatbot_train_bot_tab: "chatbot-train-bot-tab",
  chatbot_test_tool_tab: "chatbot-test-tool-tab",
  chatbot_analytics_tab: "chatbot-train-bot-tab",
  chatbot_train_bot_tab_dots_menu: "chatbot-train-bot-tab-dots-menu",
  chat_bot_train_bot_refr_all_btn: "chat-bot-train-bot-refr-all-btn",
  chat_bot_refr_all_conf_popovr_ok_btn: "chat-bot-refr-all-conf-popovr-ok-btn",
  chat_bot_refr_all_conf_popovr_cancel_btn:
    "chat-bot-refr-all-conf-popovr-cancel-btn",
  chat_bot_train_bot_stats_fil: "chat-bot-train-bot-stats-fil",
  chat_bot_train_bot_stats_fil_cnt: "chat-bot-train-bot-stats-fil-cnt",
  chat_bot_guidelines_types_fil: "chat-bot-guidelines-types-fil",
  chat_bot_guidelines_types_fil_cnt: "chat-bot-guidelines-types-fil-cnt",
  chat_bot_crt_rst_tl: "chat-bot-crt-rst-tl",
  chat_bot_crt_rst_agnt: "chat-bot-crt-rst-agnt",
  caddi_qry_rslt_lst_fedbk_sec: "caddi-qry-rslt-lst-fedbk-sec",
  caddi_qry_menu_sec: "caddi_qry_menu_sec",
  caddi_qry_rslt_lst_fedbk_sec_thms_up_btn:
    "caddi-qry-rslt-lst-fedbk-sec-thms-up-btn",
  caddi_qry_rslt_lst_fedbk_sec_thms_dn_btn:
    "caddi-qry-rslt-lst-fedbk-sec-thms-dn-btn",
  caddi_qry_hedr_lst_menu: "caddi-qry-hedr-lst-menu",
  caddi_qry_hedr_lst_menu_cnt: "caddi-qry-hedr-lst-menu-cnt",

  // filter modal
  add_new_filter_condition: "add-new-filter-condition",
  add_new_custom_expression: "add-new-custom-expression",
  add_new_criteria: "add-new-criteria",

  // rule-detail-page-definition-tab
  definiton_tab_edit_mode: "definition-tab-edit-mode",
  data_tab_edit_mode: "data-tab-edit-mode",
  history_tab_edit_mode: "data-tab-edit-mode",
  instructions_tab_edit_mode: "data-tab-edit-mode",

  rule_detail_page_tabs: "rule-detail-page-tabs",

  caddi_qry_log_sec: "caddi_qry_log_sec",
  caddi_qry_code_prvw_sec: "caddi_qry_code_prvw_sec",
  caddi_qry_exp_sec: "caddi_qry_exp_sec",
  cadd_query_menu_sec: "cadd-query-menu-sec",
  cht_bot_trng_tab: "cht-bot-trng-tab",
  cht_bot_anal_tab: "cht-bot-anal-tab",
  cht_bot_trng_add_cnp_btn: "cht-bot-trng-add-cnp-btn",
  cht_bot_anal_eng_sec: "cht-bot-anal-eng-sec",
  cht_bot_anal_top_usr_sec: "cht-bot-anal-top-usr-sec",
  cht_bot_anal_cht_lst_sec: "cht-bot-anal-cht-lst-sec",
  caddi_qst_srch_field: "caddi-qst-srch-field",
  caddi_time_frame_fil: "caddi-time-frame-fil",
  caddi_time_frame_fil_cnt: "caddi-time-frame-fil-cnt",
  caddi_qry_rslt_lst_bokm_sec: "caddi-qry-rslt-lst-bokm-sec",
  cht_bot_anal_tab_cht_lst: "cht-bot-anal-tab-cht-lst",
  cht_bot_anal_tab_fbk_lst: "cht-bot-anal-tab-fbk-lst",
  cht_bot_anal_tab_fbk_lst_qst_1: "cht-bot-anal-tab-fbk-lst-qst",

  datdict_detpg_dq_ad_rul_btn: "datdict-detpg-dq-ad-rul-btn",
  datdict_detpg_dq_ad_rul_piplin_check_btn:
    "datdict-detpg-dq-ad-rul-piplin-check-btn",
  datdict_detpg_dq_ad_rul_cnt_btn: "datdict-detpg-dq-ad-rul-cnt-btn",
  datdict_detpg_dq_ad_rul_sav_btn: "datdict-detpg-dq-ad-rul-sav-btn",
  datdict_detpg_dq_ruldet_edt_btn: "datdict-detpg-dq-ruldet-edt-btn",
  datdict_detpg_dq_ruldet_rn_rul_btn: "datdict-detpg-dq-ruldet-rn-rul-btn",
  datdict_detpg_dq_ruldet_rn_rul_btn_cont:
    "datdict-detpg-dq-ruldet-rn-rul-btn-cont",
  datdict_detpg_dq_ruldet_rn_rul_onl_btn:
    "datdict-detpg-dq-ruldet-rn-rul-onl-btn",
  datdict_detpg_dq_ruldet_rn_rul_ofl_btn:
    "datdict-detpg-dq-ruldet-rn-rul-ofl-btn",
  datdict_detpg_dq_ruldet_ad_job_btn: "datdict-detpg-dq-ruldet-ad-job-btn",
  datdict_detpg_dq_ruldet_three_dot_menu:
    "datdict-detpg-dq-ruldet-three-dot-menu",
  datdict_detpg_dq_ruldet_three_dot_menu_ad_job:
    "datdict-detpg-dq-ruldet-three-dot-menu-ad-job",
  ad_job_form_def_tab: "ad-job-form-def-tab",
  ad_job_form_noti_tab: "ad-job-form-noti-tab",
  ad_job_form_sch_tab: "ad-job-form-sch-tab",
  ad_job_form_sav_btn: "ad-job-form-sav-btn",
  datdict_detpg_dq_ruldet_sch_job_menu: "datdict-detpg-dq-ruldet-sch-job-menu",
  datdict_detpg_dq_ruldet_sch_job_menu_res:
    "datdict-detpg-dq-ruldet-sch-job-menu-res-1",
  datdict_detpg_dq_switch_avl_rule_view_btn:
    "datdict-detpg-dq-switch-avl-rule-view-btn",
  datdict_detpg_dq_ruldet_rn_onl_prog_bar:
    "datdict-detpg-dq-ruldet-rn-onl-prog-bar",

  // Reference dictionary
  ref_dict_ad_btn: "ref-dict-ad-btn",
  ad_ref_dict_frm_name_field: "ad-ref-dict-frm-name-field",
  ad_ref_dict_frm_desc_field: "ad-ref-dict-frm-desc-field",
  ad_ref_dict_frm_key_val_field: "ad-ref-dict-frm-key-val-field",
  ad_ref_dict_frm_is_heir_field: "ad-ref-dict-frm-is-heir-field",
  ad_ref_dict_frm_save_btn: "ad-ref-dict-frm-save-btn",
  ref_dict_entr_vals_manualy: "ref-dict-entr-vals-manualy",
  ref_dict_entr_vals_thrg_file: "ref-dict-entr-vals-thrg-file",
  ref_dict_dta_has_header: "ref-dict-dta-has-header",
  ref_dict_dta_field: "ref-dict-dta-field",
  ref_dict_save_vals_btn: "ref-dict-save-vals-btn",
  ref_dict_save_btn: "ref-dict-save-btn",
  ref_dict_vals_grid: "ref-dict-vals-grid",

  //Dashboards
  dash_crt_view: "dash-crt-view",
  add_dash_name_field: "add-dash-name-field",
  add_dash_desc_field: "add-dash-desc-field",
  add_dash_save_btn: "add-dash-save-btn",
  dash_add_wgt_btn: "dash-add-wgt-btn",
  dash_add_wgt_pck_frm_gallery: "dash-add-wgt-pck-frm-gallery",
  dash_add_wgt_crt_your_own: "dash-add-wgt-crt-your-own",
  dash_add_wgt_all_wgts_list: "dash-add-wgt-all-wgts-list",
  dashboard_lftnav: "dashboard-lftnav",
  allcat_lst_dflt_views_sec: "allcat-lst-dflt-views-sec",
  dash_add_wgt_form_ttl_field: "dash-add-wgt-form-ttl-field",
  dash_add_wgt_form_mod_field: "dash-add-wgt-form-mod-field",
  dash_add_wgt_form_mod_flt_field: "dash-add-wgt-form-mod-flt-field",
  dash_add_wgt_form_chr_typ_field: "dash-add-wgt-form-chr-typ-field",
  dash_add_wgt_form_enb_data_lbl_check: "dash-add-wgt-form-enb-data-lbl-check",
  dash_add_wgt_form_slic_field: "dash-add-wgt-form-slic-field",
  dash_add_wgt_form_metr_field: "dash-add-wgt-form-metr-field",
  dash_add_wgt_form_sort_by_prop_field: "dash-add-wgt-form-sort-by-prop-field",
  dash_add_wgt_form_sort_by_field: "dash-add-wgt-form-sort-by-field",
  dash_add_wgt_save_btn: "dash-add-wgt-save-btn",
  dash_wgt_exp_icon: "dash-wgt-exp-icon-",
  dash_wgt_three_dot_menu: "dash-wgt-three-dot-menu-",
  dash_wgt_three_dot_menu_icon: "dash-wgt-three-dot-menu-icon-",
  dash_wgt_opt_add_to_home: "dash-wgt-opt-add-home-",
  dash_wgt_go_to_dash: "dash-wgt-go-to-dash-",
  dash_add_wgt_pck_frm_gallery_wgt: "dash-add-wgt-pck-frm-gallery-wgt-",
  dash_wgt: "dash-wgt-",
};
