import { useHistory } from "react-router";
import { GridReadyEvent } from "ag-grid-community";
import useWebSocket from "react-use-websocket";

import { RefObject, useCallback, useState } from "react";
import { KeyValuePairType, NodeType } from "../app.types";

import { getEnvVariables, jsonParse, openNotification } from "../utils";
import { useCancelModal } from "./useCancelModal";

import { useOpenModal } from "./useopenmodal";
import { useRequestWithMethod } from "../api";

import SuccessNotificationMessage from "../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import LinkButton from "../components/linkbutton/linkbutton";

import { RunStatusId } from "../parsers/ruleparser/ruleparser.types";
import { PopulateDataParams } from "../pages/listingpage/listingpage.types";

import { getJobDetailPageUrl } from "../utils/getjobdetailpageurl";
import { getPageNode } from "../pages/listingpage/listingpage.utils";

import { useGatewayDownPrompt } from "./usegatewaydownprompt";

export type RunProfilingSocketResponseType = {
  execution_id: string;
  job_id: number;
  qry_status: string;
  return_msg: string;
  id: "PS" | "EC" | "PC" | "IP";
  error_id: "EF";
  node_type: NodeType;
  rule_result: number;
  rule_status: RunStatusId;
  rule_exec_on: string;
  is_alerting: boolean;
  node_id: string;
  rule_score: number;
};

export type NodeProfilingHOCProps = {
  nodeType: NodeType;
  nodeIds: string[];

  onOnlineProfilingProcessCompletedInGrid?: (
    params: PopulateDataParams
  ) => void;
  onOnlineProfilingProcessCompletedInPage?: () => void;
  onGatewayDown?: () => void;
  onOnlineProfilingCompleted?: (
    updatedRunResult: RunProfilingSocketResponseType
  ) => void;
  listingGovViewId?: string;
  gridRef?: RefObject<GridReadyEvent>;
  clearGridSelection?: () => void;
  allExceptionsInOnlineRun?: boolean;
  propsOnCancelProfiling?: () => void;

  isSelectAll?: boolean;
  filterModel?: KeyValuePairType<any>;
  countFilter?: string;
  gatewayId?: string;
};

export type OnlineProfilingType = {
  inProgress: boolean;
  progressPercenatge: number;
  jobId: number;
  isFailed: boolean;
  nodeIds: number[];
};

export type CancelProfilingModalProps = {
  numberOfNodesSelected: number;
  type: NodeType;
  jobId: number;
  onCancelNodeProfilingSuccess: () => void;
};

const jobConfiguration = {
  job_type: "SCH",
  freq_type: "OTM",
};

const PROGRESS_BAR_RULE_EXECUTION_STAT = 80;
const { REACT_APP_WEB_SOCKET_URL } = getEnvVariables();

const useNodeProfiling = (props: NodeProfilingHOCProps): any => {
  const {
    nodeType,
    nodeIds,
    listingGovViewId,
    gridRef,
    onOnlineProfilingCompleted,
    onOnlineProfilingProcessCompletedInGrid,
    onOnlineProfilingProcessCompletedInPage,
    clearGridSelection,
    allExceptionsInOnlineRun = false,
    propsOnCancelProfiling,
    isSelectAll,
    filterModel,
    countFilter,
    gatewayId = "",
    onGatewayDown,
  } = props;

  const onOpenModal = useOpenModal();
  const onCancel = useCancelModal();
  const history = useHistory();

  const [onlineProfiling, setOnlineProfiling] = useState<OnlineProfilingType>({
    inProgress: false,
    progressPercenatge: 0,
    jobId: 0,
    isFailed: false,
    nodeIds: [],
  });

  const {
    onExecuteRequest: onRunNodeProfiling,
    isLoading: isLoadingRunNodeProfiling,
    error: errorInRunNodeProfiling,
  } = useRequestWithMethod("run_profiling", undefined, true);

  const { onExecuteRequest: onRunOfflineProfiling } = useRequestWithMethod(
    "update_field_data",
    undefined,
    false
  );

  const node =
    nodeType === "RLS"
      ? "RLS"
      : nodeType === "COL" || nodeType === "TBL"
      ? "PRF"
      : "";

  const { isRulePage, isTablePage } = getPageNode(nodeType);

  const onStartOnlineProfilingReq = useCallback(
    (connectionId: string) => {
      const parsedNodeIds = nodeIds?.map((id) => Number(id)) || [];
      const jobToProfile = {
        job_config: {
          ...jobConfiguration,
          all_exceptions: allExceptionsInOnlineRun,
        },
        node_ids: {
          include: parsedNodeIds,
        },
        is_online_job: true,
        connection_id: connectionId,
      };

      // in case of online run dont send search-id
      onRunNodeProfiling({ ...jobToProfile }, [node, ""]);
    },
    [nodeIds, nodeType, node, allExceptionsInOnlineRun]
  );

  const updateNodeRunStatusColumn = useCallback(
    (parsedJson: RunProfilingSocketResponseType) => {
      const nodeRunStatus = parsedJson?.rule_status;
      const nodeRunResult = parsedJson?.rule_result;
      const nodeRunDate = parsedJson?.rule_exec_on;
      const nodeId = parsedJson?.node_id;
      const isAlerting = parsedJson?.is_alerting;

      const itemsToUpdate: any[] = [];

      gridRef?.current?.api?.forEachNodeAfterFilterAndSort(function (rowNode) {
        const data = rowNode?.data;

        if (nodeId && Number(nodeId) === Number(data?.id)) {
          data.is_rule_profiling = false;
          if (nodeType === "RLS") {
            data.rule_exec_status_id = nodeRunStatus;
            data.run_result = nodeRunResult;
            data.run_date = nodeRunDate;
            data.alert_status_id = isAlerting ? "ALE" : "HEL";
            itemsToUpdate.push(data);
          } else {
            data.profiling_status = nodeRunStatus;
            data.profiling_date = nodeRunDate;
            itemsToUpdate.push(data);
          }
        }
      });

      gridRef?.current?.api.applyTransaction({
        update: itemsToUpdate,
      });

      if (nodeType === "RLS") {
        gridRef?.current?.api?.refreshCells({
          force: true,
          columns: ["run_status", "run_result", "alert_status", "run_date"],
          suppressFlash: true,
        });
      } else {
        gridRef?.current?.api?.refreshCells({
          force: true,
          columns: ["profiling_status", "profiling_date"],
          suppressFlash: true,
        });
      }
    },
    [onlineProfiling, nodeType]
  );

  const onProfilingProgress = useCallback(
    (parsedJson: RunProfilingSocketResponseType) => {
      const initialProgress = onlineProfiling?.progressPercenatge || 10;
      const numberOfRecords = onlineProfiling?.nodeIds?.length || 1;

      const errorKey: keyof RunProfilingSocketResponseType = "error_id";

      const isProfilingProcessFailed = errorKey in parsedJson;

      const statusId = parsedJson?.id;
      const isProcessStarted = statusId === "PS";
      const isRuleExecutionInProgress = statusId === "IP";
      const isRuleExecutionCompleted = statusId === "EC";
      const isProcessCompleted = statusId === "PC";

      if (isProcessStarted) {
        setOnlineProfiling((st) => ({
          ...st,
          jobId: parsedJson?.job_id,
        }));
      } else if (isRuleExecutionInProgress) {
        const progressPerRecord =
          PROGRESS_BAR_RULE_EXECUTION_STAT / numberOfRecords;
        const currentProgress = initialProgress + progressPerRecord;

        // updating grid data in hook
        gridRef && updateNodeRunStatusColumn(parsedJson);
        // updating state date in detail page comp state
        onOnlineProfilingCompleted?.(parsedJson);

        setOnlineProfiling((st) => ({
          ...st,
          progressPercenatge: Math.round(currentProgress),
        }));
      } else if (isRuleExecutionCompleted || isProcessCompleted) {
        setOnlineProfiling((st) => ({
          ...st,
          inProgress: isRuleExecutionCompleted,
          progressPercenatge: isRuleExecutionCompleted
            ? onlineProfiling?.progressPercenatge
            : 100,
        }));

        if (isProcessCompleted) {
          gridRef
            ? onOnlineProfilingProcessCompletedInGrid?.({})
            : onOnlineProfilingProcessCompletedInPage?.();
        }
      } else if (isProfilingProcessFailed) {
        setOnlineProfiling((st) => ({
          ...st,
          inProgress: false,
          isFailed: true,
        }));
      }
    },
    [
      onlineProfiling,
      updateNodeRunStatusColumn,
      onOnlineProfilingCompleted,
      onOnlineProfilingProcessCompletedInPage,
      onOnlineProfilingProcessCompletedInGrid,
    ]
  );

  const onGatewayDownInGrid = useCallback(() => {
    gridRef?.current?.api?.forEachNodeAfterFilterAndSort(function (rowNode) {
      const data = rowNode?.data;
      data.is_rule_profiling = false;
    });

    if (nodeType === "RLS") {
      gridRef?.current?.api?.refreshCells({
        force: true,
        columns: ["run_status"],
        suppressFlash: true,
      });
    }
  }, [gridRef, nodeType]);

  const { triggerPrompt } = useGatewayDownPrompt(gatewayId);

  const { sendJsonMessage, getWebSocket } = useWebSocket(
    REACT_APP_WEB_SOCKET_URL,
    {
      onMessage: (mes) => {
        const parsedJson = jsonParse(mes?.data, true);

        const key: keyof RunProfilingSocketResponseType = "id";
        const isProfilingProcessStarted = key in parsedJson;

        console.log({ parsedJson });

        if (parsedJson?.is_gateway_down) {
          gridRef ? onGatewayDownInGrid?.() : onGatewayDown?.();

          setOnlineProfiling((st) => ({
            ...st,
            inProgress: false,
            progressPercenatge: 0,
            jobId: 0,
            nodeIds: [],
          }));
          triggerPrompt?.(undefined);
        }

        if (!!parsedJson?.message || typeof parsedJson?.message === "string") {
          onStartOnlineProfilingReq(parsedJson?.connection_id);
          clearGridSelection?.();
        } else if (isProfilingProcessStarted) {
          onProfilingProgress(parsedJson);
        }
      },
      reconnectInterval: 10,
      shouldReconnect: () => true,
      onClose: (): void => {
        // console.log("Connection Closed!!");
      },
    }
  );

  // CONNECTION ID CONFIRMATION FROM BE
  // useCustomPolling(
  //   () => {
  //     sendJsonMessage({
  //       action: "getconnectionId",
  //       message: "ping check",
  //     });
  //   },
  //   60000,
  //   []
  // );

  const onClickRunProfileOnline = useCallback(() => {
    const selectedNodeIds = nodeIds?.map((node) => Number(node)) || [];

    setOnlineProfiling((st) => ({
      ...st,
      jobId: 0,
      inProgress: true,
      progressPercenatge: 10,
      nodeIds: selectedNodeIds,
    }));

    const itemsToUpdate: any[] = [];

    gridRef?.current?.api.forEachNodeAfterFilterAndSort(function (rowNode) {
      const data = rowNode?.data;
      if (selectedNodeIds?.includes(Number(data?.id))) {
        const data = rowNode?.data;
        data.is_rule_profiling = true;
        itemsToUpdate.push(data);
      }
    });

    gridRef?.current?.api.applyTransaction({
      update: itemsToUpdate,
    });

    gridRef?.current?.api?.refreshCells({
      force: true,
      columns: ["run_status", "profiling_status"],
      suppressFlash: true,
    });

    sendJsonMessage({
      action: "getconnectionId",
    });
  }, [nodeIds, sendJsonMessage]);

  const onOfflineNodeProfilingSuccess = useCallback(
    (res) => {
      const jobSchedulerResp = isTablePage
        ? jsonParse(res?.data?.FN_UPDATE_GOV_TBLS_DATA)
        : jsonParse(res?.data?.FN_UPDATE_GOV_RLSS_DATA);

      const jobPageUrl = getJobDetailPageUrl(jobSchedulerResp?.job_def_id);

      openNotification(
        <SuccessNotificationMessage
          message={
            <span>
              <LinkButton
                isTextUnderLine
                marginLeft="3px"
                marginRight="3px"
                onClick={(e): void => {
                  if (e?.ctrlKey) {
                    window.open(jobPageUrl, "_blank");
                  } else {
                    history?.push(jobPageUrl);
                  }
                }}
              >
                {jobSchedulerResp?.job_id}
              </LinkButton>{" "}
              {nodeType === "RLS" ? "Rules " : "Profiling"} Job scheduled
              successfully.
            </span>
          }
        />
      );
    },
    [nodeType, isTablePage]
  );

  const onRunNodeProfilingSuccess = useCallback(
    (res) => {
      const jobName = res?.data?.job_id;
      const jobDefId = res?.data?.job_def_id;

      const jobPageUrl = getJobDetailPageUrl(jobDefId);

      openNotification(
        <SuccessNotificationMessage
          message={
            <span>
              <LinkButton
                isTextUnderLine
                marginLeft="3px"
                marginRight="3px"
                onClick={(e): void => {
                  if (e?.ctrlKey) {
                    window.open(jobPageUrl, "_blank");
                  } else {
                    history?.push(jobPageUrl);
                  }
                }}
              >
                {jobName}
              </LinkButton>{" "}
              {nodeType === "RLS" ? "Rules " : "Profiling"} Job scheduled
              successfully.
            </span>
          }
        />
      );
    },
    [nodeType]
  );

  const onClickRunProfileOffline = useCallback(() => {
    const parsedNodeIds = nodeIds?.map((id) => Number(id)) || [];

    const jobToProfile = {
      job_config: jobConfiguration,
      node_ids: {
        include: parsedNodeIds,
      },
      is_online_job: false,
    };

    if (listingGovViewId && isSelectAll) {
      onRunOfflineProfiling(
        [
          {
            isSelectAll: isSelectAll || false,
            filterModel: filterModel || {},
            countFilter: countFilter || "all",
            job_action: "CRT",
            job_def_ids: [],
          },
        ],
        [nodeType, listingGovViewId],
        onOfflineNodeProfilingSuccess
      );
    } else {
      onRunNodeProfiling(
        { ...jobToProfile },
        [node, ""],
        onRunNodeProfilingSuccess
      );
    }
  }, [
    nodeType,
    listingGovViewId,
    isSelectAll,
    filterModel,
    countFilter,
    nodeIds,
    onRunNodeProfilingSuccess,
    onOfflineNodeProfilingSuccess,
  ]);

  const onCancelNodeProfilingSuccess = useCallback(
    (response) => {
      propsOnCancelProfiling?.();

      getWebSocket()?.close();

      if (gridRef) {
        const itemsToUpdate: [] = [];

        gridRef?.current?.api?.forEachNodeAfterFilterAndSort(function (
          rowNode
        ) {
          const data = rowNode?.data;
          data.is_rule_profiling = false;
        });

        gridRef?.current?.api.applyTransaction({
          update: itemsToUpdate,
        });

        gridRef?.current?.api?.refreshCells({
          force: true,
          columns: ["run_status"],
          suppressFlash: true,
        });
      }

      //
      setOnlineProfiling((st) => ({
        ...st,
        inProgress: false,
        progressPercenatge: 0,
        jobId: 0,
        nodeIds: [],
      }));

      onCancel();
    },
    [gridRef, propsOnCancelProfiling]
  );

  const onCancelProfilingClick = useCallback((): void => {
    onOpenModal({
      modalId: "cancel_profiling_modal",
      visible: true,
      modalTitle: nodeType === "RLS" ? "Cancel Run" : "Cancel Profiling",
      modalProps: {
        type: nodeType,
        numberOfNodesSelected: onlineProfiling?.nodeIds?.length || 0,
        jobId: onlineProfiling?.jobId,
        onCancelNodeProfilingSuccess,
      } as CancelProfilingModalProps,
    });
  }, [nodeType, nodeIds, onlineProfiling]);

  const isProfilingInProgress = onlineProfiling?.inProgress;
  const isProfilingFailed = onlineProfiling?.isFailed;

  return {
    onlineProfiling,
    onClickRunProfileOnline,
    onClickRunProfileOffline,
    onCancelProfilingClick,
    isProfilingInProgress,
    isProfilingFailed,
  };
};

export default useNodeProfiling;
