import { ExcelExportParams, GridApi } from "ag-grid-community";
import { useCallback, useState } from "react";

import { CloneRuleSummaryModalGridColumnsType } from "./clonerulesummarymodalgrid.type";

import { CloneRuleSummaryModalGridStyled } from "./clonerulesummarymodalgrid.styles";

import {
  RuleNameRenderer,
  RuleStatusRenderer,
  RulesGridColumns,
} from "./renderer";

import StateHandler from "../../../../../statehandler/statehandler";
import Linkbutton from "../../../../../linkbutton";

import AgGridTable from "../../../../../aggridtable/aggridtable";
import { downloadIcon } from "../../../../../../svgs";
import { getCurrentTime } from "../../../../../../utils/getcurrenttime";

const CloneRuleSummaryModalGrid = ({
  data,
}: {
  data: CloneRuleSummaryModalGridColumnsType[];
}): JSX.Element => {
  const [gridApi, setGridApi] = useState<GridApi>();

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
    },
    [gridApi]
  );

  const getParams: () => ExcelExportParams = () => {
    return {
      fileName: `CloneRuleSummary_${getCurrentTime()}`,
    };
  };

  const onDownloadSummary = useCallback(() => {
    gridApi?.exportDataAsExcel(getParams());
  }, [gridApi, getParams]);

  return (
    <StateHandler
      isFetching={false}
      error={false}
      apiKey="get_all_user_roles_for_role_page"
    >
      <div className="data-grid">
        <CloneRuleSummaryModalGridStyled>
          <div className="summary-download-btn">
            <Linkbutton onClick={onDownloadSummary}>
              {downloadIcon} Download Summary
            </Linkbutton>
          </div>
          <AgGridTable<CloneRuleSummaryModalGridColumnsType>
            onGridReady={onGridReady}
            theme="ag-theme-alpine table-container"
            isTableWidthfitTowindowSize={false}
            rowData={data}
            defaultColDef={{
              resizable: false,
              lockPosition: true,
            }}
            frameworkComponents={{
              RuleNameRenderer,
              RuleStatusRenderer,
            }}
            rowHeight={43}
            headerHeight={43}
            maxHeight="calc(100vh - 326px)"
            domLayout="autoHeight"
            pageSizes={["5", "10", "20", "50"]}
            tableColumns={RulesGridColumns}
            suppressCellSelection
            suppressPaginationPanel
            cellRenderers={{
              ruleName: { render: "RuleNameRenderer" },
              clonedRuleName: {
                render: "RuleNameRenderer",
                params: { isClonedRule: true },
              },
              status: { render: "RuleStatusRenderer" },
            }}
          />
        </CloneRuleSummaryModalGridStyled>
      </div>
    </StateHandler>
  );
};

export default CloneRuleSummaryModalGrid;
