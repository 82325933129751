import styled from "styled-components";

export const AgentConfigViewStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .col {
    width: 45%;
  }
`;
