import { useState, useEffect } from "react";

interface UseTabFocusReturn {
  isTabFocused: boolean;
}

export const useTabFocus = (): UseTabFocusReturn => {
  const [isTabFocused, setIsTabFocused] = useState<boolean>(
    document?.hasFocus()
  );

  useEffect(() => {
    // Handler for when the tab gains focus
    const handleFocus = (): void => {
      setIsTabFocused(true);
    };

    // Handler for when the tab loses focus
    const handleBlur = (): void => {
      setIsTabFocused(false);
    };

    // Add event listeners
    window?.addEventListener("focus", handleFocus);
    window?.addEventListener("blur", handleBlur);

    // Cleanup function to remove event listeners
    return (): void => {
      window?.removeEventListener("focus", handleFocus);
      window?.removeEventListener("blur", handleBlur);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return { isTabFocused };
};

export default useTabFocus;
