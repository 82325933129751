import { useCallback } from "react";
import { useSearchFieldContext } from "../../contexts";
import { searchIcon1, thinCrossIcon } from "../../svgs";

import { InputStyled } from "../inputs/inputs.styles";
import LinkButton from "../linkbutton";

import { SearchFieldStyled } from "./searchfield.styles";

const SearchField = ({
  autoFocus = true,
}: {
  autoFocus?: boolean;
}): JSX.Element => {
  const {
    searchText,
    onChangeSearchText,
    onClearSearchText,
  } = useSearchFieldContext();

  const onChange = useCallback(
    (event) => {
      onChangeSearchText(event?.target?.value);
    },
    [onChangeSearchText]
  );

  return (
    <SearchFieldStyled>
      <InputStyled
        prefix={searchIcon1()}
        suffix={
          searchText ? (
            <LinkButton onClick={onClearSearchText}>
              {thinCrossIcon()}
            </LinkButton>
          ) : null
        }
        width="340px"
        value={searchText}
        onChange={onChange}
        autoFocus={autoFocus}
        placeholder="Search..."
      />
    </SearchFieldStyled>
  );
};

export default SearchField;
