export const PANELS_HEADINGS = {
  available_tables: "Available Tables",
  tables_for_chat: "Tables For Chat",
};

export const SHOW_RELATED_DATASETS_BTN_TEXT = "Show related datasets only";

export const NO_SEARCH_RESULTS = "Your search produced no results";

export const NO_SCHEMAS = "No data exist";
