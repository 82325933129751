import { useCallback } from "react";
import { useHistory } from "react-router";

//      <---Components--->
import CoreNodesCircleIcon from "../corenodetypescircleicon";
import Flex from "../flex";

import LinkButton from "../linkbutton";
//      <---Components--->

//      <---Custom Hooks--->
import { useGetAppType, useOpenDrawer } from "../../customhooks";
//      <---Custom Hooks--->

//      <---SVGS--->
import { longDownArrow } from "./lineagepreviewer.svgs";
//      <---SVGS--->

//      <---Styles--->
import {
  LineagePreviewerStyled,
  LineageSingleSecStyled,
  LineageSingleSecWrapperStyled,
} from "./lineagepreviewer.styles";
//      <---Styles--->

//      <---Styles--->
import {
  getDetailpageUrlBasedOnNodeType,
  getDrawerIdBasedOnNodeType,
} from "../../utils";
//      <---Styles--->

//      <---Styles--->
import { LineagePreviewerProps } from "./lineagepreviewer.types";
import StyledLink from "../styledlink/styledlink";
import ConditionalWrapper from "../conditionalwrapper";
//      <---Styles--->

const keysOfData: Array<keyof LineagePreviewerProps["data"]> = [
  "level1",
  "level2",
  "level3",
  "level4",
  "level5",
];

function LineagePreviewer(props: LineagePreviewerProps): JSX.Element {
  const { data, isRefView, align, marginBottom } = props;
  const history = useHistory();

  const openDrawer = useOpenDrawer();
  const { isExtOrDesktop } = useGetAppType();

  const filteredKeys = keysOfData?.filter((item) => data?.[item]?.length);

  const getNodeUrl = useCallback((id, nodeType, nodeSubType) => {
    return getDetailpageUrlBasedOnNodeType(id, nodeType, nodeSubType);
  }, []);

  const openDrawerBasedOnNodeType = useCallback((id, nodeType, nodeSubType) => {
    const drawerId = getDrawerIdBasedOnNodeType(nodeType, nodeSubType);
    if (drawerId) {
      openDrawer({
        drawerId,
        visible: true,
        drawerProps: { id },
      });
    }
  }, []);

  return (
    <LineagePreviewerStyled
      align={align}
      className="lineage-previewer"
      marginBottom={marginBottom}
    >
      {filteredKeys?.map((item, index) => {
        const isCurrentNode = data?.[item]?.some((node) => node?.isCurrentNode);

        return (
          <LineageSingleSecWrapperStyled key={`lineage-prviewer-sec${item}`}>
            <LineageSingleSecStyled isCurrent={isCurrentNode}>
              {data?.[item]?.map((nestItem) => {
                const { id = "", nodeName = "", nodeType, nodeSubType } =
                  nestItem || {};
                return (
                  <Flex
                    columnGap={5}
                    key={`${id}:${nodeName}:${nodeSubType}:${nodeType}`}
                  >
                    <div>
                      <CoreNodesCircleIcon
                        nodeType={nodeType}
                        nodeSubType={nodeSubType}
                        width="18px"
                        height="18px"
                        mode={isCurrentNode ? "secondary" : "primary"}
                        fontSize={nodeType === "DSF" ? "8px" : "10px"}
                      />
                    </div>

                    <div title={nodeName} className="current-node">
                      <ConditionalWrapper
                        condition={!isCurrentNode}
                        wrapper={(child): JSX.Element => (
                          <StyledLink
                            title={nodeName}
                            to={
                              isRefView
                                ? getNodeUrl(id, nodeType, nodeSubType)
                                : ""
                            }
                            cutomClassName="lineage-node-link"
                            {...(!isRefView && {
                              propsOnClick: (): void =>
                                openDrawerBasedOnNodeType(
                                  id,
                                  nodeType,
                                  nodeSubType
                                ),
                            })}
                            target={isExtOrDesktop ? "_blank" : "_self"}
                          >
                            {child}
                          </StyledLink>
                        )}
                      >
                        <span> {nodeName}</span>
                      </ConditionalWrapper>
                    </div>
                  </Flex>
                );
              })}
            </LineageSingleSecStyled>
            {index + 1 !== filteredKeys?.length && longDownArrow}
          </LineageSingleSecWrapperStyled>
        );
      })}
    </LineagePreviewerStyled>
  );
}

export default LineagePreviewer;
