export type ReferenceDictionariesResponseType = {
  DICT_ID: number;
  DICT_NAME: string;
  DICT_DESC: string;
  IS_HIERARCHICAL: boolean;
  DICT_HIER_LEVEL: number;
  DICT_MAPPING_CONFIG: string;
  CREATED_BY_ID: number;
  CREATED_BY: string;
  CREATED_ON: string;
  UPDATED_BY_ID: number;
  UPDATED_BY: string;
  UPDATED_ON: string;
  ACCOUNT_ID?: number;
  IS_KEY_VALUE_PAIR: boolean;
};

export type ReferenceDictionariesParserReturnType = {
  id: number;
  name: string;
  description: string;
  isHierarchical: boolean;
  hierarchicalLevel?: number;
  mappingConfig: {};
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  isKeyValuePair: boolean;
  dataHeaders?: boolean;
};

export type ValuesSectionGridDataType = Array<{
  [key: string]: string | number;
}>;

export type ReferenceDictionariesValuesParserReturnType = {
  gridData: Array<{ [key: string]: string | number }>;
  parsedData?: string[][];
  content?: string;
};

export type ReferenceDictionaryParserReturnType = {
  id: number;
  name: string;
  description: string;
  isHierarchical: boolean;
  hierarchicalLevel: number;
  mappingConfig: string;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  isKeyValuePair: boolean;
  dataHeaders: boolean;
};

export const DELIMITERS = [",", "\n"];
