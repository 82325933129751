// <--- Library Imports Start --->
import { AxiosResponse } from "axios";
// <--- Library Imports End --->

// <--- Types Start --->
import { NodeType } from "../../app.types";
import { ScoreType } from "../../components/popularityscore/popularityscore.types";

import {
  RetuntypeOfEnterpriseSearchResults,
  EnterpriseSearchResultsResponseType,
  CommonItemsOfEnterpriseSearchResults,
} from "./enterprisesearch.parser.types";

import { UserType } from "../analysisparser/analysisparser.types";
// <--- Types End --->

// <--- Utils Start --->
import { getProfilingInfo, removeDotfromTagName } from "../../utils";
import { getPopularityScoreData } from "../parserutils/getpopularityscoredata";
import { DATA_SOURCE_SECTION_TITLES } from "../../constants";
// <--- Utils End --->

const {
  managed_by: MANAGED_BY,
  classification: CLASSIFICATION,
  overview: OVERVIEW,
} = DATA_SOURCE_SECTION_TITLES;

const { data_owners: DATA_OWNERS } = MANAGED_BY;
const { functional_area: FUNCTIONAL_AREA } = CLASSIFICATION;
const {
  last_refreshed_on: LAST_REFRESHED_ON,
  last_updated_on: LAST_UPDATED_ON,
  node_url: NODE_URL,
} = OVERVIEW;

const transformToNodeData = (
  nodeType: NodeType,
  data: EnterpriseSearchResultsResponseType["results"]
): RetuntypeOfEnterpriseSearchResults["results"] => {
  const commonParsing = (
    item: EnterpriseSearchResultsResponseType["results"][number]
  ): CommonItemsOfEnterpriseSearchResults => ({
    id: item?.node_id,
    isSelected: false,
    description: item.description,
    nodeTitle: item?.node_title || "",
    status: item.endorsed_status ? "active" : "",
    publishStatusId: item?.published_status_id,
    publishStatus: item?.published_status || "",
    nodeType,
    nodeSubType: item?.node_sub_type,
    tagsetsTags: (item?.tags || [])?.map((item) => ({
      tagsetTags:
        item?.value
          ?.filter((item) => item?.is_prominent)
          ?.map((nestItem) => ({
            name: removeDotfromTagName(nestItem?.tag_name || ""),
            id: nestItem?.tag_id || "",
            type: nestItem?.color_code || "RED",
            tagDetails: {
              tagName: removeDotfromTagName(nestItem?.tag_name || ""),
              tagsetId: "",
              tagsetName: "",
              tagNote: nestItem?.tag_comments || "",
              tagsetDesc: nestItem?.tag_desc || "",
              updatedBy: nestItem?.tag_created_by || "",
              updatedOn: nestItem?.tag_created_on || "",
            },
          })) || [],
      tagsetId: `${item?.tagset_id || ""}`,
    })),
  });

  switch (nodeType) {
    case "COL": {
      return data?.map((item) => {
        const {
          blanks_count: blankCount = 0,
          distinct_count: distinctCount = 0,
          record_count: recordCount = 0,
        } = item?.profiling_info?.count || {};

        return {
          ...commonParsing(item),
          sourceName: item.source_name,
          tableSource: item.table_name,
          tableId: `${item?.parent_id || ""}`,
          sourceId: `${item?.source_id || ""}`,
          columnSource: item?.column_name,
          nodeTitle: item?.node_title || "",
          example: item?.example,
          title: item?.physical_name || "",
          profilingInfo: getProfilingInfo(
            recordCount,
            distinctCount,
            blankCount
          ),
          metaItems: [
            {
              label: "Data Type",
              value: item?.data_type,
            },
            {
              label: "Profile",
              value: "",
            },
            {
              id: `${item?.entity_id || ""}`,
              label: "Entity",
              value: item?.entity_name || "",
            },
          ],
        };
      });
    }
    case "TRM": {
      return data?.map((item) => {
        return {
          ...commonParsing(item),
          category: item?.category_name,
          domain: item?.domain_name,
          title: item?.term_name,
          metaItems: [
            {
              label: "Steward Name",
              value: item?.data_steward_name,
            },
            {
              label: "Last Updated On",
              value: item?.updated_on,
            },
          ],
        };
      });
    }
    case "DSR":
      return data?.map((item) => {
        return {
          ...commonParsing(item),
          tableSource: item?.parent_name,
          tableSourceId: `${item?.parent_id || ""}`,
          title: item?.table_name,
          nodeUrl: item?.node_url || "",
          metaItems: [
            {
              id: `${item?.entity_id || ""}`,
              label: FUNCTIONAL_AREA,
              value: item?.functional_area_name || "",
            },
            {
              label: LAST_REFRESHED_ON,
              value: item?.last_refreshed_on,
            },
            {
              label: LAST_UPDATED_ON,
              value: item?.updated_on,
            },
            {
              label: DATA_OWNERS,
              value: item?.data_owner_name || "--",
            },
            {
              label: NODE_URL,
              value: item?.node_url || "--",
            },
          ],
        };
      });
    case "ANL":
      return data?.map((item) => {
        const users: UserType[] = item?.analysis_users || [];

        return {
          ...commonParsing(item),
          nodeTitle: item?.node_name || "",
          title: "",
          shareLevelId: item?.da_access?.da_share_level_id || "NON",
          authorEmail: item?.author_email || "",
          srcTypeId: item?.src_type_id,
          analysisUsers:
            users?.map((user) => ({
              email: user?.USER_EMAIL || "",
              accessType: user?.NODE_REL_TYPE || "",
              type: user?.USER_TYPE || "USR",
              id: `${user?.USER_ID || ""}`,
            })) || [],
          metaItems: [
            {
              label: "Created By",
              value: item?.created_by || "",
            },
            {
              label: "Last Refreshed On",
              value: item?.last_refreshed_on || "",
            },
            {
              label: "Questions Asked",
              value: `${item?.da_queries_cnt || ""}`,
            },
            {
              label: "Datasource Used",
              value: `${item?.category_name || ""}`,
            },
            ...((item?.src_type_id === "RST" &&
              item?.node_sub_type !== "TOL" && [
                {
                  label: "Tools Used",
                  value: [],
                },
              ]) ||
              []),

            ...((item?.src_type_id !== "RST" && [
              {
                label: "Tables Used",
                value:
                  item?.tables?.map((item) => item?.anl_tbl_name || "") || [],
              },
            ]) ||
              []),
          ],
        };
      });
    case "RLS":
      return data?.map((item) => {
        return {
          ...commonParsing(item),
          nodeTitle: item?.node_name || "",
          title: "",
          description: item?.node_desc || "",
          sourceName: item.source_name,
          tableSource: item.table_name,
          tableId: `${item?.table_id || ""}`,
          sourceId: `${item?.source_id || ""}`,
          alertStatusId: item?.rule_exec_info?.alert_status_id,
          isRuleScheduled: !!item?.scheduled_jobs?.length,
          columns:
            item?.cols_info?.map((col) => ({
              columnName: col?.col_name || "",
            })) || [],
          metaItems: [
            {
              label: "Rule Type",
              value: item?.rule_type || "",
            },

            {
              label: "Run Status",
              value: `${item?.rule_status_id || ""}`,
            },
            {
              label: "Last Refreshed On",
              value: item?.rule_exec_info?.rule_exec_on || "",
            },
          ],
        };
      });
    default: {
      return data?.map((item) => {
        return {
          ...commonParsing(item),
          tableSource: item?.source_name,
          tableSourceId: `${item?.parent_id || ""}`,
          title: item?.table_name,
          metaItems: [
            {
              id: `${item?.entity_id || ""}`,
              label: "Data Domain",
              value: item?.functional_area_name || "",
            },
            {
              label: "Last Updated On",
              value: item?.last_refreshed_on,
            },
            {
              label: "Record Count",
              value: item?.record_count,
            },
          ],
          popularityScore: getPopularityScoreData(
            item?.tbl_popularity_id as ScoreType,
            item?.tbl_popularity,
            item?.tbl_popularity_info
          ),
        };
      });
    }
  }
};

export function enterpriseSearchResultsParser({
  data,
}: AxiosResponse<EnterpriseSearchResultsResponseType>): RetuntypeOfEnterpriseSearchResults {
  return {
    total: data?.total,
    results: data?.results?.length
      ? transformToNodeData(data?.results?.[0]?.node_type, data?.results)
      : [],
  };
}
