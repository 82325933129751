import moment from "moment";

import { utcTOLocalTimeZone } from "../../utils";
import {
  JobDocumentDefConfigType,
  JobScheduleInfoType,
} from "./jobparser.types";

export function calculateTimeDifference(start: string, end: string): string {
  // Parse the datetime strings into Date objects
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Calculate the difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Calculate the difference in hours, minutes, and seconds
  const totalSeconds = timeDifference / 1000;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = (totalSeconds % 60).toFixed(0);

  // Format the difference as a string
  const differenceStr = `${hours > 0 ? `${hours} hours,` : ""} ${
    minutes > 0 ? `${minutes} minutes,` : ""
  }  ${seconds ? `${seconds} seconds` : ""}`;

  return differenceStr;
}

export const getJobScheduleInfo = (
  jobConfig: JobDocumentDefConfigType
): JobScheduleInfoType => {
  const activationTime = jobConfig?.activation_date || "";

  const activationDte = `${activationTime}`;

  const date = moment(activationDte);

  const isDateOfDST = date?.isDST(); // Check if it's in DST

  const isActivationDateEndsWithZ = activationDte?.toLowerCase()?.endsWith("z");
  const transformedActDate = isActivationDateEndsWithZ
    ? activationDte?.slice(0, -1)
    : activationDte;

  const startOnTimeForView = transformedActDate
    ? new Date(
        utcTOLocalTimeZone(transformedActDate as string, "", false, isDateOfDST)
      )
    : new Date();

  const startOnTimeForEdit = activationDte
    ? new Date(activationDte)
    : new Date();

  return {
    frequencyType: jobConfig?.freq_type || "DLY",
    activationDate: jobConfig?.activation_date
      ? new Date(jobConfig?.activation_date)
      : new Date(),
    daysOfMonth: jobConfig?.days_of_month || "DOM",
    daysOfWeek: jobConfig?.days_of_week || [],
    endAfterRepeatingXTimes: jobConfig?.ends_after_repeating_x_times || "",
    endsOnDate: jobConfig?.ends_on_date
      ? new Date(jobConfig?.ends_on_date || "")
      : new Date(),
    interval: jobConfig?.recur_interval || "",
    isRepeat: jobConfig?.is_repeat || false,
    repeatAfterXMinutes: jobConfig?.repeat_after_x_minutes || "",
    repeatForXHours: jobConfig?.repeat_for_x_hours || "",
    scheduleType: jobConfig?.job_type || "SCH",
    ends: jobConfig?.ends_after_repeating_x_times
      ? "after"
      : jobConfig?.ends_on_date
      ? "on"
      : "never",
    startOnTime: startOnTimeForView,
    startOnTimeEdit: startOnTimeForEdit,
  };
};
