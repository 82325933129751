// <--- Constants Start --->
import { LOCAL_STORAGE_CONSTANTS } from "../constants";
// <--- Constants End --->

// <--- Types Start --->
import { ShareLevelId } from "../components/sharelevelicon/sharelevelicon.types";
// <--- Types End --->

// <--- Utils Start --->
import { AnalysisNodeSubType, AnalysisUserType } from "../parsers";
import { getPostLoginData } from "./getpostlogindata";
import { NodeSubType } from "../app.types";
// <--- Utils End --->

export const isAnalysisDetailPageAccessible = (params: {
  authorEmail: string;
  sharedWithUsers: AnalysisUserType;
  shareLevelId: ShareLevelId;
  nodeSubType: AnalysisNodeSubType | NodeSubType;
}): boolean => {
  const { authorEmail, sharedWithUsers, shareLevelId, nodeSubType } = params;

  const { user_info: userInfo = {} } = getPostLoginData();
  const isTool = nodeSubType === "TOL";

  const loggedInUserEmail =
    localStorage?.getItem(LOCAL_STORAGE_CONSTANTS?.email) || "";

  const isAuthorLoggedIn =
    authorEmail?.toLocaleLowerCase() === loggedInUserEmail?.toLocaleLowerCase();

  const isUserExistsInList = sharedWithUsers?.find(
    (item) =>
      item?.email?.toLocaleLowerCase() ===
      loggedInUserEmail?.toLocaleLowerCase()
  );

  const isSharedUserHasEditAccess = sharedWithUsers?.find(
    (item) =>
      item?.email?.toLocaleLowerCase() ===
        loggedInUserEmail?.toLocaleLowerCase() &&
      item?.accessType === "USER_EDITOR"
  );

  const isSharedUserHasViewAccess =
    !!sharedWithUsers?.find(
      (item) =>
        item?.email?.toLocaleLowerCase() ===
          loggedInUserEmail?.toLocaleLowerCase() &&
        item?.accessType === "USER_VIEWER"
    ) && isTool;

  const isUserExistsInSharedGroupWithViewAccess =
    !!sharedWithUsers
      ?.filter(
        (item) => item?.type === "UGP" && item?.accessType === "USER_VIEWER"
      )
      ?.map((item) => item?.id)
      ?.includes(`${userInfo?.user_group_id}`) && isTool;

  const isUserExistsInSharedGroup = sharedWithUsers
    ?.filter(
      (item) => item?.type === "UGP" && item?.accessType === "USER_EDITOR"
    )
    ?.map((item) => item?.id)
    ?.includes(`${userInfo?.user_group_id}`);

  const isAccessibleToLimited =
    isAuthorLoggedIn ||
    !!isSharedUserHasEditAccess ||
    (isUserExistsInSharedGroup && !isUserExistsInList) ||
    !!isSharedUserHasViewAccess ||
    (isUserExistsInSharedGroupWithViewAccess && !isUserExistsInList);

  switch (shareLevelId) {
    case "NON":
      return isAuthorLoggedIn;
    case "LIM":
      return isAccessibleToLimited;
    case "ALL":
    default:
      return true;
  }
};
