import styled from "styled-components";

export const S3ConfigurationModalStyled = styled.div`
  .form-body {
    padding: 50px 0px 70px;
    max-height: calc(100vh - 225px);
    overflow: auto;
  }

  .form-actions-sec {
    .ant-form-item-control-input-content {
      column-gap: 8px;
    }
  }

  .ant-alert-content {
    max-width: 588px;
    width: 100%;
  }
`;
