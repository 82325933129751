// <---  Library Imports Start --->
import { Button } from "antd";
import styled from "styled-components";
// <---  Library Imports End --->

// <---  Constants Start --->
import { ellipsis } from "../../styles/app.styles";
// <---  Constants End --->

// <---  Types Start --->
import { LinkButtonProps, LinkiButtonId } from "./linkbutton.types";
// <---  Types End --->

const colorMapping = (id: LinkiButtonId, theme: any): string => {
  const colors: { [key in LinkiButtonId]: string } = {
    link: theme?.textColors?.WHITE_COLOR,
    primary: theme?.textColors?.DARK_BLUE,
    secondary: theme?.textColors?.BLUE_116,
    light_blue: theme?.textColors?.BLUE_56,
    danger: theme?.textColors?.RED_100,
  };
  return colors[id];
};

export const LinkButtonStyled = styled(Button)<
  LinkButtonProps & { btnType?: LinkiButtonId }
>`
  padding: 0px;
  width: ${({ width }): string => width || "unset"};
  height: ${({ height }): string => height || "unset"};
  min-width: ${({ minWidth }): string => minWidth || ""};
  max-width: ${({ maxWidth }): string => maxWidth || ""};
  line-height: unset;
  border: none;
  box-shadow: none;
  color: ${({ theme, btnType }): string =>
    colorMapping(btnType || "primary", theme)};
  font-size: ${(props): any => props?.fontSize};
  border-radius: ${(props): any => props?.isBorderRadius && "50%"};
  background: transparent;
  margin-right: ${(props): any => props?.marginRight};
  padding-right: ${(props): any => props?.paddingRight};
  margin-left: ${(props): any => props?.marginLeft};
  font-family: ${(props): any => props?.fontFamily};
  display: ${({ displayNone, sufixIcon }): any =>
    displayNone ? "none" : sufixIcon && "inline-flex"};
  align-items: ${({ sufixIcon }): string =>
    sufixIcon ? "baseline" : "center"};
  column-gap: 3px;

  ${({ showEllipsis, width }): string =>
    showEllipsis
      ? `
      display: flex;
      align-items: center;
      width: ${width || "100%"};
      .flex-container{
        display:flex;
        align-items:center;
        min-width:0px;

        > span {
          ${ellipsis};
        };
      }
  `
      : ""};

  :hover,
  :focus,
  :active {
    box-shadow: none;
    color: ${({ theme, btnType }): string =>
      colorMapping(btnType || "primary", theme)};
    background: transparent;
  }

  span {
    text-decoration: ${(props): any => props?.isTextUnderLine && "underline"};
  }

  :disabled {
    color: ${({ theme, btnType }): string =>
      colorMapping(btnType || "primary", theme)};
    opacity: 0.5;
    :hover {
      color: ${({ theme, btnType }): string =>
        colorMapping(btnType || "primary", theme)};
    }
  }

  ::after {
    animation: unset;
    transition: unset;
  }
`;
