// <--- Library Imports Start --->
import { Tooltip } from "antd";
import { useCallback } from "react";
// <--- Library Imports End --->

// <--- Components Start --->
import LinkButton from "../../../../../../components/linkbutton";

import TagList from "../../../../../../components/tags/taglist/taglist";
// <--- Components End --->

// <--- Constants Start --->
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../../../../constants";

import {
  OPENED_ANALYSIS_TOOLTIP_TEXT,
  OPEN_ANALYSIS_TOOLTIP_TEXT,
} from "./analysisdetailpageheaderleftsec.constants";

import { DEFAULT_ANALYSIS_TITLE } from "../../../../analysisdetalpage.constants";
// <--- Constants End --->

// <--- Styles Start --->
import { VerticalDividerStyled } from "../../../../../../components/dividers/dividers.styles";
import { AnalysisDetailPageheaderLeftSecStyled } from "./analysisdetailpageheaderleftsec.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import {
  editPencilIcon,
  globalIcon,
  infoIcon,
  lockSolidIcon,
  userGroupsIcon,
} from "../../../../../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { AnalysisDetailPageheaderLeftSecProps } from "./analysisdetailpageheaderleftsec.types";
import { useOpenModal } from "../../../../../../customhooks";
import { SSASaveAnalysisModalProps } from "../../../../../../components/modalwrapper/modals/ssasaveanalysismodal/ssasaveanalysismodal.types";
import ShowMoreOrLessText from "../../../../../../components/showmoreorlesstext/showmoreorlesstext";
import { ToggleEyeIcon } from "../../../../../../components";
import { getDataSourceLogoIconByType } from "../../../../../../utils";
import CoreNodesCircleIcon from "../../../../../../components/corenodetypescircleicon/corenodetypescircleicon";
import { NodeSubType } from "../../../../../../app.types";
// <--- Types End --->

const AnalysisDetailPageheaderLeftSec = (
  props: AnalysisDetailPageheaderLeftSecProps
): JSX.Element => {
  const {
    isEditMode = false,
    analysisHeaderData,
    showInfoSection = "",
    onEditOverviewFormSubmit,
    handleOverviewOpenButtonClick,
    isAnalysisTitleExists,
    showGradient,
    analysisNodeSubType,
  } = props;

  const {
    title = "",
    desc = "",
    createdBy = { name: "", email: "" },
    tablesList = [],
    lastRefreshed = "",
    totalQueries = 0,
    shareType = "NON",
    datasourceUsed,
    exclusionCriteriaExists,
    analysisId = "",
    toolsList,
  } = analysisHeaderData || {};

  const onOpenModal = useOpenModal();

  const onSubmit = useCallback(
    ({ title, desc }: { title: string; desc: string }) => {
      onEditOverviewFormSubmit({ title, desc });
    },
    [onEditOverviewFormSubmit]
  );

  const handleEditClick = useCallback(() => {
    onOpenModal({
      modalId: "ssa_save_analysis_modal",
      visible: true,
      modalTitle: "Edit",
      modalProps: {
        title: isAnalysisTitleExists ? title : "",
        desc,
        onSaveDoneClick: onSubmit,
      } as SSASaveAnalysisModalProps,
    });
  }, [title, desc, isAnalysisTitleExists, onSubmit]);

  const isTool = analysisNodeSubType === "TOL";

  return (
    <AnalysisDetailPageheaderLeftSecStyled data-testid="analysis-detail-page-header-left-sec-main-wrapper">
      <>
        {showGradient && <div className="gradient-div" />}
        <div
          className="heading-sec"
          data-testid="analysis-detail-page-header-left-sec-heading-sec"
        >
          <CoreNodesCircleIcon
            nodeType="ANL"
            nodeSubType={analysisNodeSubType as NodeSubType}
            fontSize="12px"
          />
          <div
            className="title"
            data-testid="analysis-detail-page-header-left-sec-title-text"
          >
            {title || DEFAULT_ANALYSIS_TITLE}
          </div>

          {isEditMode ? (
            <LinkButton
              onClick={handleEditClick}
              tooltipProps={{
                title: "Edit Overview",
                placement: "bottom",
              }}
              data-testid="analysis-detail-page-header-left-sec-edit-btn"
            >
              {editPencilIcon("11", "11")}
            </LinkButton>
          ) : (
            <div
              className="heading-icons-sec"
              data-testid="analysis-detail-page-header-left-sec-heading-icons-sec"
            >
              <div
                className="share-type-icon"
                data-testid="analysis-detail-page-header-left-sec-share-icons-sec"
              >
                {shareType === "NON" && (
                  <Tooltip
                    title="Not shared with anyone"
                    placement="bottom"
                    overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                    data-testid="analysis-detail-page-header-left-sec-lock-icon"
                  >
                    <span data-testId="private-analysis-icon">
                      {lockSolidIcon("", "13.04")}
                    </span>
                  </Tooltip>
                )}
                {shareType === "LIM" && (
                  <Tooltip
                    title="Shared with a group of people"
                    placement="bottom"
                    overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                    data-testid="analysis-detail-page-header-left-sec-user-group-icon"
                  >
                    <span data-testId="shared-with-group-analysis-icon">
                      {userGroupsIcon("", "13.04")}
                    </span>
                  </Tooltip>
                )}
                {shareType === "ALL" && (
                  <Tooltip
                    title="Shared with everyone"
                    placement="bottom"
                    overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                    data-testid="analysis-detail-page-header-left-sec-global-icon"
                  >
                    <span data-testId="shared-with-every-one-analysis-icon">
                      {globalIcon("", "13.04")}
                    </span>
                  </Tooltip>
                )}
              </div>

              <VerticalDividerStyled height="7px" />
              <Tooltip
                title={
                  showInfoSection === "overview"
                    ? `Open ${isTool ? "Tool" : "Agent"} Overview`
                    : `${isTool ? "Tool" : "Agent"} Overview`
                }
                placement="right"
                overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
              >
                <div
                  className="info-icon-wrapper"
                  role="button"
                  onClick={(): void =>
                    handleOverviewOpenButtonClick("overview")
                  }
                  data-testid="analysis-detail-page-header-left-sec-info-btn"
                >
                  {infoIcon}
                </div>
              </Tooltip>
            </div>
          )}
        </div>
        {desc && <ShowMoreOrLessText text={desc} textLimit={180} />}
        {analysisId && analysisHeaderData && (
          <div
            className="analysis-info-sec"
            data-testid="analysis-detail-page-header-left-sec-info-sec-wrapper"
          >
            <div data-testid="analysis-detail-page-header-left-sec-created-by">
              Created By: {createdBy?.name}
            </div>
            <VerticalDividerStyled height="7px" />

            {!!lastRefreshed && (
              <>
                <div data-testid="analysis-detail-page-header-left-sec-last-refreshed">
                  Last Refreshed: {lastRefreshed}
                </div>
                <VerticalDividerStyled height="7px" />
              </>
            )}

            <div data-testid="analysis-detail-page-header-left-sec-questions-asked">
              {" "}
              Questions Asked: {totalQueries}
            </div>

            {datasourceUsed?.name && (
              <>
                <VerticalDividerStyled height="7px" />
                <div
                  data-testid="analysis-detail-page-header-left-sec-datasource-used"
                  className="datasource-wrapper"
                >
                  Datasource Used:
                  <div className="datasource-details">
                    {getDataSourceLogoIconByType(datasourceUsed?.type || "ADL")}
                    {datasourceUsed?.name}
                  </div>
                </div>
              </>
            )}

            {!!tablesList?.length && (
              <>
                <VerticalDividerStyled height="7px" />
                <div
                  className="tags-wrapper"
                  data-testid="analysis-detail-page-header-left-sec-tags-wrapper"
                >
                  Tables Used: <TagList type="secondory" tags={tablesList} />
                </div>
              </>
            )}

            {!!toolsList?.length && (
              <>
                <VerticalDividerStyled height="7px" />
                <div
                  className="tags-wrapper"
                  data-testid="analysis-detail-page-header-left-sec-tags-wrapper"
                >
                  Tools Used: <TagList type="secondory" tags={toolsList} />
                </div>
              </>
            )}

            {exclusionCriteriaExists && (
              <>
                <VerticalDividerStyled height="7px" />
                <div
                  className="chat-exclusion-criteria-wrapper"
                  data-testid="analysis-detail-page-header-left-sec-tags-wrapper"
                >
                  Chat Exclusion Criteria:{" "}
                  <ToggleEyeIcon
                    tooltipProps={{
                      title:
                        "Show exclusion criteria of the tables and columns for the chat. ",
                      placement: "bottom",
                      overlayClassName: "exclusion-criteria-tooltip",
                    }}
                    filledIcon={showInfoSection === "exclusion-criteria"}
                    onClick={(): void =>
                      handleOverviewOpenButtonClick("exclusion-criteria")
                    }
                  />
                </div>
              </>
            )}
          </div>
        )}
      </>
    </AnalysisDetailPageheaderLeftSecStyled>
  );
};

export default AnalysisDetailPageheaderLeftSec;
