import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { FormItemStyled, FormStyled } from "../../../form";
import { SelectField } from "../../../formfields";

import { SelectAgentforToolModalStyled } from "./selectagentfortoolmodal.styles";
import FormItemLabel from "../../../form/formitemlabel";

import Button from "../../../button";
import {
  useCancelModal,
  useGetAppState,
  useTabFocus,
} from "../../../../customhooks";

import LinkButton from "../../../linkbutton";
import { SelectAgentforToolModalProps } from "./selectagentfortoolmodal.types";

import { useGetchatBots } from "../../../../api/analysisservice";
import {
  getAnalysisDetailPageUrl,
  openNotification,
  selectFilterOption,
} from "../../../../utils";

import Flex from "../../../flex";
import {
  redirectIcon,
  solidGoToIcon,
  thinRedirectIcon,
} from "../../../../svgs";

import StyledLink from "../../../styledlink/styledlink";
import Statehandler from "../../../statehandler";
import SuccessNotificationMessage from "../../../successnotificationmessagerendrer/successnotificationmessagerendrer";

const redirectIconBtn = redirectIcon("10", "10");
const thinRedirectSvg = thinRedirectIcon("8.41", "8.41");

const SelectAgentforToolModal = (): JSX.Element => {
  const { control, setValue, handleSubmit, watch } = useForm();

  const { modal } = useGetAppState();
  const { isTabFocused } = useTabFocus();
  const [isLoading, setIsLoading] = useState(false);

  const modalProps: SelectAgentforToolModalProps =
    (modal?.modalProps as SelectAgentforToolModalProps) || {};

  const { onCreateNewAgentClick, srcId, onAddToolsToAgentClick } = modalProps;

  const onCancel = useCancelModal();

  const {
    parsedData: listOfAgents,
    isLoading: isLoadingAgents,
    refetch,
  } = useGetchatBots(srcId);

  const selectOptions = useMemo(() => {
    return listOfAgents
      ?.filter((item) => item?.node_sub_type !== "TOL")
      ?.map((item) => ({
        label: (
          <Flex justifyContent="space-between">
            <div>{item?.name}</div>
            <LinkButton className="redirect-btn">
              <StyledLink
                to={getAnalysisDetailPageUrl(`${item?.id}`)} // It is always for the agent
                target="_blank"
              >
                {redirectIconBtn}
              </StyledLink>
            </LinkButton>
          </Flex>
        ),
        value: `${item?.id}`,
        labelText: item?.name,
      }));
  }, [listOfAgents]);

  const agents = watch("agents") || [];

  const onSubmit = useCallback(
    (values) => {
      setIsLoading(true);
      const addedAgents = listOfAgents?.filter((item) =>
        values?.agents?.includes(`${item?.id}`)
      );

      onAddToolsToAgentClick(
        addedAgents?.map((item) => ({ id: item?.id, title: item?.name })),
        () => {
          openNotification(
            <SuccessNotificationMessage message="Tool has been added to selected agent(s)" />
          );
          setIsLoading(false);
          onCancel();
        }
      );
    },
    [listOfAgents, onAddToolsToAgentClick]
  );

  useEffect(() => {
    isTabFocused && refetch();
  }, [isTabFocused]);

  return (
    <Statehandler
      isFetching={isLoadingAgents || isLoading}
      isModal
      error={undefined}
    >
      <SelectAgentforToolModalStyled>
        <FormStyled isItemColumnLayout onFinish={handleSubmit(onSubmit) as any}>
          <div className="form-wrapper">
            <div className="details">
              The tool will be used based on the selected agents from the below
              list. If you want to add this tool to a new agent then please{" "}
              <LinkButton
                sufixIcon={solidGoToIcon("", "9px")}
                onClick={onCreateNewAgentClick}
              >
                create a new Agent
              </LinkButton>
            </div>
            <FormItemLabel label="Available Agents" required>
              <SelectField
                options={selectOptions}
                control={control}
                name="agents"
                setValue={setValue}
                showSearch
                filterOption={selectFilterOption}
                mode="multiple"
                isAllowClear={false}
                allowClear
                width="530px"
                placeholder="Select the Agents"
              />
            </FormItemLabel>
          </div>
          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!agents?.length}
            >
              Add
            </Button>
          </FormItemStyled>
        </FormStyled>
      </SelectAgentforToolModalStyled>
    </Statehandler>
  );
};

export default SelectAgentforToolModal;
