import { useCallback } from "react";
import { HeadingStyled } from "../../definitiontab.styles";

import { AgentConfigViewStyled } from "./agentconfigview.styles";

import { AgentConfigViewProps } from "./agentconfigview.types";
import { analysisToolIcon } from "../../../../../../svgs";
import { LimitAccessSec } from "../../definitiontab.components";
import { AgentConfigSec, AgentExplorerSec } from "./agentconfigview.components";
import { getAnalysisDetailPageUrl } from "../../../../../../utils";

const LIMIT_SEC_CONSTANTS = {
  readonlyTitle: "Tools",
  pinnedObjectsName: "tool",
  limitFieldTitle: "Limit to specific Tools",
  limitFieldDescription:
    "By selecting this checkbox, you can limit which tools can be accessed by this Agent. This could be useful when you want to restrict access to specific tools from your available tools.",
};

const AgentConfigView = (props: AgentConfigViewProps): JSX.Element => {
  const {
    sourceId,
    isEditMode,
    isScopeEnabled,
    pinnedToolsList,
    configuration,
    updateConfiguration,
    updatePinnedToolsList,
    onScopeEnabledClick,
    enableSaveButton,
    disableSaveButton,
    aiChatModel,
    aiChatModelName,
    onChangeAIChatModel,
    onCreateNewToolAgentClick,
    toolsList = [],
  } = props;

  const onReadonlyObjectClick = useCallback((id: number) => {
    // It is only for the tool as it the list of tools pinned to an agent
    window.open(
      getAnalysisDetailPageUrl(`${id}`, undefined, undefined, true),
      "_blank"
    );
  }, []);

  return (
    <AgentConfigViewStyled>
      <div className="col">
        <HeadingStyled>Tools</HeadingStyled>
        <LimitAccessSec
          isEditMode={isEditMode}
          pinnedObjectsList={pinnedToolsList}
          pinnedObjectIcon={analysisToolIcon("25", "25")}
          isScopeEnabled={isScopeEnabled}
          pinnedObjectsName={LIMIT_SEC_CONSTANTS?.pinnedObjectsName || ""}
          readonlyTitle={LIMIT_SEC_CONSTANTS?.readonlyTitle || ""}
          limitFieldTitle={LIMIT_SEC_CONSTANTS?.limitFieldTitle || ""}
          limitFieldDescription={
            LIMIT_SEC_CONSTANTS?.limitFieldDescription || ""
          }
          onScopeEnabledClick={onScopeEnabledClick}
          enableSaveButton={enableSaveButton}
          disableSaveButton={disableSaveButton}
          onReadonlyObjectClick={onReadonlyObjectClick}
          explorerSec={
            <AgentExplorerSec
              sourceId={sourceId}
              pinnedToolsList={pinnedToolsList}
              updatePinnedToolsList={updatePinnedToolsList}
              onCreateNewToolAgentClick={onCreateNewToolAgentClick}
            />
          }
        />
      </div>

      <div className="col">
        <HeadingStyled>LLM & Prompt Configuration</HeadingStyled>

        <AgentConfigSec
          isEditMode={isEditMode}
          configuration={configuration}
          updateConfiguration={updateConfiguration}
          aiChatModel={aiChatModel}
          aiChatModelName={aiChatModelName}
          onChangeAIChatModel={onChangeAIChatModel}
          toolsList={toolsList}
          isScopeEnabled={isScopeEnabled}
        />
      </div>
    </AgentConfigViewStyled>
  );
};

export default AgentConfigView;
