import { useCallback, useEffect, useMemo, useState } from "react";
import { Collapse, Menu, Spin } from "antd";

import {
  ExplorerSecListItemStyled,
  AgentExplorerSecStyled,
} from "./agentexplorersec.styles";

import {
  ExplorerSecListItemProps,
  AgentExplorerSecProps,
} from "./agentexplorersec.types";

import { useGetchatBots } from "../../../../../../../../api/analysisservice";

import {
  analysisToolIcon,
  lightCrossIcon,
  minusIcon,
  plusIcon,
  searchIcon,
  thinRedirectIcon,
  tickIconGreen,
} from "../../../../../../../../svgs";

import LinkButton from "../../../../../../../../components/linkbutton";
import {
  getAnalysisDetailPageUrl,
  isAnalysisDetailPageAccessible,
} from "../../../../../../../../utils";
import { Input } from "../../../../../../../../components/inputs";
import {
  useQueryParams,
  useTabFocus,
} from "../../../../../../../../customhooks";
import { AgentExplorerSecEmptystate } from "..";

const PANELS_HEADINGS = {
  available_tools: "Available Tools",
  tools_for_chat: "Tools For Chat",
};

const ExplorerSecListItem = (props: ExplorerSecListItemProps): JSX.Element => {
  const { icon, title, optionalActionsConfig, showSuccessSpan } = props;

  return (
    <ExplorerSecListItemStyled>
      <div className="title-sec" title={title}>
        {icon}

        <div className="title">{title}</div>
      </div>

      {showSuccessSpan ? (
        <div className="success-span">
          <span className="icon">{tickIconGreen()}</span>
          <span className="text">Tool Added for Chat</span>
        </div>
      ) : (
        <div className="action-btns">
          {optionalActionsConfig?.map((actionConfig, index) => (
            <LinkButton
              key={index + actionConfig?.tooltipTitle}
              className={`action-btn ${actionConfig?.className || ""}`}
              onClick={actionConfig?.onClick}
              tooltipProps={{
                title: actionConfig?.tooltipTitle,
                placement: "top",
              }}
            >
              {actionConfig?.icon}
            </LinkButton>
          ))}
        </div>
      )}
    </ExplorerSecListItemStyled>
  );
};

const AgentExplorerSec = (props: AgentExplorerSecProps): JSX.Element => {
  const { isTabFocused } = useTabFocus();
  const queryParams = useQueryParams();

  const isFromAnalysisId = queryParams.get("isFromAnalysisId");

  const {
    sourceId = "",
    pinnedToolsList = [],
    updatePinnedToolsList,
    onCreateNewToolAgentClick,
  } = props;

  const [searchText, setSearchText] = useState("");
  const [successSpanId, setSuccessSpanId] = useState(null);

  const {
    parsedData: chatBotsParsedData = [],
    isLoading: chatBotsIsLoading,
    error: chatBotsError,
    refetch,
  } = useGetchatBots(sourceId);

  const toolsList = useMemo(
    () =>
      chatBotsParsedData?.filter((chatBot) => {
        const {
          author_email: authorEmail = "",
          analysis_users: sharedWithUsers = [],
          shared_level_id: shareLevelId,
          node_sub_type: nodeSubType,
        } = chatBot;

        const isToolAccessible = isAnalysisDetailPageAccessible({
          authorEmail,
          sharedWithUsers,
          shareLevelId,
          nodeSubType,
        });

        return (
          chatBot?.name?.toLowerCase().includes(searchText.toLowerCase()) &&
          chatBot?.node_sub_type === "TOL" &&
          isToolAccessible
        );
      }),
    [chatBotsParsedData, searchText]
  );

  const defaultActivePanelKey = !toolsList?.length
    ? PANELS_HEADINGS?.available_tools
    : isFromAnalysisId
    ? PANELS_HEADINGS?.tools_for_chat
    : undefined;

  const onSearchTextChange = useCallback((e) => {
    setSearchText(e?.target?.value || "");
  }, []);

  const redirectToTool = useCallback((toolId: string): void => {
    // This is always for the tool as it is on the click of available tools in the agent definition tab
    window.open(
      getAnalysisDetailPageUrl(toolId, undefined, undefined, true),
      "_blank"
    );
  }, []);

  const handlePinTool = useCallback(
    (tool, isAdded: boolean) => {
      if (isAdded) {
        setSuccessSpanId(tool?.id);
        setTimeout(() => {
          setSuccessSpanId(null);
        }, 2000);
      }

      updatePinnedToolsList({ ...tool }, isAdded);
    },
    [updatePinnedToolsList]
  );

  useEffect(() => {
    isTabFocused && refetch();
  }, [isTabFocused]);

  return (
    <AgentExplorerSecStyled>
      <Menu
        mode="inline"
        getPopupContainer={(trigger): HTMLElement =>
          trigger.parentNode as HTMLElement
        }
      >
        <Collapse bordered={false} defaultActiveKey={defaultActivePanelKey}>
          <Collapse.Panel
            header={
              <div className="collapse-header-content">
                <div className="collapse-header-content-title">
                  {PANELS_HEADINGS?.tools_for_chat}

                  {!!pinnedToolsList?.length && (
                    <div className="count">{pinnedToolsList?.length}</div>
                  )}
                </div>
              </div>
            }
            key={PANELS_HEADINGS?.tools_for_chat}
          >
            <div className="explorer-collapse-item-content-container">
              {pinnedToolsList?.length ? (
                pinnedToolsList?.map((pinnedTool, index) => (
                  <>
                    <ExplorerSecListItem
                      key={pinnedTool?.id + index}
                      icon={analysisToolIcon("19.2px", "19.2px")}
                      title={pinnedTool?.name}
                      optionalActionsConfig={[
                        {
                          icon: minusIcon("25", "6"),
                          className: "minus-icon",
                          tooltipTitle: "Remove Tool for Chat",
                          onClick: (): void => handlePinTool(pinnedTool, false),
                        },
                        {
                          icon: thinRedirectIcon("11.2", "11.2"),
                          className: "redirect-icon",
                          tooltipTitle: "Open This Tool",
                          onClick: (): void =>
                            redirectToTool(`${pinnedTool?.id}`),
                        },
                      ]}
                    />
                  </>
                ))
              ) : (
                <div className="pinned-objects-blankstate">
                  No Tool is available for chat yet
                </div>
              )}
            </div>
          </Collapse.Panel>

          <Collapse.Panel
            header={
              <div
                className="collapse-header-content"
                data-testid="analysis-detail-page-explorer-sec-table-browser-sec-header"
              >
                {PANELS_HEADINGS?.available_tools}
              </div>
            }
            key={PANELS_HEADINGS?.available_tools}
          >
            <Spin spinning={chatBotsIsLoading}>
              <div className="explorer-collapse-item-content-container">
                {!chatBotsIsLoading && !toolsList?.length ? (
                  <AgentExplorerSecEmptystate
                    onCreateNewToolAgentClick={onCreateNewToolAgentClick}
                  />
                ) : (
                  <div className="available-bots-wrapper">
                    <Input
                      className="table-browser-search"
                      prefix={searchIcon("12.48px")}
                      suffix={
                        searchText ? (
                          <LinkButton onClick={onSearchTextChange}>
                            {lightCrossIcon("9.6px", "9.6px")}
                          </LinkButton>
                        ) : (
                          <div />
                        )
                      }
                      onChange={onSearchTextChange}
                      value={searchText}
                      placeholder="Search"
                    />

                    {toolsList?.map((tool, index) => {
                      const isPinned = pinnedToolsList?.some(
                        (pinnedTool) => pinnedTool?.id === tool?.id
                      );

                      return (
                        <ExplorerSecListItem
                          key={tool?.id + index}
                          icon={analysisToolIcon("19.2px", "19.2px")}
                          title={tool?.name}
                          showSuccessSpan={tool?.id === successSpanId}
                          optionalActionsConfig={[
                            {
                              icon: isPinned
                                ? minusIcon("25", "6")
                                : plusIcon("11.2", "11.2"),
                              className: isPinned ? "minus-icon" : "plus-icon",
                              tooltipTitle: isPinned
                                ? "Remove Tool for Chat"
                                : "Add Tool for Chat",
                              onClick: (): void =>
                                handlePinTool(
                                  { id: tool?.id, name: tool?.name },
                                  !isPinned
                                ),
                            },
                            {
                              icon: thinRedirectIcon("11.2", "11.2"),
                              className: "redirect-icon",
                              tooltipTitle: "Open This Tool",
                              onClick: (): void =>
                                redirectToTool(`${tool?.id || 0}`),
                            },
                          ]}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </Spin>
          </Collapse.Panel>
        </Collapse>
      </Menu>
    </AgentExplorerSecStyled>
  );
};

export default AgentExplorerSec;
