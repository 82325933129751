import { LOCAL_STORAGE_CONSTANTS } from "../constants/localstorageconstants";
import { GridExpandedStateType } from "../pages/listingpage/listingpage.types";

import { jsonParse } from "./jsonparse";

export const getGridsExpandedStateFromLocalStorage = (): GridExpandedStateType => {
  const data =
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS?.user_expanded_grids) || "";

  const parsedData = jsonParse<GridExpandedStateType>(data, true);

  return parsedData;
};
