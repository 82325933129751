import styled from "styled-components";

export const QueryBlockListingBlankSlateStyled = styled.div<{
  isChatPage: boolean;
  isNotConnectedToAgent: boolean;
}>`
  min-height: ${({ isChatPage }): string => (isChatPage ? "380px" : "286px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }): string => theme?.textColors?.GREY_210};
  row-gap: 10px;
  height: ${({ isNotConnectedToAgent }): string =>
    isNotConnectedToAgent ? "435px" : "auto"};

  .blank-slate-msg {
    width: 50%;
    text-align: center;
  }
`;
