// <--- Library Imports Start --->
import { Row } from "antd";
// <--- Library Imports End --->

// <--- Components Start --->
import { AnalysisDetailPageContentSecProps } from "./analysisdetailpagecontentsec.types";
// <--- Components End --->

// <--- Styles Start --->
import { AnalysisDetailPageContentSecStyled } from "./analysisdetailpagecontentsec.styles";
import { checkIsChatPage } from "../../../../utils/ischatpage";

import ChatBotSection from "./analysisdetailpagecontentsec.components/chatbotsection/chatbotsection";
import AnalysisDetailPageQueriesSec from "./anaysisdetailpagequeriessec";
// <--- Styles End --->

import { GatewayStatusCheckingWrapper } from "./analysisdetailpagecontentsec.components";
import { useGetAppState } from "../../../../customhooks";
import { ConditionalDisplayWrapper } from "../../../../components/conditionaldisplay/conditionaldisplay.styles";

const AnalysisDetailPageContentSec = (
  props: AnalysisDetailPageContentSecProps
): JSX.Element => {
  const {
    queriesSec,
    pinnedTablesList = [],
    updatePinnedTablesList,
    pinnedToolsList = [],
    updatePinnedToolsList,
    onAddQuery,
    tableIds,
    analysisSrcTypeId,
    analysisNodeSubType,
    sourceId,
    analysisId = "",
    onSaveDoneClick,
    isAuthor,
    shareType = "NON",
    isAnalysisTitleExists,
    analysisQueryBlocksInfo,
    isAnalysisLoading = false,
    isScopeEnabled,
    onScopeEnabledClick,
    onManageFieldsChange,
    hasManageFieldsChanges,
    onSaveAnalysisButtonClicked,
    analysisError,
    isDataSavedIntoState,
    configuration,
    updateConfiguration,
    aiChatModel = "",
    aiChatModelName = "",
    dataSourceUsed,
    gatewayId = "",
    onCreateNewToolAgentClick,
    toolsList = [],
  } = props;

  const {
    handlers,
    isEditMode,
    hasUserAccessToEdit = false,
    hasUserViewOnlyAccess = false,
  } = queriesSec;

  const {
    onEditClick,
    onDiscardClick,
    onEditOverviewFormSubmit,
    onLeaveTabInEditMode,
    onChangeQuerySettings,
    onChangeAIChatModel,
  } = handlers;

  const isChatPage = checkIsChatPage();

  const { isOnboardingMode } = useGetAppState();

  return (
    <AnalysisDetailPageContentSecStyled
      data-testid="analysis-detail-page-content-sec-main-wrapper"
      isChatPage={isChatPage}
      isQueryPresent={!!queriesSec?.queries?.length}
      isOnboardingMode={isOnboardingMode}
    >
      {!isChatPage ? (
        <Row className="content-sec">
          <ChatBotSection
            gatewayId={gatewayId}
            isAuthor={isAuthor}
            shareType={shareType}
            isEditMode={isEditMode}
            isAnalysisTitleExists={isAnalysisTitleExists}
            handlers={{
              onEditClick,
              onSaveDoneClick,
              onDiscardClick,
              onEditOverviewFormSubmit,
              onLeaveTabInEditMode,
              onManageFieldsChange,
              updatePinnedTablesList,
              updatePinnedToolsList,
              onAddQuery,
              onScopeEnabledClick,
              onSaveAnalysisButtonClicked,
              onChangeQuerySettings,
              updateConfiguration,
              onChangeAIChatModel,
              onCreateNewToolAgentClick,
            }}
            analysisQueryBlocksInfo={analysisQueryBlocksInfo}
            pinnedTablesList={pinnedTablesList}
            pinnedToolsList={pinnedToolsList}
            tableIds={tableIds}
            sourceId={sourceId}
            analysisSrcTypeId={analysisSrcTypeId}
            analysisNodeSubType={analysisNodeSubType}
            queries={queriesSec?.queries}
            trainBotFilteredQueries={queriesSec?.trainBotFilteredQueries}
            analysisId={analysisId}
            trainbotSecProps={props}
            isAnalysisLoading={isAnalysisLoading}
            analysisError={analysisError}
            isDataSavedIntoState={isDataSavedIntoState}
            isScopeEnabled={isScopeEnabled}
            hasUserAccessToEdit={hasUserAccessToEdit}
            hasManageFieldsChanges={hasManageFieldsChanges}
            configuration={configuration}
            aiChatModel={aiChatModel}
            aiChatModelName={aiChatModelName}
            dataSourceUsed={dataSourceUsed}
            toolsList={toolsList}
            hasUserViewOnlyAccess={hasUserViewOnlyAccess}
          />
        </Row>
      ) : (
        <GatewayStatusCheckingWrapper
          gatewayId={gatewayId}
          type="loading"
          height="400px"
        >
          <AnalysisDetailPageQueriesSec {...props} />
        </GatewayStatusCheckingWrapper>
      )}
      <ConditionalDisplayWrapper displayOnScreen={false}>
        <AnalysisDetailPageQueriesSec {...props} isHiddenMode />
      </ConditionalDisplayWrapper>
    </AnalysisDetailPageContentSecStyled>
  );
};

export default AnalysisDetailPageContentSec;
