// <--- Constants Start --->
import { APP_ROUTES } from "../constants";
import {
  ChatbotAnalyticsViewKeys,
  ChatbotPageParamViewKeys,
  chatbotPageTabValue,
} from "../pages/analysisdetailpage/analysisdetailpage.types";

// <--- Constants End --->

export function getAnalysisDetailPageUrl(
  analysisId: string,
  tab?: ChatbotPageParamViewKeys,
  view?: ChatbotAnalyticsViewKeys,
  isTool?: boolean
): string {
  const finalTab =
    tab ||
    (analysisId
      ? chatbotPageTabValue?.[isTool ? "definition" : "train-agent"]
      : chatbotPageTabValue?.definition);

  const finalView = view ? `/${view}` : "";

  return analysisId
    ? `${
        APP_ROUTES.private_routes?.[isTool ? "tool" : "ai_agent"]
      }/${analysisId}/${finalTab}${finalView}`
    : `${
        APP_ROUTES.private_routes?.[isTool ? "tool" : "ai_agent"]
      }/${finalTab}${finalView}`;
}
