import styled from "styled-components";

export const ConfigurationViewStyled = styled.div<{ isEditMode: boolean }>`
  margin-top: 19.2px;
  display: flex;
  flex-direction: column;
  row-gap: 19.2px;
  width: 100%;

  .configuration-form {
    display: grid;
    grid-template-columns: 48% 45%;
    column-gap: 7%;

    .config-title {
      margin-bottom: 15px;
    }

    .ant-form-item-label {
      padding-bottom: 0px;
    }

    .ant-form-item-control {
      width: 100%;
    }

    .readonly-config-margin-top {
      margin-top: 19.2px;
    }

    .readonly-config {
      display: flex;
      flex-direction: column;
      row-gap: 23.2px;

      .config-item-wrapper {
        .config-label {
          font-family: OpenSansSemiBold;
          margin-bottom: 3px;
          color: ${({ theme }): string => theme?.textColors?.BLUE_116};
        }

        .config-value,
        .config-json-value {
          position: relative;
          width: 100%;

          &.config-json-value {
            width: 100%;
            max-height: 800px;
            min-height: 500px;
            overflow: auto;
            padding: 8px 9.6px;
            background: ${({ theme }): string =>
              theme?.bgColors?.LIGHT_GREY_22};
            border: 1px solid ${({ theme }): string => theme?.bgColors?.GREY_18};

            .cong-down-icon {
              background: ${({ theme }): string =>
                theme?.bgColors?.LIGHT_GREY_22};
              position: absolute;
              right: 25px;
              top: 20px;
              font-family: OpenSansSemiBold;

              svg {
                margin-left: 5px;
              }
            }
          }
        }

        .config-value {
          color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
          .show-more-text-main-wrapper {
            white-space: pre-wrap;
            word-break: break-word;
          }
        }
      }
    }

    .selected-chat-agent {
      .label {
        color: ${({ theme, isEditMode }): string =>
          isEditMode
            ? theme?.textColors?.BLACK_COLOR
            : theme?.textColors?.BLUE_116};
      }
    }
  }

  .sec-title {
    font-family: OpenSansSemiBold;
  }

  .subtitle {
    font-size: 12px;
    color: ${({ theme }): string => theme?.textColors?.GREY_26};
    margin-bottom: 5px;
  }

  .selected-chat-agent {
    display: flex;
    flex-direction: column;

    .label {
      font-family: OpenSansSemiBold;
      margin-bottom: 2px;
    }

    .value {
      color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
    }
  }
`;
