import styled from "styled-components";
import { FlexProps } from "./flex.types";

export const FlexStyled = styled.div<FlexProps>`
  display: flex;
  align-items: ${({ alignItems }): string => alignItems || "center"};
  justify-content: ${({ justifyContent }): string => justifyContent || "start"};
  column-gap: ${({ columnGap }): string => (columnGap ? `${columnGap}px` : "")};
  row-gap: ${({ rowGap }): string => (rowGap ? `${rowGap}px` : "")};
  height: ${({ height }): string => height || ""};
  width: ${({ width }): string => width || ""};
  flex-direction: ${({ direction }): string => direction || "row"};
  flex-wrap: ${({ flexWrap }): string => flexWrap || ""};
  min-width: ${({ minWidth }): string => minWidth || ""};
`;
