import styled from "styled-components";

export const InfoMessageStyled = styled.div<{
  width: string;
  marginTop: string;
  marginBottom: string;
}>`
  display: flex;
  align-items: baseline;
  width: ${({ width }): string => width};
  margin-top: ${({ marginTop }): string => marginTop};
  margin-bottom: ${({ marginBottom }): string => marginBottom};
  font-size: 12px;
  border: 1px solid ${({ theme }): string => theme.borderColors.LIGHT_BLUE_103};
  padding: 10px;
  column-gap: 5px;
  color: ${({ theme }): string => theme.textColors.BLACK_13};
  background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_104};

  svg {
    width: 12px;
    height: 12px;
  }
`;
