import styled from "styled-components";
import { PageHeaderProps } from "./pageheader.types";

export const PageHeaderStyled = styled.div<PageHeaderProps>`
  height: ${({ headerHeight }): string => headerHeight || "63px"};
  padding: ${({ padding }): string => padding || "13px 25px 12px 24.2px"};
  /* border-bottom: 1px solid rgba(112, 112, 112, 0.1); */
  background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  display: flex;
  align-items: center;

  .left {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .right {
    display: flex;
    align-items: center;
  }
`;
