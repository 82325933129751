import styled from "styled-components";

const flexCenter = `
  display: flex;
  align-items: center;
`;

export const QueryMenuSecStyled = styled.div`
  .menu-action-sec {
    ${flexCenter};
    column-gap: 4px;

    .ant-spin {
      display: flex;
      padding: 9.4px;
    }

    .refresh-btn {
      svg {
        path {
          fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
        }
      }
    }

    .menu-save-icon {
      svg {
        path {
          stroke: ${({ theme }): string =>
            theme?.textColors?.BLUE_116} !important;
        }
      }
    }

    .ant-btn-link {
      display: flex;
      align-items: center;
      border-radius: 50%;
      justify-content: center;
      height: 31px;
      width: 31px;

      :hover {
        background: ${({ theme }): string => theme?.bgColors?.LIGHT_BLUE_25};
      }

      &.log-btn,
      &.active {
        svg {
          path {
            fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
          }
        }
      }
    }

    .ant-tooltip-disabled-compatible-wrapper {
      &.active {
        svg {
          path {
            fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
          }
        }
      }
    }

    .qry-context-icon {
      svg {
        margin: 3px 0 0 3px;
      }
    }
  }
`;

export const HighlightIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  box-shadow: 0 0 9px 0
    ${({ theme }): string => theme?.bgColors?.YELLOW_ORANGISH};
  border: solid 1.5px
    ${({ theme }): string => theme?.borderColors?.YELLOW_ORANGISH};
  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  border-radius: 50%;
`;
