import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";

//        <---Components-->
import Flex from "../flex";
import LinkButton from "../linkbutton";

import CoreNodesCircleIcon from "../corenodetypescircleicon";
//        <---Components-->

//        <---SVGS--->
import { redirectCircledIcon } from "../../svgs";
import { useOpenModal } from "../../customhooks";
import { DQRuleDetailsModalProps } from "../modalwrapper/modals/dqruledetailsmodal/dqruledetailsmodal.types";
import { QualitySectionTitleWithIconProps } from "./qualitysectitlewithicon.types";
import { getTableDetailPageUrl } from "../../utils";
import { tableDetailPageTabsValues } from "../../pages/tablepage/tablepage.constants";
import StyledLink from "../styledlink/styledlink";
//        <---SVGS--->

function QualitySectionTitleWithIcon(
  props: QualitySectionTitleWithIconProps
): JSX.Element {
  const onOpenModal = useOpenModal();
  const history = useHistory();

  const {
    title = "",
    nodeId = "",
    isColRef = false,
    isOverview = false,
    parentId = "",
  } = props;

  const openModalForColRef = useCallback((): void => {
    onOpenModal({
      modalId: "rule_type_detail_modal",
      visible: true,
      modalTitle: (
        <Flex columnGap={5}>
          <CoreNodesCircleIcon nodeType="COL" /> {title}
        </Flex>
      ),
      modalProps: {
        nodeId,
        ruleTypeId: "ALL",
        nodeName: title || "",
        nodeType: "COL",
        dqRulesgridNodeId: parentId,
        chartProps: {
          title: "Overall DQ trend for column",
          nodeId,
          nodeType: "COL",
          ruleCategoryId: "ALL",
        },
      } as DQRuleDetailsModalProps,
    });
  }, [props]);

  const getToUrl = useMemo(() => {
    const url = getTableDetailPageUrl(
      nodeId,
      tableDetailPageTabsValues?.data_quality
    );

    return url || "";
  }, [props]);

  return (
    <Flex>
      {isOverview && "Data"} Quality
      <LinkButton marginLeft="8px" height="20px">
        <StyledLink
          to={isColRef ? "" : getToUrl}
          {...(isColRef && {
            propsOnClick: openModalForColRef,
          })}
        >
          {isOverview
            ? redirectCircledIcon("25", "25")
            : redirectCircledIcon("20", "20")}
        </StyledLink>
      </LinkButton>
    </Flex>
  );
}

export default QualitySectionTitleWithIcon;
