import styled from "styled-components";

export const AgentConfigSecStyled = styled.div<{ isEditMode: boolean }>`
  .configuration-form {
    .readonly-config-margin-top {
      margin-top: 19.2px;
    }

    .readonly-config {
      display: flex;
      flex-direction: column;

      .config-item-wrapper {
        margin-bottom: 23.2px;
        .config-label {
          font-family: OpenSansSemiBold;
          margin-bottom: 3px;
          color: ${({ theme }): string => theme?.textColors?.BLUE_116};
        }

        .config-value,
        .config-json-value {
          position: relative;
          width: 100%;

          .cong-down-icon {
            background: ${({ theme }): string =>
              theme?.bgColors?.LIGHT_GREY_22};
            position: absolute;
            right: 25px;
            top: 20px;
            font-family: OpenSansSemiBold;

            svg {
              margin-left: 5px;
            }
          }

          pre {
            width: 100%;
            height: 500px;
            min-height: 500px;
            overflow: auto;
            padding: 8px 9.6px;
            background: ${({ theme }): string =>
              theme?.bgColors?.LIGHT_GREY_22};
            border: 1px solid ${({ theme }): string => theme?.bgColors?.GREY_18};
          }
        }

        .config-value {
          color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};

          .show-more-text-main-wrapper {
            white-space: pre-wrap;
            word-break: break-word;
          }
        }
      }
    }

    .selected-chat-agent {
      .label {
        color: ${({ theme, isEditMode }): string =>
          isEditMode
            ? theme?.textColors?.BLACK_COLOR
            : theme?.textColors?.BLUE_116};
      }
    }
  }

  .selected-chat-agent {
    display: flex;
    flex-direction: column;
    margin-top: ${({ isEditMode }): string => (isEditMode ? "0" : "23.2px")};

    .label {
      font-family: OpenSansSemiBold;
      margin-bottom: 2px;
    }

    .value {
      color: ${({ theme }): string => theme?.textColors?.BLACK_COLOR};
    }
  }

  .ant-form-item-control {
    width: 100%;
  }
`;

export const SectionLabelwithDescStyled = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  .sec-label {
    font-family: OpenSansSemiBold;
    margin-bottom: 3px;
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    font-size: 17px;
  }

  .sec-desc {
    color: ${({ theme }): string => theme?.textColors?.GREY_26};
    font-size: 12px;
  }
`;
