import { AxiosResponse } from "axios";
import { SelectFieldOption } from "../../components/formfields/selectfield/selectfield.types";

import { DATE_TIME_COMBINE_FORMAT } from "../../constants";

import {
  formatBytes,
  getObjectKeys,
  getTimeDifference,
  isArray,
  jsonParse,
  utcToLocalDate,
} from "../../utils";

import { utcTOLocalTimeZone } from "../../utils/utctolocaltimezone";

import {
  SourcesParserReturnType,
  SourcesResponseType,
  SourceScanHistoryParserReturnType,
  SourceScanHistoryResponseType,
  SourceTypeParserReturnType,
  SourceTypeResponseType,
  EmailRecipientsResponseType,
  ExcelSourceFilesListResponse,
  ExcelSourceScanJobStatusParsedType,
  ExcelSourceScanJobStatusRes,
  ParsedExcelSourceFilesList,
  ScanHistoryPaginatedParserReturnType,
  ScanHistoryPaginatedResponseType,
  ScanResultResponse,
  ScanReultParserdType,
  ScanSummary,
  SourceOwnerType,
  SourceStateResponseType,
  WorkspaceResponseType,
  SourceWorkspacesParserReturnType,
  SchemasResponseType,
  DatabasesResponseType,
  FolderResponseType,
  S3ResourceTypeReturnType,
} from "./sourcesparser.types";
import { SourceTypes } from "../../app.types";

function getOwners(ownersJson: string): SourceOwnerType[] {
  return jsonParse(ownersJson);
}

function getEmailRecipients(emailRecipientsJson: string): any {
  if (!emailRecipientsJson) {
    return [];
  }

  const parsedResponse = jsonParse(emailRecipientsJson);

  return parsedResponse?.map((item: EmailRecipientsResponseType) => ({
    userId: item?.user_id,
    userName: item?.user_name,
    userEmail: item?.user_email,
    userType: item?.user_type,
  }));
}

function getParsedSourceStat(statsJson: string): any {
  if (!statsJson) {
    return undefined;
  }

  const statsResponse = jsonParse(statsJson, true) as SourceStateResponseType;

  return {
    tableCount: statsResponse?.Table_Count,
    columnCount: statsResponse?.Column_Count,
  };
}

function getParsedScanInfo(
  scanDefJson: string
): SourcesParserReturnType["scanInfo"] {
  const jsonData = scanDefJson ? jsonParse(scanDefJson, true) : undefined;

  let activationDate;

  if (jsonData?.activation_date) {
    if (jsonData?.activation_date?.length < 19) {
      activationDate = jsonData?.activation_date?.substring(0, 11).concat(
        jsonData?.activation_date
          ?.substring(11)
          ?.split(":")
          ?.map((val: string) => (Number(val) < 10 ? `0${Number(val)}` : val))
          ?.join(":")
      );
    } else {
      activationDate = jsonData?.activation_date;
    }
  }

  return {
    activationDate: activationDate && utcToLocalDate(activationDate),
    daysOfMonth: jsonData?.days_of_month || "",
    daysOfWeek: jsonData?.days_of_week || [],
    emailRecipients: jsonData?.email_recipients || "",
    endAfterRepeatingXTimes: jsonData?.ends_after_repeating_x_times || "",
    endsOnDate:
      jsonData?.ends_on_date &&
      utcToLocalDate(jsonData?.ends_on_date.substring(0, 10)),
    frequencyType: jsonData?.freq_type || "",
    interval: jsonData?.interval || "1",
    isRepeat: jsonData?.is_repeat || "",
    repeatAfterXMinutes: jsonData?.repeat_after_x_minutes || "",
    repeatForXHours: jsonData?.repeat_for_x_hours || "",
    scanType: jsonData?.scan_type || "",
    scheduleType: jsonData?.sch_type || "",
    startOnTime:
      activationDate && utcTOLocalTimeZone(activationDate, "hh:mm a"),
    ends: jsonData?.ends_after_repeating_x_times
      ? "after"
      : jsonData?.ends_on_date
      ? "on"
      : "never",
  };
}

function parseDataSource(item: SourcesResponseType): SourcesParserReturnType {
  return {
    id: item?.SRC_ID,
    name: item?.SRC_NAME || "",
    sourceTypeId: item?.SRC_TYPE_ID,
    sourceType: item?.SRC_TYPE || "",
    owners: getOwners(item?.SRC_OWNERS),
    lastScanned: item?.LAST_SCANNED_DATE || "",
    status: item?.SRC_STATUS || "",
    isSrcOffline: item?.IS_SRC_OFFLINE,
    isOnPremSaws: item?.IS_ON_PREM_SAWS,
    accessInfo: item?.SRC_ACCESS_INFO || "",
    description: item?.SRC_DESC || "",
    stats: getParsedSourceStat(item?.SRC_STATS),
    scannedComments: item?.SRC_SCANNED_COMMENTS || "",
    sawsId: item?.SAWS_CONNECTOR_ID || null,
    scanDefName: item?.SCAN_DEF_NAME || "",
    scanDefDesc: item?.SCAN_DEF_DESC || "",
    scanInfo: getParsedScanInfo(item?.SCAN_DEF_CONFIG),
    emailRecipients: getEmailRecipients(item?.EMAIL_RECIPIENTS),
    connectionDetails: item?.SRC_CONNECTION_DETAILS || "",
    sawsName: item?.SAWS_NAME || "",
    isChanged: item?.IS_CHANGED || 0,
    srcSettings: jsonParse(item?.SRC_SETTINGS, true),
    createdOnUtcTime: item?.CREATED_ON || "",
  };
}

function getParsedAdhocInfo(
  adhocJsonSting: string
): SourceTypeParserReturnType["adhocInfo"] {
  const parsedAdhocInfo = jsonParse(adhocJsonSting, true);

  if (!adhocJsonSting) {
    return undefined;
  }

  return parsedAdhocInfo;
}

export function getParsedSingleDataSource({
  data,
}: AxiosResponse<SourcesResponseType[]>): SourcesParserReturnType {
  return data?.length
    ? parseDataSource(data[0])
    : ({} as SourcesParserReturnType);
}

export function getParsedDataSources({
  data = [],
}: AxiosResponse<SourcesResponseType[]>): SourcesParserReturnType[] {
  return data?.map((item) => ({
    id: item?.SRC_ID,
    name: item?.SRC_NAME || "",
    sourceTypeId: item?.SRC_TYPE_ID,
    sourceType: item?.SRC_TYPE || "",
    owners: getOwners(item?.SRC_OWNERS),
    lastScanned: item?.LAST_SCANNED_DATE || "",
    status: item?.SRC_STATUS || "",
    isSrcOffline: item?.IS_SRC_OFFLINE,
    isOnPremSaws: item?.IS_ON_PREM_SAWS,
    accessInfo: item?.SRC_ACCESS_INFO || "",
    description: item?.SRC_DESC || "",
    stats: getParsedSourceStat(item?.SRC_STATS),
    scannedComments: item?.SRC_SCANNED_COMMENTS || "",
    sawsId: item?.SAWS_CONNECTOR_ID || null,
    scanDefName: item?.SCAN_DEF_NAME || "",
    scanDefDesc: item?.SCAN_DEF_DESC || "",
    // scanInfo: getParsedScanInfo(item?.SCAN_DEF_CONFIG),
    emailRecipients: getEmailRecipients(item?.EMAIL_RECIPIENTS),
    connectionDetails: item?.SRC_CONNECTION_DETAILS || "",
    sawsName: item?.SAWS_NAME || "",
    isChanged: item?.IS_CHANGED || 0,
    srcSettings: jsonParse(item?.SRC_SETTINGS, true),
    createdOnUtcTime: item?.CREATED_ON || "",
  }));
}

export function getParsedDataSourceTypes({
  data = [],
}: AxiosResponse<SourceTypeResponseType[]>): SourceTypeParserReturnType[] {
  return data?.map((item) => ({
    id: item?.REF_ID?.trim() as SourceTypes,
    type: item?.REF_TYPE || "",
    displayName: item?.REF_NAME || "",
    displayOrder: item?.REF_DISPLAY_ORDER,
    adhocInfo: getParsedAdhocInfo(item?.REF_ADHOC_INFO),
    sourceTypeId: item?.REF_ID,
  }));
}

export function getParsedSourceScanHistory({
  data,
}: AxiosResponse<
  SourceScanHistoryResponseType[]
>): SourceScanHistoryParserReturnType[] {
  return data?.map((item) => ({
    id: item?.SCAN_ID,
    defId: item?.SCAN_DEF_ID,
    nodeId: item?.NODE_ID,
    nodeType: item?.NODE_TYPE || "",
    defConfig: item?.SCAN_DEF_CONFIG || "",
    createdBy: item?.CREATED_BY,
    createdById: item?.CREATED_BY_ID,
    execId: item?.SCAN_EXEC_ID || "",
    status: item?.SCAN_STATUS?.trim() || "",
    startDate: item?.SCAN_START_DATE || "",
    endDate: item?.SCAN_END_DATE || "",
    summary: item?.SCAN_SUMMARY || "",
    scanName: item?.SCAN_NAME || "",
    statusId: item?.SCAN_STATUS_ID || "",
    createdOn: item?.CREATED_ON || "",
    scanType: item?.SCAN_TYPE || "",
  }));
}

export function getParsedPaginatedScanHistory({
  data,
}: AxiosResponse<ScanHistoryPaginatedResponseType>): ScanHistoryPaginatedParserReturnType {
  return {
    data:
      (isArray(data?.data) &&
        data?.data?.map((item) => ({
          id: item?.SCAN_ID,
          defId: item?.SCAN_DEF_ID,
          nodeId: item?.NODE_ID,
          nodeType: item?.NODE_TYPE || "",
          defConfig: item?.SCAN_DEF_CONFIG || "",
          createdBy: item?.CREATED_BY,
          createdById: item?.CREATED_BY_ID,
          execId: item?.SCAN_EXEC_ID || "",
          status: item?.SCAN_STATUS?.trim() || "",
          startDate: item?.SCAN_START_DATE || "",
          endDate: item?.SCAN_END_DATE || "",
          summary: item?.SCAN_SUMMARY || "",
          scanName: item?.SCAN_NAME || "",
          statusId: item?.SCAN_STATUS_ID,
          createdOn: item?.CREATED_ON || "",
          scanType: item?.SCAN_TYPE,
        }))) ||
      ([] as SourceScanHistoryParserReturnType[]),
    totalPage: data?.pageCount,
    totalRows: data?.totalRecords,
  };
}

export function dataSourceScanResultParser({
  data,
}: AxiosResponse<ScanResultResponse>): ScanReultParserdType {
  const scanSummary = jsonParse(data?.SCAN_SUMMARY, true) as ScanSummary;

  return {
    scan_name: data?.SCAN_NAME || "",
    scan_def_config: jsonParse(data?.SCAN_DEF_CONFIG || ""),
    source_type: data?.SRC_TYPE,
    source_id: data?.SRC_ID || "",
    source_name: data?.SRC_NAME || "",
    status: data?.SCAN_STATUS || "",
    status_id: data?.SCAN_STATUS_ID || "",
    scan_type: data?.SCAN_TYPE || "",
    scan_type_id: data?.SCAN_TYPE_ID || "",
    created_by: data?.CREATED_BY || "",
    created_on_utc_time: data?.CREATED_ON || "",
    created_at: utcTOLocalTimeZone(
      data?.SCAN_START_DATE,
      "MM/dd/yyyy hh:mm:ss a"
    ),
    completed_at: utcTOLocalTimeZone(
      data?.SCAN_END_DATE,
      "MM/dd/yyyy hh:mm:ss a"
    ),
    completion_time: getTimeDifference(
      utcTOLocalTimeZone(data?.SCAN_START_DATE),
      undefined,
      utcTOLocalTimeZone(data?.SCAN_END_DATE)
    )?.replace("ago", ""),
    insights_results:
      (getObjectKeys(scanSummary) as Array<keyof ScanSummary>)
        ?.sort(
          (a: keyof ScanSummary, b: keyof ScanSummary) =>
            scanSummary?.[a]?.order - scanSummary?.[b]?.order
        )
        ?.map((next) => {
          const isContatiningMultipleSec =
            scanSummary?.[next]?.value?.length > 1;
          const headingElement = scanSummary?.[next]?.value?.[0];

          return {
            section_heading: scanSummary?.[next]?.name || "",
            heading_details: {
              count: isContatiningMultipleSec ? 0 : headingElement?.count || 0,
              desc: isContatiningMultipleSec ? "" : headingElement?.name || "",
              type: isContatiningMultipleSec ? "alert" : headingElement?.type,
            },
            remaining_details: (
              (isContatiningMultipleSec
                ? scanSummary?.[next]
                : headingElement
              )?.value?.sort((a, b) => a?.order - b?.order) || []
            )?.map((nestValue) => ({
              count: nestValue?.count || 0,
              desc: nestValue?.name || "",
              type: nestValue?.type,
              sublist: nestValue?.value?.length
                ? nestValue?.value
                    ?.sort((a, b) => a?.order - b?.order)
                    ?.map((nestNestItem) => ({
                      count: nestNestItem?.count || 0,
                      desc: nestNestItem?.name || "",
                      type: nestNestItem?.type,
                    }))
                : undefined,
            })),
          };
        }) || [],
  };
}

export function getParsedExcelSourceFiles({
  data,
}: AxiosResponse<ExcelSourceFilesListResponse>): ParsedExcelSourceFilesList {
  const commonVal = { is_loading: false, is_newly_added: false };
  return (
    data?.body?.map((file) => {
      const splittedfilePath = file.Key?.split?.("/") || [];
      const fileName = splittedfilePath?.slice(-1)?.toString() || "";

      return {
        file_name: {
          ...commonVal,
          file_name: fileName,
        },
        delete_file: { ...commonVal },
        size: { ...commonVal, size: formatBytes(file?.Size) },
        updated_by: { ...commonVal, updated_by: file?.UpdatedBy },
        updated_on: {
          progress: 0,
          updated_time: utcTOLocalTimeZone(
            file?.LastModified,
            DATE_TIME_COMBINE_FORMAT
          ),
          is_uploading: false,
        },
        id: fileName,
        is_newly_added: false,
        folder_name: "",
        file_id: file?.Key || "",
        Job_id: file?.JobId || "",
      };
    }) || []
  )?.sort(
    (a, b) =>
      new Date(b?.updated_on?.updated_time)?.valueOf() -
      new Date(a?.updated_on?.updated_time)?.valueOf()
  );
}

export function getParsedExcelSourceJobStatus({
  data,
}: AxiosResponse<ExcelSourceScanJobStatusRes>): ExcelSourceScanJobStatusParsedType {
  return { status: data?.[0]?.SCAN_STATUS_ID };
}

export function getParsedWorkspaces({
  data = [],
}: AxiosResponse<SchemasResponseType>): SourceWorkspacesParserReturnType[] {
  return (
    data
      ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      ?.map((item) => ({ key: item?.id || "", name: item?.name || "" })) || []
  );
}

export function getParsedSchemas({
  data,
}: AxiosResponse<DatabasesResponseType>): SourceWorkspacesParserReturnType[] {
  const { results = [] } = data || {};

  return (
    results
      ?.sort((a, b) =>
        a?.schema_name
          ?.toLowerCase()
          ?.localeCompare(b?.schema_name?.toLowerCase())
      )
      ?.map((item) => ({
        key: item?.schema_name || "",
        name: item?.schema_name || "",
        label: item?.schema_name || "",
        value: item?.schema_name || "",
      })) || []
  );
}

export function getParsedDatabases({
  data,
}: AxiosResponse<WorkspaceResponseType>): SelectFieldOption[] {
  const { results = [] } = data || {};
  return (
    results
      ?.sort((a, b) =>
        a.db_name > b.db_name ? 1 : b.db_name > a.db_name ? -1 : 0
      )
      ?.map((item) => ({
        value: item?.db_name || "",
        label: item?.db_name || "",
      })) || []
  );
}

export function getParsedFolders({
  data,
}: AxiosResponse<FolderResponseType>): SourceWorkspacesParserReturnType[] {
  const folders = data?.projects?.project || [];

  return (
    folders
      ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      ?.map((item) => ({ key: item?.name || "", name: item?.name || "" })) || []
  );
}

export const getParsedS3Resources = ({
  data,
}: AxiosResponse<{
  body: {
    Name: string;
    Description: string;
    CreateTime: string;
    CatalogId: string;
    DatabaseName: string;
  }[];
}>): S3ResourceTypeReturnType => {
  const { body = [] } = data || {};
  return body?.map((item) => ({
    name: item?.Name || "",
    desc: item?.Description || "",
    database: item?.DatabaseName || "",
  }));
};
