import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";

import LinkButton from "../../../../components/linkbutton";
import DropdownButton from "../../../../components/dropdownbutton";

import {
  chevronDownCollapse,
  deactivateIcon,
  deleteIcon,
  doubleTickIcon,
  playIcon,
  plusIconInFilledBlueCircle,
  redoIcon,
  removeScheduleIcon,
  tagsIcon,
} from "../../../../svgs";

import { GridActionButtonsProps } from "./gridactionbuttons.types";

import {
  GridActionButtonsStyled,
  TagsItemsWrapperStyled,
} from "./gridactionbuttons.styles";

import { VerticalDividerStyled } from "../../../../components/dividers/dividers.styles";
import { ELEMENT_IDS } from "../../../../constants";

import ButtonActionMenu from "../../../../components/buttonactionmenu";
import { CaddiAnalyticsStatusIdType } from "../../../../parsers";

import {
  getUserPermissions,
  jsonParse,
  openNotification,
} from "../../../../utils";

import { RunNodeProfilingButton } from "../../../../components";
import { MoreActionsDropdownComp } from "./gridactionbuttons.components/moreactionsdropdown";

import { useOpenModal } from "../../../../customhooks";
import { DeleteRecordsModalProps } from "../deleterecords/deleterecords.types";

import { getPageNode } from "../../listingpage.utils";
import { useRequestWithMethod } from "../../../../api";

import SuccessNotificationMessage from "../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import { CancelScheduleModalPropsType } from "../../../../components/modalwrapper/modals/cancelschedulemodal/cancelschedulemodal.types";

import { getJobScheduledDetailPageUrl } from "../../../../utils/getjobdetailpageurl";
import { THEME_COLORS } from "../../../../components/themeprovider/themeprovider.constant";

const {
  allcat_lst_lst_tags_upd_btn: ALLCAT_LST_LST_TAGS_UPD_BTN,
  allcat_lst_lst_mass_upd_btn: ALLCAT_LST_LST_MASS_UPD_BTN,
  allcat_lst_lst_add_tag_btn: ALLCAT_LST_LST_ADD_TAG_BTN,
  allcat_lst_lst_remove_tag_btn: ALLCAT_LST_LST_REMOVE_TAG_BTN,
  allcat_lst_lst_more_actions_btn: ALLCAT_LST_LST_MORE_ACTIONS_BTN,
} = ELEMENT_IDS;

const massUpdateIcon = redoIcon("12", "12");
const tagIcon = tagsIcon("12", "12");
const plusIcon = plusIconInFilledBlueCircle();
const playBtnIcon = playIcon();
const chevronDownSvg = chevronDownCollapse();
const doubleTickSvg = doubleTickIcon(
  undefined,
  undefined,
  THEME_COLORS?.light?.textColors?.BLUE_116
);
const cancelExecutionsIcon = removeScheduleIcon("12", "12");
const abortScheduleBtnIcon = deactivateIcon("13", "13");

export const GridActionButtons = (
  props: GridActionButtonsProps
): JSX.Element => {
  const {
    isButtonDisable = false,
    isMassUpdateDisable = false,
    onClickDelete,
    onClickMassUpdate,
    handleAddTags,
    handleRemoveTags,
    onClickHide,
    onClickUnHide,
    isHideDisabled = false,
    isUnhideDisabled = false,
    isDisableDeleteButton,
    tooltipTitle,
    isRunProfileDisable,
    sortedFeedbackStatuses,
    onMassUpdateStatus,
    onClickRunProfileOffline,
    onClickRunProfileOnline,
    nodeType = "TBL",
    isOutsideListingVews,
    isOnlineProfilingInProgress,
    selectedRulesWithSourceInfo,
    viewId,
    allSelectedNodesIds,
    handleClearAll,
    countFilter,
    filterModel,
    isSelectAll,
    populateData,
    isActivateJobDisabled = false,
    isDeactivateJobDisabled = false,
    // need this for selectall case because allSelectedNodesIds gets [] in selectAll
    selectedRowsCount,
    selectedNodeTagIds,
    sortModel,
  } = props;

  const onOpenModal = useOpenModal();
  const history = useHistory();

  const {
    is_catalog_admin: isCatalogAdmin,
    is_data_security_admin: isDataSecurityAdmin,
  } = getUserPermissions();

  const {
    isTablePage,
    isColumnPage,
    isJobPage,
    isRulePage,
    isTermPage,
    isJobExecutionsPage,
    isFeedbackAnalyticsPage,
  } = getPageNode(nodeType);

  const nodeName = isTablePage
    ? "Table(s)"
    : isColumnPage
    ? "Column(s)"
    : isRulePage
    ? "Rule(s)"
    : "Job(s)";

  const filteredSelectedNodeIds = allSelectedNodesIds
    ?.filter((val) => val !== undefined)
    ?.map((val) => Number(val));

  const onDeleteRecordsSuccess = useCallback(() => {
    populateData?.({});
  }, [populateData]);

  const onClickDeleteRecords = useCallback(() => {
    const ids =
      selectedRulesWithSourceInfo
        ?.filter((node: any) => node?.is_editable !== false)
        ?.map((node) => node?.node_id) || [];

    const idsCount = ids?.length || 0;

    onOpenModal({
      modalId: "delete_records",
      visible: true,
      modalTitle: `Delete ${nodeName}`,
      modalProps: {
        nodeType,
        selectedNodesIds: ids,
        isSelectAll,
        selectedRowsCount: idsCount,
        countFilter,
        searchId: `${viewId}`,
        filterModel,
        onDeleteRecords: onDeleteRecordsSuccess,
      } as DeleteRecordsModalProps,
    });
  }, [
    viewId,
    nodeType,
    nodeName,
    isSelectAll,
    filterModel,
    countFilter,
    selectedRulesWithSourceInfo,
    onDeleteRecordsSuccess,
  ]);

  const onJobRunNowSuccess = useCallback(
    (response) => {
      const parsedRes = jsonParse(response?.data?.FN_UPDATE_GOV_JOBS_DATA);

      const scheduledJobs = parsedRes?.scheduled_jobs;

      const scheduledJobsLength = scheduledJobs?.length || 0;

      const jobId = scheduledJobs?.[0]?.job_def_id;
      const schId = scheduledJobs?.[0]?.sch_id;
      const schName = scheduledJobs?.[0]?.sch_name;

      const schedulerPageUrl = getJobScheduledDetailPageUrl(jobId, schId);

      openNotification(
        <SuccessNotificationMessage
          message={
            scheduledJobsLength > 1 ? (
              "Job Executions created successfully"
            ) : (
              <span>
                <LinkButton
                  isTextUnderLine
                  onClick={(e): void => {
                    if (e?.ctrlKey) {
                      window.open(schedulerPageUrl, "_blank");
                    } else {
                      history?.push(schedulerPageUrl);
                    }
                  }}
                  marginLeft="3px"
                  marginRight="3px"
                >
                  {schName}
                </LinkButton>{" "}
                has been running.
              </span>
            )
          }
        />
      );

      populateData?.({});
    },
    [populateData]
  );

  const {
    onExecuteRequest: onMassUpdateAction,
    isLoading: isMassUpdateActionLoading,
    error: errorInMassUpdate,
  } = useRequestWithMethod(
    "update_field_data",
    [nodeType, viewId],
    false,
    onJobRunNowSuccess
  );

  const onClickRunNow = useCallback(() => {
    const allSelectedNodesIdsLength = allSelectedNodesIds?.length || 0;
    const isMassUpdate: boolean =
      isSelectAll || (!isSelectAll && allSelectedNodesIdsLength > 1);

    const updatedScheduler = [
      {
        job_def_ids: allSelectedNodesIds,
        job_status: "RUN",
        isSelectAll,
        filterModel: filterModel || {},
        countFilter: countFilter || "all",
        is_mass_update: isMassUpdate,
      },
    ];

    onMassUpdateAction(updatedScheduler);
  }, [allSelectedNodesIds]);

  const onCancelExecutionSuccess = useCallback(
    (response) => {
      openNotification(
        <SuccessNotificationMessage
          message={`${response?.data?.total_cnt} Job Execution(s) has been updated.`}
        />
      );
      populateData({});
    },
    [populateData]
  );

  const onClickAbortExecutions = useCallback(() => {
    onOpenModal({
      modalId: "cancel_schedule",
      visible: true,
      modalTitle: "Abort Execution(s)",
      modalProps: {
        isFromListing: true,
        propsOnSuccess: onCancelExecutionSuccess,
        schId: filteredSelectedNodeIds,
        actionType: "ABR",
      } as CancelScheduleModalPropsType,
    });
  }, [filteredSelectedNodeIds, onCancelExecutionSuccess]);

  const onClickCancelExecutions = useCallback(() => {
    onOpenModal({
      modalId: "cancel_schedule",
      visible: true,
      modalTitle: "Cancel Execution(s)",
      modalProps: {
        isFromListing: true,
        propsOnSuccess: onCancelExecutionSuccess,
        schId: filteredSelectedNodeIds,
        actionType: "CNL",
      } as CancelScheduleModalPropsType,
    });
  }, [filteredSelectedNodeIds, onCancelExecutionSuccess]);

  const massUpdateSec = (
    <LinkButton
      onClick={onClickMassUpdate}
      disabled={isMassUpdateDisable}
      elementId={ALLCAT_LST_LST_MASS_UPD_BTN}
      className="mass-update-btn"
    >
      {massUpdateIcon} Mass Update
    </LinkButton>
  );

  const tagsSec = (
    <TagsItemsWrapperStyled>
      <DropdownButton
        items={
          !isButtonDisable
            ? [
                {
                  key: "add-tag",
                  value: "Add Tags",
                  icon: plusIcon,
                  onClick: handleAddTags,
                  id: ALLCAT_LST_LST_ADD_TAG_BTN,
                  customClass: "add-btn",
                },
                {
                  key: "remove-tag",
                  value: "Remove Tags",
                  icon: deleteIcon,
                  onClick: handleRemoveTags,
                  id: ALLCAT_LST_LST_REMOVE_TAG_BTN,
                },
              ]
            : []
        }
        disabled={isButtonDisable}
        overlay={<div />}
        drpdnBtnElementId={ALLCAT_LST_LST_TAGS_UPD_BTN}
      >
        {tagIcon}
        Tags
      </DropdownButton>
    </TagsItemsWrapperStyled>
  );

  const runRuleProfiling = (
    <RunNodeProfilingButton
      buttonTitle="Run Rule"
      buttonIcon={playBtnIcon}
      onClickRunProfileOnline={onClickRunProfileOnline}
      onClickRunProfileOffline={onClickRunProfileOffline}
      nodeType={nodeType}
      selectedRowsCount={selectedRowsCount}
    />
  );

  const runNow = (
    <LinkButton className="run-job-btn" onClick={onClickRunNow}>
      {playBtnIcon} Run Now
    </LinkButton>
  );

  const abortJobExecutions = (
    <LinkButton
      className="abort-executions-btn"
      onClick={onClickAbortExecutions}
    >
      {abortScheduleBtnIcon} Abort Execution(s)
    </LinkButton>
  );

  const cancelJobExecutions = (
    <LinkButton
      className="cancel-executions-btn"
      onClick={onClickCancelExecutions}
    >
      {cancelExecutionsIcon} Cancel Execution(s)
    </LinkButton>
  );

  const runTableColumnProfiling = isCatalogAdmin ? (
    <RunNodeProfilingButton
      buttonIcon={playBtnIcon}
      buttonTitle="Run Profiling"
      onClickRunProfileOnline={onClickRunProfileOnline}
      onClickRunProfileOffline={onClickRunProfileOffline}
      nodeType={nodeType}
      selectedRowsCount={selectedRowsCount}
    />
  ) : (
    <div />
  );

  const moreActionsDropdown = (
    <MoreActionsDropdownComp
      selectedRulesWithSourceInfo={selectedRulesWithSourceInfo}
      viewId={viewId}
      nodeType={nodeType}
      filterModel={filterModel}
      sortModel={sortModel}
      countFilter={countFilter}
      isSelectAll={isSelectAll}
      isHideDisabled={isHideDisabled}
      isUnhideDisabled={isUnhideDisabled}
      isActivateJobDisabled={isActivateJobDisabled}
      isDeactivateJobDisabled={isDeactivateJobDisabled}
      handleClearAll={handleClearAll}
      populateData={populateData}
      onClickHide={onClickHide}
      onClickUnHide={onClickUnHide}
      onClickDeleteRecords={onClickDeleteRecords}
      disabled={isOnlineProfilingInProgress}
      selectedNodeTagIds={selectedNodeTagIds}
      selectedRowsCount={selectedRowsCount}
      isOutsideListingVews={isOutsideListingVews}
    />
  );

  const deleteTerm = (
    <LinkButton
      className="delete-btn"
      onClick={onClickDelete}
      disabled={isButtonDisable || isDisableDeleteButton}
      tooltipProps={{
        title: isDisableDeleteButton ? tooltipTitle || "" : "",
        placement: "right",
        overlayClassName: "term-info-tooltip",
      }}
    >
      {deleteIcon} Delete Term
    </LinkButton>
  );

  const deleteTablesOrColumns = (
    <LinkButton className="delete-btn" onClick={onClickDeleteRecords}>
      {deleteIcon} Delete {nodeName}
    </LinkButton>
  );

  const isProfilingInProgressAndRulePage =
    isRulePage && isOutsideListingVews && isOnlineProfilingInProgress;

  const updateStatusOverlayItems = useMemo(
    () =>
      sortedFeedbackStatuses?.map((item) => ({
        name: item?.name || "",
        onClick: (): void =>
          onMassUpdateStatus(item?.id as CaddiAnalyticsStatusIdType),
      })),
    [sortedFeedbackStatuses, onMassUpdateStatus]
  );

  return (
    <GridActionButtonsStyled
      className="listing-grid-act-btns"
      isProfilingInProgress={isProfilingInProgressAndRulePage}
    >
      {isFeedbackAnalyticsPage ? (
        <div className="feedback-analytics-act-btns">
          <ButtonActionMenu
            customButton={
              <div className="update-status-btn">
                {doubleTickSvg} Update Status {chevronDownSvg}
              </div>
            }
            overLayItems={updateStatusOverlayItems}
          />
        </div>
      ) : (
        <div className="grid-action-btns-wrapper left-action-section">
          {isJobExecutionsPage && abortJobExecutions}

          {isJobExecutionsPage && cancelJobExecutions}

          {isRulePage ? (
            runRuleProfiling
          ) : !isJobPage && !isJobExecutionsPage ? (
            massUpdateSec
          ) : (
            <></>
          )}

          {isJobPage && runNow}

          {!isRulePage && !isJobPage && !isJobExecutionsPage && tagsSec}

          {isRulePage ? (
            massUpdateSec
          ) : isTablePage || isColumnPage ? (
            runTableColumnProfiling
          ) : (
            <></>
          )}

          {isRulePage && tagsSec}

          {(isRulePage || isJobPage) && moreActionsDropdown}

          {isTablePage &&
            (isDataSecurityAdmin || isCatalogAdmin) &&
            moreActionsDropdown}

          {isColumnPage && isDataSecurityAdmin
            ? moreActionsDropdown
            : isColumnPage && isCatalogAdmin && deleteTablesOrColumns}

          {isTermPage && deleteTerm}
          {/* {(isTablePage || isColumnPage) && isCatalogAdmin && (
          <>
            <LinkButton
              className="play-btn-icon"
              onClick={onClickRunProfile}
              disabled={isRunProfileDisable || isRunningProfile}
              tooltipProps={{
                title: isRunningProfile
                  ? "Profiling is already in running state."
                  : isRunProfileDisable
                  ? `Only ${
                      isColumnPage ? "Columns" : "Tables"
                    } from one source can be profiled at a time.`
                  : "When you click this, it is going to schedule profiling of the selected objects.",
                placement: "right",
              }}
            >
              {playBtnIcon} Run Profiling
            </LinkButton>
          </>
        )} */}
        </div>
      )}
    </GridActionButtonsStyled>
  );
};

export default GridActionButtons;
