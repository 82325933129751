import { Button } from "../../../../../../components";
import { AnalysisDetailPageTopicSectionStyled } from "./analysisdetailpagetopicsection.styles";
import { AnalysisDetailPageTopicSectionProps } from "./analysisdetailpagetopicsection.types";

const AnalysisDetailPageTopicSection = (
  props: AnalysisDetailPageTopicSectionProps
): JSX.Element => {
  const {
    selectedTopicId = 0,
    topicsList = [],
    onChangeSelectedTopic,
    isVisible = false,
    agentsList,
    subtitle,
  } = props;

  return (
    <AnalysisDetailPageTopicSectionStyled isVisible={isVisible}>
      <div className="subtitle">
        {subtitle || "I have following agents from your data store"}
      </div>

      <div
        className="topics-list-container"
        data-testid="analysis-detail-page-topics-sec-list-container"
      >
        {(agentsList || topicsList)?.map((item) => {
          const { id = 0, title = "" } = item || {};

          return (
            <Button
              key={id}
              className={`topic-btn ${
                item?.id === selectedTopicId ? "selected-topic-btn" : ""
              }`}
              onClick={(): void => onChangeSelectedTopic(item?.id, title)}
              data-testid="analysis-detail-page-topics-sec-topic-btn"
            >
              {title}
            </Button>
          );
        })}
      </div>
    </AnalysisDetailPageTopicSectionStyled>
  );
};

export default AnalysisDetailPageTopicSection;
