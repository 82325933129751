import styled from "styled-components";

export const MessageInputFieldStyled = styled.div<{
  marginBottom?: string;
  marginTop?: string;
  iconPlacement?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: ${({ marginBottom }): string => marginBottom || "0"};
  margin-top: ${({ marginTop }): string => marginTop || "0"};

  .form-style {
    width: 100%;

    .info-msg {
      display: flex;
      align-items: center;
      color: ${({ theme }): string => theme?.textColors?.GREY_24};
      column-gap: 4px;
      padding: 0 4px;
      margin-bottom: 16px;

      .info-text,
      .learn-more-btn {
        font-size: 12px;
      }

      .info-icon {
        svg {
          height: 12.8px;
          width: 5.48px;
          margin-right: 4px;

          path {
            fill: ${({ theme }): string => theme?.textColors?.GREY_210};
          }
        }
      }
    }

    .ant-input {
      font-size: 14.4px;
      padding: 19px 14.4px;
      padding-right: 90px;
      min-height: 60.8px;
      max-height: 110px !important;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    }

    .send-msg-btn {
      position: absolute;
      right: 28px;
      top: ${({ iconPlacement }): string =>
        iconPlacement === "top" ? "23px" : ""};
      bottom: ${({ iconPlacement }): string =>
        iconPlacement === "bottom" ? " 21.5px" : ""};

      display: flex;
      align-items: flex-end;
      cursor: ${({ disabled }): string =>
        disabled ? "not-allowed" : "pointer"};

      svg {
        path {
          fill: ${({ theme, disabled }): string =>
            disabled
              ? theme?.textColors?.GREY_36
              : theme?.textColors?.BLUE_116};
          stroke: ${({ theme, disabled }): string =>
            disabled
              ? theme?.textColors?.GREY_36
              : theme?.textColors?.BLUE_116};
        }
      }
    }
  }
`;
