import { Col, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import {
  ChatBotDetailStyled,
  CheckboxWrapperStyled,
  LimitFormStyled,
  LimitAccessSecStyled,
  ObjectNameWithIcon,
} from "./limitaccesssec.styles";

import { LimitAccessSecProps } from "./limitaccesssec.types";
import { CheckboxStyled } from "../../../../../../components/checkbox/checkbox.styles";
import Flex from "../../../../../../components/flex";

import LinkButton from "../../../../../../components/linkbutton";

const LimitAccessSec = (props: LimitAccessSecProps): JSX.Element => {
  const {
    isEditMode,
    isLimitTables,
    pinnedObjectIcon,
    pinnedObjectsList,
    isScopeEnabled,
    pinnedObjectsName,
    readonlyTitle,
    limitFieldTitle,
    limitFieldDescription,
    onScopeEnabledClick,
    enableSaveButton,
    disableSaveButton,
    onReadonlyObjectClick,
    explorerSec,
  } = props;

  const pinnedObjectsLength = pinnedObjectsList?.length || 0;

  return (
    <LimitAccessSecStyled>
      {isEditMode ? (
        <Row>
          <Col>
            <LimitFormStyled>
              <CheckboxWrapperStyled flexDirection="row">
                <CheckboxStyled
                  checked={isScopeEnabled}
                  onChange={(event: CheckboxChangeEvent): void => {
                    const isChecked = event?.target?.checked;
                    !isChecked && enableSaveButton();
                    if (isChecked && !pinnedObjectsList?.length)
                      disableSaveButton?.();
                    onScopeEnabledClick();
                  }}
                >
                  <span className="label">{limitFieldTitle}</span>
                </CheckboxStyled>
              </CheckboxWrapperStyled>

              <p className="checkbox-desc">{limitFieldDescription}</p>

              {isScopeEnabled && (
                <>
                  {isLimitTables && (
                    <>
                      <p className="select-tables">Select Tables</p>
                      <span className="select-tables-desc">
                        Select the tables you wish to engage in a chat with
                        using this bot. If you want to control which columns are
                        allowed for chat click on the Manage Fields button on
                        the right.
                      </span>
                    </>
                  )}

                  <div className="chat-bot-explorer-sec">{explorerSec}</div>
                </>
              )}
            </LimitFormStyled>
          </Col>
        </Row>
      ) : (
        <Row className="chat-bot-details">
          <ChatBotDetailStyled>
            <p className="label">{readonlyTitle}:</p>

            {pinnedObjectsLength ? (
              <div className="object-listing">
                <p className="object-count">
                  {pinnedObjectsLength} {pinnedObjectsName}
                  {pinnedObjectsLength > 1 ? "s have" : " has"} been selected
                </p>
                <Flex direction="row" columnGap={5} alignItems="flex-start">
                  <Flex
                    customClass="objects-list"
                    direction="column"
                    alignItems="flex-start"
                  >
                    {!!pinnedObjectsLength &&
                      pinnedObjectsList?.map((object, index) => {
                        return (
                          <Flex
                            alignItems="flex-end"
                            justifyContent="flex-end"
                            columnGap={5}
                            key={`pinned-objects-${object?.id}`}
                            customClass="pinned-objects"
                          >
                            <div
                              key={`definition-objects-bullets-${index}`}
                              className="object-bullets"
                            />
                            <ObjectNameWithIcon
                              key={`definition-objects-sec-${object?.id}`}
                              className="definition-objects-bullets"
                            >
                              {pinnedObjectIcon}
                              <LinkButton
                                onClick={(): void =>
                                  onReadonlyObjectClick?.(object?.id)
                                }
                                className="object-name"
                              >
                                {object?.title || object?.name}
                              </LinkButton>
                            </ObjectNameWithIcon>
                          </Flex>
                        );
                      })}
                  </Flex>
                </Flex>
              </div>
            ) : (
              <span className="empty-text">No Limit Applied</span>
            )}
          </ChatBotDetailStyled>
        </Row>
      )}
    </LimitAccessSecStyled>
  );
};

export default LimitAccessSec;
