import { NodeSubType, NodeType } from "../../app.types";
import { chatBotIcon, analysisToolIcon } from "../../svgs";
import {
  CoreNodesCircleIconStyled,
  LetterIconMapping,
} from "./corenodetypescircleicon.styles";
import { CoreNodesCircleIconProps } from "./corenodetypescircleicon.types";

function CoreNodesCircleIcon(props: CoreNodesCircleIconProps): JSX.Element {
  const {
    nodeType,
    nodeSubType,
    isSelected,
    width = "25px",
    height = "25px",
  } = props;

  const nodeTypeToLetterMapping: {
    [key in NodeType]?: { letter: string | JSX.Element; title: string };
  } = {
    TBL: { letter: "T", title: "Table" },
    DSR: { title: "Data Source", letter: "DS" },
    COL: { letter: "C", title: "Column" },
    TRM: { letter: "G", title: "Glossary Term" },
    DSF: { letter: "DSF", title: "Data Source Field" },
    ANL: {
      letter: chatBotIcon(width, height),
      title: "Agent",
    },
    RLS: { letter: "DQ", title: "Rules" },
    JOB: { letter: "J", title: "Job" },
  };

  const nodeSubTypeToLetterMapping: {
    [key in NodeSubType | string]?: {
      letter: string | JSX.Element;
      title: string;
    };
  } = {
    RPT: { letter: "R", title: "Report" },
    TOL: { letter: analysisToolIcon("25", "25"), title: "Tool" },
  };

  const mappedInfo =
    nodeSubTypeToLetterMapping?.[nodeSubType || ""] ||
    nodeTypeToLetterMapping?.[nodeType];

  const isSvgIcon = false;
  const showBackgroundColor = nodeType !== "ANL";

  return (
    <CoreNodesCircleIconStyled
      {...props}
      title={mappedInfo?.title || ""}
      className="core-nodes-circle-icon"
      data-testid="core-nodes-circle-icon-main-wrapper"
      showBackgroundColor={showBackgroundColor}
    >
      <LetterIconMapping
        isSvg={isSvgIcon}
        className="letter-icon-mapping"
        data-testid="core-nodes-circle-icon-letter-mapping"
        isSelected={isSelected}
      >
        {mappedInfo?.letter}
      </LetterIconMapping>
    </CoreNodesCircleIconStyled>
  );
}

export default CoreNodesCircleIcon;
