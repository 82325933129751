import styled from "styled-components";

export const LimitAccessSecStyled = styled.div``;

export const LimitFormStyled = styled.div`
  width: 100%;
  padding: 10px 20px 10px 0;

  .desc {
    max-width: 100%;
  }

  .dvsum-tooltip {
    padding: 0;
  }

  .select-tables {
    font-family: OpenSansSemiBold;
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    font-size: 16px;
    margin-bottom: 3px;
    padding-left: 25px;
  }

  .select-tables-desc {
    padding-left: 25px;
    font-size: 12px;
    color: ${({ theme }): string => theme?.textColors?.GREY_24};
    margin-bottom: 8px;
    display: block;
  }
  .checkbox-desc {
    font-size: 12px;
    padding-top: 5px;
    padding-left: 25px;
  }
`;

export const CheckboxWrapperStyled = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }): string => flexDirection || "column"};
  gap: 5px;
  padding-left: 0;

  .label {
    font-family: OpenSansSemiBold;
    font-size: 13.6px;
  }
`;

export const ChatBotDetailStyled = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;

  .object-listing {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 5px;
    flex-direction: column;
  }

  .label {
    font-size: 13.6px;
  }

  .object-count {
    font-size: 13.6px;
    font-family: OpenSansSemiBold;
    margin-bottom: 0;
  }

  .object-bullets {
    width: 6.4px;
    height: 40px;
    border-left: 1px solid rgb(207, 206, 206);
    border-bottom: 1px solid rgb(207, 206, 206);
  }

  .objects-list {
    position: relative;

    .pinned-objects:first-child {
      height: 30px;
    }

    div:first-child {
      .object-bullets {
        height: 20px;
      }
    }

    div.definition-objects-bullets {
      height: 40px;
      align-items: center;
      top: 20px;
    }
  }

  .empty-text {
    font-size: 13.6px;
  }
`;

export const ObjectNameWithIcon = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
`;
