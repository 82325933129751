import { format } from "date-fns";
import moment from "moment";

export function utcTOLocalTimeZone(
  value: string,
  dateFormat?: string,
  dontDoFormat?: boolean,
  checkDaylightSaving?: boolean
): string {
  try {
    const convertedDate = new Date(`${value}Z`);

    if (checkDaylightSaving) {
      const date = value?.toLowerCase()?.endsWith("z")
        ? new Date(value)
        : new Date(`${value}Z`);

      const currentTzOffset = moment()?.utcOffset();

      const dateToLocalZone = moment(date)?.utcOffset(currentTzOffset);
      const dateToLocalZoneStr = dateToLocalZone?.format("YYYY-MM-DD HH:mm:ss");

      return dateToLocalZoneStr;
    }

    return dontDoFormat
      ? `${convertedDate}`
      : format(convertedDate, dateFormat || "PPpp");
  } catch {
    return "";
  }
}
