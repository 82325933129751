import { AxiosResponse } from "axios";
import { useQueries, useQuery } from "react-query";

// <--- Constants Start --->
import { API_CONFIG } from "../../constants/apiconfig";
// <--- Constants End --->

// <--- Parsers Start --->
import {
  getParsedAnalysis,
  getParsedAnalysisDocForRefPage,
  getTableColumsDetails,
  ReturnTypeOfAnalysisRefParser,
  getParsedAnalysisDocForDetailPage,
  getParsedDatasets,
  ReturnTypeOfAnalysisDetailPageParser,
  ColumnResponse,
  ReturnTypeOfColumnsParser,
  ReturnTypeOfUserPreferencesParser,
  getParsedDataImportUploadSignedUrl,
  DataImportUploadSignedUrlReturnType,
} from "../../parsers";
// <--- Parsers End --->

// <--- Services Start --->
import { useGetAll } from "../baseservice";
import {
  fetchRequestWithCustomHeaders,
  fetchRequestWithoutHeaders,
} from "../../api";
// <--- Services End --->

// <--- Types Start --->
import { CustomReactQueryHookReturnType } from "../services.types";
import {
  ReturnTypeOfAllDatasets,
  ReturnTypeOfAnalysisQueriesParser,
  ReturnTypeOfAnalysisTopicsList,
  ReturnTypeOfBotConceptsParser,
  ReturnTypeOfChatBots,
  ReturnTypeOfChatList,
  ReturnTypeOfParseFieldsOfTablesThatIncludesInPrompt,
} from "../../parsers/analysisparser/analysisparser.types";

import {
  OnUpdateQueryColumns,
  OnUpdateQueryResults,
  OnErrorOfQueryBlock,
  OnUpdateQueryVerboseLogs,
  ErrorOnFetchingVerboseLogs,
} from "../../pages/analysisdetailpage/analysisdetailpage.views/analysisdetailpagecontentsec";

import {
  getParsedChatList,
  getParsedAnalysisTopicsList,
  getParsedUserPreferences,
  getParsedQueryResults,
  getParsedBotConcepts,
  getParsedAnalysisQueries,
} from "../../parsers/analysisparser/analysisparser";

import { NodeType } from "../../app.types";
import { getFiltersfromLocalStorage } from "../../utils";

import { useGetCurrentGateway, useGatewayDownPrompt } from "../../customhooks";
// <--- Types End --->

const {
  get_all_chat_bots: getAllChatBots,
  get_node: getNode,
  search_table: searchTable,
  get_table_columns: getTableColumns,
  get_analysis_topics_list: getAnalysisTopicsList,
  get_chat_listing: getAllChats,
  get_pinned_tbls_manage_fields: getPinnedTblsManageFields,
  get_user_preferences: getUserPreferences,
  get_data_analysis_upload_signed_url: getDataAnalysisUploadSignedUrl,
  get_data_analysis_uploaded_file_signed_url: getDataAnalysisConfUploadedFileSignedURL,
  get_bot_concepts: getBotConcepts,
  get_analysis_queries: getAnalysisQueries,
} = API_CONFIG;

export function useGetchatBots(
  nodeId: string
): CustomReactQueryHookReturnType<ReturnTypeOfChatBots[]> {
  return useGetAll({
    apiConfig: getAllChatBots,
    params: [nodeId],
    parser: getParsedAnalysis,
  });
}

export function useGetAnalysisDoc(
  nodeID: string,
  nodeType: NodeType,
  nodeStatus: string
): CustomReactQueryHookReturnType<ReturnTypeOfAnalysisRefParser> {
  const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);

  return useGetAll({
    apiConfig: getNode,
    params: [nodeID, nodeType, nodeStatus, "true", "ANL", parsedNodeFilter],
    parser: getParsedAnalysisDocForRefPage,
  });
}
export const useGetResultsOfAnalysisQueryBlocks = (
  items: { url: string; id: string; isLoading: boolean }[],
  onSuccess: OnUpdateQueryResults,
  onError: OnErrorOfQueryBlock,
  gatewayId?: string
): void => {
  const { triggerPrompt } = useGatewayDownPrompt(gatewayId || "");

  const {
    isGatewayRunning,
    currentGateway,
    isFetchingGatewaysList,
  } = useGetCurrentGateway(gatewayId || "");

  const fetcher =
    !currentGateway?.isOnpremiseGateway && !isFetchingGatewaysList
      ? fetchRequestWithoutHeaders()
      : fetchRequestWithCustomHeaders("GET", {
          "X-Api-Key": btoa(currentGateway?.sawsCommunicationKey || ""),
        });

  const queries = items?.map((item) => ({
    queryKey: item?.url,
    queryFn: fetcher,
    onSuccess: (res: any): void => {
      onSuccess(
        item?.id,
        getParsedQueryResults(res),
        undefined,
        "fetching_data_from_sample_url"
      );
    },
    onError: (err: any): void => {
      const isTimeOutError = err?.formattedMsg?.includes("timeout");
      triggerPrompt?.(err);
      onError?.(
        item?.id,
        err,
        "fetching_data_from_sample_url",
        undefined,
        undefined,
        undefined,
        !err?.status && currentGateway?.isOnpremiseGateway && !isTimeOutError
      );
    },
    refetchOnMount: item?.isLoading,
    retry: false,
    enabled: isGatewayRunning,
  }));

  useQueries(currentGateway?.isPingcheckInProgress ? [] : queries);
};

export function useGetAnalysisDocForDetailsPage(
  nodeID: string,
  nodeType: NodeType,
  nodeStatus: string,
  enabled: boolean,
  analysisType: "ANL" | "CHT"
): CustomReactQueryHookReturnType<ReturnTypeOfAnalysisDetailPageParser> {
  const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);
  return useGetAll({
    apiConfig: getNode,
    params: [
      nodeID,
      nodeType,
      nodeStatus,
      "false",
      analysisType,
      parsedNodeFilter,
    ],
    parser: getParsedAnalysisDocForDetailPage,
    options: {
      enabled,
    },
  });
}

export const useGetColumnsProfilingOfAnalysisQueryBlocks = (
  items: { url: string; id: string; isLoading: boolean }[],
  onSuccess: OnUpdateQueryColumns,
  onError: OnErrorOfQueryBlock,
  gatewayId: string
): ReturnType<typeof useQuery>[] => {
  const {
    isGatewayRunning,
    currentGateway,
    isFetchingGatewaysList,
  } = useGetCurrentGateway(gatewayId || "");

  const { triggerPrompt } = useGatewayDownPrompt(gatewayId || "");

  const fetcher =
    !currentGateway?.isOnpremiseGateway && !isFetchingGatewaysList
      ? fetchRequestWithoutHeaders()
      : fetchRequestWithCustomHeaders("GET", {
          "X-Api-Key": btoa(currentGateway?.sawsCommunicationKey || ""),
        });

  const queries = items?.map((item) => ({
    queryKey: item?.url,
    queryFn: fetcher,
    onSuccess: (res: any): void => {
      const typedRes: AxiosResponse<{
        col_seq: {
          name: string;
          data_type: string;
        }[];
        data: ColumnResponse[];
      }> = res;

      const updatedData: ColumnResponse[] =
        typedRes?.data?.col_seq?.map((seq) => {
          const findedObj = typedRes?.data?.data?.find(
            (item) => item?.COL_NAME === seq?.name
          );
          return (
            findedObj ||
            ({
              COL_NAME: seq?.name,
              COL_DATA_TYPE_ID: seq?.data_type,
            } as ColumnResponse)
          );
        }) || [];

      const updatedRes: AxiosResponse<ColumnResponse[]> = {
        ...res,
        data: updatedData ?? [],
      };

      const parsedData = getTableColumsDetails(updatedRes) || {};

      const {
        sample_data_header_rendrer: sampleDataHeaderRendrer = [],
        column_details: columnDetails = [],
      } = parsedData;

      const updatedColDetails = columnDetails?.map((col) => ({
        column_id: `${col?.column_id}`,
        column_name: col?.column_name,
        column_display_name: col?.column_display_name,
        description: col?.description,
        data_type: col?.data_type || "STR",
        data_type_info: col?.data_type_info,
        col_status: col?.col_status,
        col_status_id: col?.col_status_id,
        is_selected: false,
        is_applied: false,
        col_type_id: col?.col_type_id,
        col_dist_count: col?.col_dist_count,
        is_col_masked: col?.is_col_masked,
      }));

      onSuccess(
        item?.id,
        updatedColDetails,
        sampleDataHeaderRendrer,
        "fetching_columns_from_sample_url"
      );
    },
    onError: (err: any): void => {
      const isTimeOutError = err?.formattedMsg?.includes("timeout");

      triggerPrompt?.(err);
      onError?.(
        item?.id,
        err,
        "fetching_columns_from_sample_url",
        undefined,
        undefined,
        undefined,
        !err?.status && currentGateway?.isOnpremiseGateway && !isTimeOutError
      );
    },
    refetchOnMount: item?.isLoading,
    retry: false,
    enabled: isGatewayRunning,
  }));

  return useQueries(currentGateway?.isPingcheckInProgress ? [] : queries);
};

export const useGetSearchDatasets = (
  nodeId: string,
  nodeType: string,
  searchText: string
): CustomReactQueryHookReturnType<ReturnTypeOfAllDatasets> => {
  return useGetAll({
    apiConfig: searchTable,
    params: [nodeId, searchText, nodeType],
    options: { enabled: false },
    parser: getParsedDatasets,
  });
};

export const useGetQueryTableFilterColumns = (
  tableId: string,
  enabled: boolean,
  onSuccess: (_data: any) => void
): CustomReactQueryHookReturnType<ReturnTypeOfColumnsParser> => {
  return useGetAll({
    apiConfig: getTableColumns,
    params: [tableId, "", "true"],
    options: { enabled, onSuccess },
  });
};

export const useGetAnalysisTopicsList = (): CustomReactQueryHookReturnType<ReturnTypeOfAnalysisTopicsList> => {
  return useGetAll({
    apiConfig: getAnalysisTopicsList,
    params: [],
    parser: getParsedAnalysisTopicsList,
  });
};

export const useGetChatListing = (): CustomReactQueryHookReturnType<ReturnTypeOfChatList> => {
  return useGetAll({
    apiConfig: getAllChats,
    params: [],
    parser: getParsedChatList,
  });
};

export const useGetFieldsThatPartOfPrompt = (
  analysisId = "",
  pinnedTableIds = ""
): CustomReactQueryHookReturnType<
  ReturnTypeOfParseFieldsOfTablesThatIncludesInPrompt[]
> => {
  return useGetAll({
    apiConfig: getPinnedTblsManageFields,
    params: [analysisId, pinnedTableIds],
    options: { enabled: !!analysisId },
  });
};

export const useGetVerboseLogsOfQueries = (
  items: { url: string; id: string; isLoading: boolean }[],
  onSuccess: OnUpdateQueryVerboseLogs,
  onError: ErrorOnFetchingVerboseLogs
): void => {
  const fetcher = fetchRequestWithoutHeaders();

  const queries = items?.map((item) => ({
    queryKey: item?.url,
    queryFn: fetcher,
    onSuccess: (res: any): void => {
      onSuccess(item?.id, res?.data || "");
    },
    onError: (): void => {
      onError?.(item?.id);
    },
    refetchOnMount: item?.isLoading,
  }));

  useQueries(queries);
};

export const useGetUserPreferences = (
  onSuccess?: (_response: unknown) => void
): CustomReactQueryHookReturnType<ReturnTypeOfUserPreferencesParser> => {
  return useGetAll({
    apiConfig: getUserPreferences,
    params: [],
    parser: getParsedUserPreferences,
    options: {
      onSuccess,
      enabled: false,
    },
  });
};

//TODO: will make it one single service to get signed url for file upload,
export const useGetDataAnalysisUploadSignedUrl = (): CustomReactQueryHookReturnType<DataImportUploadSignedUrlReturnType> => {
  return useGetAll({
    apiConfig: getDataAnalysisUploadSignedUrl,
    parser: getParsedDataImportUploadSignedUrl,
  });
};

export const useGetDataAnalysisConfUploadedFileSignedURL = (
  fileName: string
): CustomReactQueryHookReturnType<any> => {
  return useGetAll({
    apiConfig: getDataAnalysisConfUploadedFileSignedURL,
    params: [fileName],
    options: {
      enabled: false,
    },
  });
};

export const useGetDownloadConfSignedUrl = (
  url: string
): CustomReactQueryHookReturnType<any> => {
  return useGetAll({
    apiConfig: { url, method: "GET" },
    options: {
      enabled: !!url,
    },
    isReqWithoutHeader: true,
  });
};

export const useGetBotConcepts = (
  nodeId: string,
  sourceId: string
): CustomReactQueryHookReturnType<ReturnTypeOfBotConceptsParser> => {
  return useGetAll({
    apiConfig: getBotConcepts,
    params: [nodeId, sourceId],
    parser: (res) => getParsedBotConcepts(res, nodeId, sourceId),
    options: { enabled: !!nodeId && !!sourceId },
  });
};

export const useGetAnalysisQueries = (
  nodeId: string
): CustomReactQueryHookReturnType<ReturnTypeOfAnalysisQueriesParser> => {
  return useGetAll({
    apiConfig: getAnalysisQueries,
    params: [nodeId],
    parser: getParsedAnalysisQueries,
    options: { enabled: !!nodeId },
  });
};
