import { lazy, Suspense, useCallback, useState } from "react";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";

import AppThemeProvider from "./components/themeprovider";
import { PrivateRoute, AuthRoute } from "./routes";

import { APP_ROUTES } from "./constants";

import { GlobalStyles } from "./styles/app.styles";

import { store } from "./store";
import ModalWrapper from "./components/modalwrapper";

import "antd/dist/antd.css";
import "ag-grid-community/dist/styles/ag-grid.css";

import "reactflow/dist/style.css";

import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "./index.css";

import ScrollToTop from "./components/scrolltotop";
import Drawer from "./components/drawerwrapper";

import { useGetAppState } from "./customhooks";

import { OnboardingTutorial } from "./components";
import Dvsumfallbackspinner from "./components/dvsumfallbackspinner";

import ReleaseOnboarding from "./components/releaseonboarding/releaseonboarding";
import { GatewaysStatusesProvider } from "./contexts/gatewaystatuscheckcontext/gatewaystatuscheckcontext";
import GatewaysWrapper from "./components/gatewayswrapper";

const EnterpriseSearchPage = lazy(() => import("./pages/enterprisesearchpage"));
const DataImportPage = lazy(() => import("./pages/dataimportpage"));

const LoginPage = lazy(() => import("./pages/loginpage"));
const AuthTokenPage = lazy(() => import("./pages/authtokenpage"));

const TermsPage = lazy(() => import("./pages/termspage"));
const TablePage = lazy(() => import("./pages/tablepage"));

const UserGroupsPage = lazy(() => import("./pages/usergroupspage"));
const UserRolesPage = lazy(() => import("./pages/userrolespage"));

const UsersPage = lazy(() => import("./pages/userspage"));
const DataSourcesPage = lazy(() => import("./pages/sourceslistingpage"));

const SourceDetailPage = lazy(() => import("./pages/sourcedetailpage"));
const AccountSettingsPage = lazy(() => import("./pages/accountsettingspage"));

const ScanResultPage = lazy(() => import("./pages/scanresultpage"));
const HomePage = lazy(() => import("./pages/homepage/homepage"));

const RegistraionPage = lazy(() => import("./pages/registrationpage"));
const AccountVerificationPage = lazy(
  () => import("./pages/accountverificationpage")
);

const GlossaryDomainsPage = lazy(() => import("./pages/glossarydomainspage"));
const GlossaryCategoriesPage = lazy(
  () => import("./pages/glossarycategoriespage")
);

const TableListingPage = lazy(
  () => import("./pages/listingpage/tableslistingpage")
);

const RulesListingPage = lazy(
  () => import("./pages/listingpage/ruleslistingpage")
);

const JobsListingPage = lazy(
  () => import("./pages/listingpage/jobslistingpage")
);

const JobExecutionsLisitngPage = lazy(
  () => import("./pages/listingpage/jobexecutionslistingpage")
);

const ColumnListingPage = lazy(
  () => import("./pages/listingpage/columnslistingpage")
);

const AnalyticsDictionaryListingPage = lazy(
  () => import("./pages/listingpage/analyticsdictionarylistingpage")
);

const TermsListingPage = lazy(
  () => import("./pages/listingpage/termslistingpage")
);

const DataSourceDetailPage = lazy(() => import("./pages/datasourcedetailpage"));

const ProductTourPage = lazy(() => import("./pages/producttourpage"));
const ProductTestDrivePage = lazy(() => import("./pages/testdrivepage"));

const DashboardPage = lazy(() => import("./pages/dashboard"));

const ExtEnterpriseSearchPagesNavigator = lazy(
  () => import("./pages/extenterprisesearchpage/extenterprisesearchpage")
);

const ExtCharLengthErrorPage = lazy(
  () =>
    import(
      "./pages/extenterprisesearchpage/extcharlengtherrorpage/extcharlengtherrorpage"
    )
);

const LineagePage = lazy(() => import("./pages/lineagepage"));

const TagsetsPage = lazy(() => import("./pages/tagsetspage"));
const TagsetsDetailPage = lazy(() => import("./pages/tagsetsdetailpage"));

const AnalyticsPage = lazy(() => import("./pages/analyticspage"));
const MySettingsPage = lazy(() => import("./pages/mysettingspage"));

const OnboardingTutorialPage = lazy(
  () => import("./pages/onboardingtutorialpage")
);

const AnalysisDetailPage = lazy(() => import("./pages/analysisdetailpage"));

const RuleDetailPage = lazy(() => import("./pages/ruledetailpage"));

const JobDetailPage = lazy(() => import("./pages/jobdetailpage"));

const ChatPage = lazy(() => import("./pages/chatpage"));

const JobScheduleDetailPage = lazy(
  () => import("./pages/jobscheduledetailpage")
);

const ReferenceDictionaryPage = lazy(
  () => import("./pages/referencedictionary")
);

const ReferenceDictionaryDetailPage = lazy(
  () => import("./pages/referencedictionarydetailpage")
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  },
});

function AppCopntent(): JSX.Element {
  const { isLogedIn, isOnboardingMode } = useGetAppState();
  const location = useLocation();

  const chatId = location?.pathname?.substring(6) || "";

  const [isNewChatInitiated, setIsNewChatInitiated] = useState<boolean>(false);
  const [chatRouteKey, setChatRouteKey] = useState<string>("new-chat");

  const onNewChatInitiated = useCallback(() => {
    setIsNewChatInitiated(true);
  }, []);

  const getRandomRouteKey = useCallback(() => {
    return (+new Date() * Math.random()).toString(36).slice(2, 7);
  }, []);

  const onNewChatButtonClicked = useCallback(
    (generateNewRouteKey?: boolean) => {
      generateNewRouteKey && setChatRouteKey(getRandomRouteKey());
      setIsNewChatInitiated(false);
    },
    []
  );

  const redirectUrl = isLogedIn
    ? APP_ROUTES.private_routes.home
    : APP_ROUTES.auth_routes.login;

  return (
    <AppThemeProvider>
      <div>
        <ModalWrapper />
        <ModalWrapper isSecondaryModal />
        <Drawer />
        <GlobalStyles />
        {isLogedIn && <GatewaysWrapper />}
        {isOnboardingMode && <OnboardingTutorial />}
        {isLogedIn && <ReleaseOnboarding />}
        <Switch>
          <Route path="/" exact>
            <Redirect to={redirectUrl} />
          </Route>
          <AuthRoute
            path={APP_ROUTES.auth_routes.login}
            isLoggedIn={isLogedIn}
            exact
          >
            <LoginPage />
          </AuthRoute>
          <AuthRoute
            path={APP_ROUTES.auth_routes.ext_login}
            isLoggedIn={isLogedIn}
            exact
          >
            <LoginPage isExtLogin />
          </AuthRoute>
          <AuthRoute
            path={APP_ROUTES.auth_routes.auth_token}
            isLoggedIn={isLogedIn}
            exact
          >
            <AuthTokenPage />
          </AuthRoute>
          <AuthRoute
            path={APP_ROUTES.auth_routes.register}
            exact
            isLoggedIn={isLogedIn}
          >
            <RegistraionPage />
          </AuthRoute>
          <AuthRoute
            path={APP_ROUTES.auth_routes.account_verification}
            isLoggedIn={isLogedIn}
            exact
          >
            <AccountVerificationPage />
          </AuthRoute>
          <PrivateRoute
            id="source_scan_result"
            path={`${APP_ROUTES.private_routes.source_scan_result}/:id/:tab?`}
            exact
            isLoggedIn={isLogedIn}
          >
            <ScanResultPage />
          </PrivateRoute>
          <PrivateRoute
            id="sources"
            path={APP_ROUTES.private_routes.sources}
            isLoggedIn={isLogedIn}
            exact
          >
            <DataSourcesPage />
          </PrivateRoute>
          <PrivateRoute
            id="sources"
            path={`${APP_ROUTES.private_routes.sources}/:id/:tab?/:subTab?`}
            exact
            isLoggedIn={isLogedIn}
          >
            <SourceDetailPage />
          </PrivateRoute>
          <PrivateRoute
            id="user_groups"
            path={APP_ROUTES.private_routes.user_groups}
            isLoggedIn={isLogedIn}
            exact
          >
            <UserGroupsPage />
          </PrivateRoute>
          <PrivateRoute
            id="user_roles"
            path={APP_ROUTES.private_routes.user_roles}
            isLoggedIn={isLogedIn}
            exact
          >
            <UserRolesPage />
          </PrivateRoute>
          <PrivateRoute
            id="users"
            path={APP_ROUTES.private_routes.users}
            isLoggedIn={isLogedIn}
            exact
          >
            <UsersPage />
          </PrivateRoute>
          <PrivateRoute
            id="account_settings"
            path={`${APP_ROUTES.private_routes.account_settings}/:tab/:subTab?`}
            isLoggedIn={isLogedIn}
            exact
          >
            <AccountSettingsPage />
          </PrivateRoute>
          {/* <PrivateRoute
            id="glossary_upload"
            path={APP_ROUTES.private_routes.glossary_upload}
            isLoggedIn={isLogedIn}
            exact
          >
            <GlossaryUploadPage />
          </PrivateRoute> */}
          <PrivateRoute
            id="enterprise_search"
            path={`${APP_ROUTES.private_routes.enterprise_search}/:tab?/:q?`}
            isLoggedIn={isLogedIn}
            exact
          >
            <EnterpriseSearchPage />
          </PrivateRoute>
          <PrivateRoute
            id="data_domains"
            path={APP_ROUTES.private_routes.data_domains}
            isLoggedIn={isLogedIn}
            exact
          >
            <GlossaryDomainsPage />
          </PrivateRoute>
          <PrivateRoute
            id="sub_domains"
            path={APP_ROUTES.private_routes.sub_domains}
            isLoggedIn={isLogedIn}
            exact
          >
            <GlossaryCategoriesPage />
          </PrivateRoute>
          <PrivateRoute
            id="home"
            path={APP_ROUTES.private_routes.home}
            isLoggedIn={isLogedIn}
            exact
          >
            <HomePage />
          </PrivateRoute>
          <PrivateRoute
            id="table_dictionary"
            path={`${APP_ROUTES.private_routes.table_dictionary}/:id/:view`}
            exact
            isLoggedIn={isLogedIn}
          >
            <TablePage />
          </PrivateRoute>
          <PrivateRoute
            id="terms"
            path={`${APP_ROUTES.private_routes.terms}/:id/:view`}
            exact
            isLoggedIn={isLogedIn}
          >
            <TermsPage />
          </PrivateRoute>
          <PrivateRoute
            id="terms"
            path={APP_ROUTES.private_routes.terms}
            exact
            isLoggedIn={isLogedIn}
          >
            <TermsListingPage />
          </PrivateRoute>
          <PrivateRoute
            id="table_dictionary"
            path={APP_ROUTES.private_routes.table_dictionary}
            exact
            isLoggedIn={isLogedIn}
          >
            <TableListingPage />
          </PrivateRoute>
          <PrivateRoute
            id="field_dictionary"
            path={APP_ROUTES.private_routes.field_dictionary}
            exact
            isLoggedIn={isLogedIn}
          >
            <ColumnListingPage />
          </PrivateRoute>
          <PrivateRoute
            id="rules_dictionary"
            path={APP_ROUTES.private_routes.rules_dictionary}
            exact
            isLoggedIn={isLogedIn}
          >
            <RulesListingPage />
          </PrivateRoute>

          <PrivateRoute
            id="jobexecutions_dictionary"
            path={APP_ROUTES.private_routes.jobexecutions_dictionary}
            exact
            isLoggedIn={isLogedIn}
          >
            <JobExecutionsLisitngPage />
          </PrivateRoute>

          <PrivateRoute
            id="jobs_dictionary"
            path={APP_ROUTES.private_routes.jobs_dictionary}
            exact
            isLoggedIn={isLogedIn}
          >
            <JobsListingPage />
          </PrivateRoute>

          <PrivateRoute
            id="reference_dictionary"
            path={APP_ROUTES.private_routes.reference_dictionary}
            exact
            isLoggedIn={isLogedIn}
          >
            <ReferenceDictionaryPage />
          </PrivateRoute>

          <PrivateRoute
            id="reference_dictionary"
            path={`${APP_ROUTES.private_routes.reference_dictionary}/:id`}
            exact
            isLoggedIn={isLogedIn}
          >
            <ReferenceDictionaryDetailPage />
          </PrivateRoute>

          <PrivateRoute
            id="analytics_dictionary"
            path={APP_ROUTES.private_routes.analytics_dictionary}
            exact
            isLoggedIn={isLogedIn}
          >
            <AnalyticsDictionaryListingPage />
          </PrivateRoute>
          <PrivateRoute
            id="data_sources"
            path={`${APP_ROUTES.private_routes.data_sources}/:id/:view`}
            exact
            isLoggedIn={isLogedIn}
          >
            <DataSourceDetailPage />
          </PrivateRoute>
          <PrivateRoute
            id="reports"
            path={`${APP_ROUTES.private_routes.reports}/:id/:view`}
            exact
            isLoggedIn={isLogedIn}
          >
            <DataSourceDetailPage isReportPage />
          </PrivateRoute>
          <PrivateRoute
            id="product_tour"
            path={APP_ROUTES.private_routes.product_tour}
            isLoggedIn={isLogedIn}
            wrapLayout={false}
            exact
          >
            <ProductTourPage />
          </PrivateRoute>
          <PrivateRoute
            id="ext_search_page"
            path={APP_ROUTES.private_routes.ext_search_page}
            isLoggedIn={isLogedIn}
            wrapLayout={false}
            exact
          >
            <ExtEnterpriseSearchPagesNavigator />
          </PrivateRoute>
          <PrivateRoute
            id="ext_search_page"
            path={`${APP_ROUTES.private_routes.ext_search_page}/search/:searchParam`}
            isLoggedIn={isLogedIn}
            wrapLayout={false}
            exact
          >
            <ExtEnterpriseSearchPagesNavigator />
          </PrivateRoute>
          <PrivateRoute
            id="ext_search_page"
            path={`${APP_ROUTES.private_routes.ext_search_page}/:nodeType/:id`}
            isLoggedIn={isLogedIn}
            wrapLayout={false}
            exact
          >
            <ExtEnterpriseSearchPagesNavigator />
          </PrivateRoute>
          <PrivateRoute
            id="ext_search_page"
            path={`${APP_ROUTES.private_routes.ext_search_page}/char-length-error`}
            isLoggedIn={isLogedIn}
            wrapLayout={false}
            exact
          >
            <ExtCharLengthErrorPage />
          </PrivateRoute>
          <PrivateRoute
            id="tagsets"
            path={APP_ROUTES.private_routes.tagsets}
            isLoggedIn={isLogedIn}
            exact
          >
            <TagsetsPage />
          </PrivateRoute>
          <PrivateRoute
            id="tagsets"
            path={`${APP_ROUTES.private_routes.tagsets}/:id`}
            isLoggedIn={isLogedIn}
            exact
          >
            <TagsetsDetailPage />
          </PrivateRoute>
          <PrivateRoute
            id="analytics"
            path={`${APP_ROUTES.private_routes.analytics}/:filter`}
            exact
            isLoggedIn={isLogedIn}
          >
            <AnalyticsPage />
          </PrivateRoute>
          <PrivateRoute
            id="analytics"
            path={APP_ROUTES.private_routes.analytics}
            exact
            isLoggedIn={isLogedIn}
          >
            <AnalyticsPage />
          </PrivateRoute>
          <PrivateRoute
            id="lineage"
            path={APP_ROUTES.private_routes.lineage}
            exact
            isLoggedIn={isLogedIn}
          >
            <LineagePage />
          </PrivateRoute>
          <PrivateRoute
            id="data_import"
            path={APP_ROUTES.private_routes.data_import}
            exact
            isLoggedIn={isLogedIn}
          >
            <DataImportPage />
          </PrivateRoute>
          <PrivateRoute
            id="my_settings"
            path={`${APP_ROUTES.private_routes.my_settings}/:tab`}
            exact
            isLoggedIn={isLogedIn}
          >
            <MySettingsPage />
          </PrivateRoute>
          <PrivateRoute
            path={APP_ROUTES.private_routes.onboarding_tutorial}
            id="onboarding_tutorial"
            exact
            isLoggedIn={isLogedIn}
            wrapLayout={false}
          >
            <OnboardingTutorialPage />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.ai_agent}/:tab`}
            id="ai_agent"
            exact
            isLoggedIn={isLogedIn}
          >
            <AnalysisDetailPage />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.ai_agent}/:id/:tab`}
            id="ai_agent"
            exact
            isLoggedIn={isLogedIn}
          >
            <AnalysisDetailPage />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.ai_agent}/:id/:tab/:view`}
            id="ai_agent"
            exact
            isLoggedIn={isLogedIn}
          >
            <AnalysisDetailPage />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.tool}/:tab`}
            id="tool"
            exact
            isLoggedIn={isLogedIn}
          >
            <AnalysisDetailPage />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.tool}/:id/:tab`}
            id="tool"
            exact
            isLoggedIn={isLogedIn}
          >
            <AnalysisDetailPage />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.tool}/:id/:tab/:view`}
            id="tool"
            exact
            isLoggedIn={isLogedIn}
          >
            <AnalysisDetailPage />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.rule_detail}/:id/:tab`}
            id="rule_detail"
            exact
            isLoggedIn={isLogedIn}
          >
            <RuleDetailPage />
          </PrivateRoute>

          <PrivateRoute
            path={`${APP_ROUTES.private_routes.job_detail}/:id/:tab`}
            id="job_detail"
            exact
            isLoggedIn={isLogedIn}
          >
            <JobDetailPage />
          </PrivateRoute>

          <PrivateRoute
            path={`${APP_ROUTES.private_routes.job_schedule_detail}/:id/:tab/:id/:tab`}
            id="job_schedule_detail"
            exact
            isLoggedIn={isLogedIn}
          >
            <JobScheduleDetailPage />
          </PrivateRoute>

          <PrivateRoute
            path={`${APP_ROUTES.private_routes.dashboard}`}
            id="dashboard"
            exact
            isLoggedIn={isLogedIn}
          >
            <DashboardPage />
          </PrivateRoute>

          <PrivateRoute
            path={`${APP_ROUTES.private_routes.dashboard}/:id`}
            id="dashboard"
            exact
            isLoggedIn={isLogedIn}
          >
            <DashboardPage />
          </PrivateRoute>

          <PrivateRoute
            path={APP_ROUTES.private_routes.new_chat}
            id="chat"
            key={chatRouteKey}
            exact
            isLoggedIn={isLogedIn}
            wrapLayout={false}
          >
            <ChatPage
              onNewChatInitiated={onNewChatInitiated}
              onNewChatButtonClicked={onNewChatButtonClicked}
              useLayout
            />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.chat}`}
            id="chat"
            key={isNewChatInitiated ? chatRouteKey : "unsaved-chat"}
            exact
            isLoggedIn={isLogedIn}
            wrapLayout={false}
          >
            <ChatPage
              onNewChatButtonClicked={onNewChatButtonClicked}
              onNewChatInitiated={onNewChatInitiated}
              useLayout
            />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.chat}/:id`}
            id="chat"
            key={isNewChatInitiated ? chatRouteKey : `chat-details-${chatId}`}
            exact
            isLoggedIn={isLogedIn}
            wrapLayout={false}
          >
            <ChatPage
              onNewChatButtonClicked={onNewChatButtonClicked}
              onNewChatInitiated={onNewChatInitiated}
              useLayout
            />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.chat}/:id/query-id=:queryId`}
            id="chat"
            key={isNewChatInitiated ? chatRouteKey : `chat-details-${chatId}`}
            exact
            isLoggedIn={isLogedIn}
            wrapLayout={false}
          >
            <ChatPage
              onNewChatButtonClicked={onNewChatButtonClicked}
              onNewChatInitiated={onNewChatInitiated}
              useLayout
            />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.read_only_chat}/:id`}
            id="chat"
            key={isNewChatInitiated ? chatRouteKey : `chat-details-${chatId}`}
            exact
            isLoggedIn={isLogedIn}
            wrapLayout={false}
          >
            <ChatPage
              onNewChatButtonClicked={onNewChatButtonClicked}
              onNewChatInitiated={onNewChatInitiated}
              useLayout={false}
            />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.read_only_chat}/:id/query-id=:queryId`}
            id="chat"
            key={isNewChatInitiated ? chatRouteKey : `chat-details-${chatId}`}
            exact
            isLoggedIn={isLogedIn}
            wrapLayout={false}
          >
            <ChatPage
              onNewChatButtonClicked={onNewChatButtonClicked}
              onNewChatInitiated={onNewChatInitiated}
              useLayout={false}
            />
          </PrivateRoute>
          <PrivateRoute
            path={`${APP_ROUTES.private_routes.view_only_chat_question}`}
            id="chat"
            key={isNewChatInitiated ? chatRouteKey : `chat-details-${chatId}`}
            exact
            isLoggedIn={isLogedIn}
            wrapLayout={false}
          >
            <ChatPage
              onNewChatButtonClicked={onNewChatButtonClicked}
              onNewChatInitiated={onNewChatInitiated}
              useLayout={false}
            />
          </PrivateRoute>
          <Route path={APP_ROUTES.auth_routes.test_drive}>
            <Suspense fallback={<Dvsumfallbackspinner />}>
              <ProductTestDrivePage />
            </Suspense>
          </Route>
          <Redirect to={redirectUrl} />
        </Switch>
      </div>
    </AppThemeProvider>
  );
}

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <GatewaysStatusesProvider>
        <Router getUserConfirmation={(): void => {}}>
          <QueryClientProvider client={queryClient}>
            <>
              <AppCopntent />
              <ScrollToTop />
            </>
          </QueryClientProvider>
        </Router>
      </GatewaysStatusesProvider>
    </Provider>
  );
}

export default App;
